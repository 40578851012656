import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWaveform } from '@fortawesome/pro-solid-svg-icons'
import { faCompress } from '@fortawesome/pro-solid-svg-icons'
import { faBolt } from '@fortawesome/pro-solid-svg-icons'
import { faHeartbeat as PumpIcon } from '@fortawesome/pro-solid-svg-icons'
import { faPaperPlane as AdHocIcon } from '@fortawesome/pro-solid-svg-icons'
import { faTemperatureHigh } from '@fortawesome/pro-regular-svg-icons'
import { faRotate } from '@fortawesome/pro-solid-svg-icons'
import { Tooltip } from '@mui/material'

import { AlertTypes } from '../types/alert'
import { EquipmentType } from '../types/equipmentTypes'

export interface AlertTypeMappingRecord {
  displayText: string
  displayTextPlural: string
  equipmentType: EquipmentType // this alert is mapped to what equipment type
  getIcon: (key?: string) => JSX.Element
  alertDescriptionText: string
  getMeasurementTypeText: () => string // sentence describe what kind of status
  defaultAlarmValue?: string
}

const AlertTypeMappingRecord: Record<AlertTypes, AlertTypeMappingRecord> = {
  FinFanVibration: {
    alertDescriptionText:
      'This number is the output of machine learning analytics. It is calculated using sensor and statistical equipment data and should be used as an indication of potential equipment faults.',
    displayText: 'Vibration Anomaly',
    displayTextPlural: 'Vibration Anomalies',
    equipmentType: EquipmentType.fan,
    getIcon: (key?: string) => {
      return (
        <Tooltip
          key={key}
          title="Vibration Anomaly"
          enterDelay={500}
          disableInteractive
        >
          <span>
            <FontAwesomeIcon icon={faWaveform} />
          </span>
        </Tooltip>
      )
    },
    getMeasurementTypeText: () => {
      return 'Vibration Severity (0-1)'
    },
  },
  FinFanLowLoad: {
    displayText: 'Low Load Alarm',
    alertDescriptionText:
      'This status indicates that the DCS Alarm and Event Journal has detected a low load alarm on this equipment. This should be used as an indication of potential equipment faults.',
    displayTextPlural: 'Low Load Alarms',
    equipmentType: EquipmentType.fan,
    getIcon: (key?: string) => {
      return (
        <Tooltip
          key={key}
          title="Low Load Alarm"
          enterDelay={500}
          disableInteractive
        >
          <span>
            <FontAwesomeIcon icon={faBolt} />
          </span>
        </Tooltip>
      )
    },
    getMeasurementTypeText: () => {
      return 'Alarm Status'
    },
    defaultAlarmValue: 'Low Load Detected',
  },
  FilterPressure: {
    alertDescriptionText:
      'This number is the output of machine learning analytics. It is calculated using sensor and statistical equipment data and should be used as an indication of potential equipment faults.',
    displayText: 'Filter Pressure Alert',
    displayTextPlural: 'Filter Pressure Alerts',
    equipmentType: EquipmentType.filter,
    getIcon: (key?: string) => {
      return (
        <Tooltip
          key={key}
          title="Low Load Alarm"
          enterDelay={500}
          disableInteractive
        >
          <span>
            <FontAwesomeIcon icon={faBolt} />
          </span>
        </Tooltip>
      )
    },
    getMeasurementTypeText: () => {
      return 'Estimated Remaining Life'
    },
  },
  AdHocRequest: {
    alertDescriptionText: '',
    displayText: 'Ad-hoc Request',
    displayTextPlural: 'Ad-hoc Requests',
    equipmentType: EquipmentType.fan,
    getIcon: (key?: string) => {
      return (
        <Tooltip
          key={key}
          title="Ad-hoc: Response without an alert attached"
          enterDelay={500}
          disableInteractive
        >
          <span>
            <FontAwesomeIcon icon={AdHocIcon} />
          </span>
        </Tooltip>
      )
    },
    getMeasurementTypeText: () => {
      return 'Estimated Remaining Life'
    },
  },
  ESDN2Model: {
    alertDescriptionText:
      'This number is the output of N2 Pressure for ESD Bundle Unit. The Alert is raised once threshold is reached.',
    displayText: 'ESD N2 Recharge',
    displayTextPlural: 'ESD N2 Recharges',
    equipmentType: EquipmentType.esd,
    getIcon: (key?: string) => {
      return (
        <Tooltip
          key={key}
          title="ESD N2 Automation"
          enterDelay={500}
          disableInteractive
        >
          <span>
            <FontAwesomeIcon icon={faCompress} />
          </span>
        </Tooltip>
      )
    },
    getMeasurementTypeText: () => {
      return 'Nitrogen Pressure (Mpa)'
    },
  },
  ESDCycleModel: {
    alertDescriptionText:
      'This number is the output of No of ESD Cycles for ESD Bundle Unit. The Alert is raised once threshold is reached.',
    displayText: 'ESD Cycle Alert',
    displayTextPlural: 'ESD Cycle Alerts',
    equipmentType: EquipmentType.esd,
    getIcon: (key?: string) => {
      return (
        <Tooltip
          key={key}
          title="ESD Cycle Alerts"
          enterDelay={500}
          disableInteractive
        >
          <span>
            <FontAwesomeIcon icon={faRotate} />
          </span>
        </Tooltip>
      )
    },
    getMeasurementTypeText: () => {
      return 'Alarm Status'
    },
    defaultAlarmValue: 'Fail',
  },
  ESDTempModel: {
    alertDescriptionText:
      'This number is the output of the Temperature alert for ESD Bundle Unit. The Alert is raised once threshold is reached.',
    displayText: 'ESD Temperature Alert',
    displayTextPlural: 'ESD Temperature Alerts',
    equipmentType: EquipmentType.esd,
    getIcon: (key?: string) => {
      return (
        <Tooltip
          key={key}
          title="ESD Temperature Alerts"
          enterDelay={500}
          disableInteractive
        >
          <span>
            <FontAwesomeIcon icon={faTemperatureHigh} />
          </span>
        </Tooltip>
      )
    },
    getMeasurementTypeText: () => {
      return 'Alarm Status'
    },
    defaultAlarmValue: 'Fail',
  },
  PumpModel: {
    alertDescriptionText:
      'This number is the output of machine learning analytics. It is calculated using sensor and statistical equipment data and should be used as an indication of potential equipment faults.',
    displayText: 'Pump Anomaly',
    displayTextPlural: 'Pump Anomalies',
    equipmentType: EquipmentType.pump,
    getIcon: (key?: string) => {
      return (
        <Tooltip
          key={key}
          title="Pump Anomaly"
          enterDelay={500}
          disableInteractive
        >
          <span>
            <FontAwesomeIcon icon={PumpIcon} />
          </span>
        </Tooltip>
      )
    },
    getMeasurementTypeText: () => {
      return 'Anomaly Severity (0-1)'
    },
  },
}
export default AlertTypeMappingRecord

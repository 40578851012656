import { useContext, useMemo, useState } from 'react'
import useStrategyModelActionCondition from '../../services/hooks/useStrategyModelActionCondition'
import { LoggingContext } from '../providers/spa-logging-provider'
import { EquipmentType } from '../types/equipmentTypes'
import { parseBandingInfo } from '../utility'

export function useBandingData(floc: string, equipmentType: EquipmentType) {
  const [thresholds, setThresholds] = useState([] as number[])
  const loggingContext = useContext(LoggingContext)
  const { data: modelActionConditionsData } = useStrategyModelActionCondition(
    floc,
    equipmentType === EquipmentType.fan
      ? 'AnomalyModelOutput-Vibration'
      : 'AnomalyModelOutput-Pump'
  )
  useMemo(() => {
    try {
      if (
        modelActionConditionsData &&
        modelActionConditionsData[0] &&
        modelActionConditionsData[0].conditionBasedActionCondition
      ) {
        const bandingNumbers = parseBandingInfo(
          modelActionConditionsData[0].conditionBasedActionCondition
        )
        if (bandingNumbers) {
          setThresholds(bandingNumbers)
        } else {
          loggingContext.log(
            'WARNING',
            `FLOC: ${floc}, No banding information found.`
          )
        }
      }
    } catch (e) {
      loggingContext.log(
        'WARNING',
        `FLOC: ${floc}, Error during searching alert bands: ${e}`
      )
    }
  }, [modelActionConditionsData])

  return thresholds
}

import styled from '@emotion/styled'
import { Box, Tab, Tabs, Theme } from '@mui/material'
import { withStyles, createStyles } from '@mui/styles'
import React from 'react'
import { RecoilRoot } from 'recoil'

import ClockIcon from '../../../../../icons/clock.svg?react'
import RunHourGraphTab from './tabs/run-hour-main'

const Root = styled(Box)`
  padding: 1em;
`

const Heading = styled(Box)`
  display: flex;
  place-content: space-between;
  #status {
    display: flex;
    flex-direction: row;
    width: 15%;
    min-width: 10rem;
    gap: 0.5rem;
    align-items: flex-start;
    #severity {
      width: 80%;
    }
  }
`

const SealContainer = styled(Box)`
  background-color: white;
  padding: 0 1em 1em 0em;
`

withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    height: '0.15rem',
    backgroundColor: '#00354D',
  },
})(Tabs)

withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      textTransform: 'none',
      opacity: '0.60',
      fontWeight: 400,
      '&:hover': {
        color: '#00354D',
        opacity: 0.8,
      },
      '&$selected': {
        color: '#00354D',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#00354D',
      },
    },
    selected: {},
  })
)((props: any) => <Tab {...props} />)

const RunHourCard = (props: any) => {
  return (
    <Root>
      <Heading>
        <h3>
          <ClockIcon style={{ marginRight: '.5rem', height: '1rem' }} />
          Run Hours
        </h3>
      </Heading>
      <SealContainer boxShadow={0}>
        <RecoilRoot>
          <RunHourGraphTab />
        </RecoilRoot>
      </SealContainer>
    </Root>
  )
}
export default RunHourCard

import styled from '@emotion/styled'
import { Box, Button, CircularProgress, Link } from '@mui/material'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'

import ExternalOpen from '../../../icons/open-external.svg?react'
import PlanningCard from './planning-card/component'
import ResponseCard from './response-card/component'
import SubmitSuccessDialogue from './responses-dialogues/success-submit'
import AlertSubmissionFailedDialogue from './responses-dialogues/failed-submission'
import {
  DiagnosedAnomalyResponse,
  SubmissionReadyCheckFunc,
  UIInternalDiagnosisStateType,
} from '../../../shared/types/diagnosis'
import TickIcon from '../../../icons/tick.svg?react'
import CrossIcon from '../../../icons/cross.svg?react'
import CancelRequestDialogue from './responses-dialogues/cancel-request'
import {
  ISSOWStrategyTypes,
  ParseStrategyDataRules,
} from '../../../shared/types/strategyRule'
import DiagnosisCard from './diagnosis-card/component'
import { acquireTokenInfoSilent } from '../../../shared/auth'
import useStrategyByFloc from '../../../services/hooks/useStrategyData'
import {
  checkUserCanActionOnAnomaly,
  getWopIdOREmail,
  useParamQuery,
} from '../../../shared/utility'
import { useUserTokenContext } from '../../../shared/providers/user-token-provider'
import InvalidFloc from '../invalid-floc/invalid-floc'
import SubmissionDialogue from './submission-clear-dialogue/submission-clear-dialogue'
import useActiveAlertList from '../../anomaly-list/hooks/useActiveAlertList'
import { EquipmentOverviewContext } from '../../../shared/providers/floc-context-provider'
import { PaddedCard } from '../../../shared/components/padded-card'
import sucessImg from '../../../images/diagnosis-success.png'
import warningImg from '../../../images/alert-warning.png'
import { PaddedTabBox } from '../../../shared/components/padded-box'

const NavFooter = styled(Box)`
  width: 100%;
  padding: 0rem 0 0rem 0;
  margin: 0 !important;
  background: none !important;
  box-shadow: none !important;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  svg {
    height: 1rem;
  }
  .backBtn.MuiButton-contained {
    background-color: #dce2e5 !important;
    color: #00354d;
  }
  .backBtn.MuiButton-contained.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.12);
  }
`

const ButtonStyled = styled(Button)`
  align-self: flex-end;
`

const WaitingProgressComp = styled(CircularProgress)`
  margin-left: 1rem;
`

const ErrorDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #666666;
  #red {
    color: #f44336;
    b {
      font-weight: 500;
    }
  }
  a {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  #inline {
    a {
      margin: 0;
      display: inline;
    }
  }
`

interface ResponseStepProps {
  currentTab: number
}

const ResponseStep = ({ currentTab }: ResponseStepProps) => {
  const query = useParamQuery()
  const floc = query.get('floc')
  const token = useUserTokenContext()
  const queryClient = useQueryClient()
  const eqOverview = useContext(EquipmentOverviewContext)
  const [state, setState] = useState(new UIInternalDiagnosisStateType())
  const [diagnosisSubmitResp, setDiagnosisSubmitResp] =
    useState<DiagnosedAnomalyResponse>({})
  const [content, setContent] =
    useState(`Thanks for diagnosing the problem and providing the maintenance
  response. The relevant alert for this equipment has been moved to
  actioned. The maintenance request has been sent to SAP. We will email you
  once the notification and work order are created.`)
  const [submitting, setSubmitting] = useState(false)
  const [openCancelWarningDialogue, setOpenCancelWarningDialogue] =
    useState(false)
  const [openSubmissionDialogue, setOpenSubmissionDialogue] = useState(false)
  const { data: activeData } = useActiveAlertList(floc)
  const {
    data: failureModesResponses,
    error: fmodeError,
    isLoading: failureModeLoading,
  } = useStrategyByFloc(floc)
  const myHistroy = useHistory()
  const { instance: msalInstance } = useMsal()

  const { mutate: mutatePostDiagnosis } = useMutation(
    async (inputToAPICall: any) => {
      const tokenResult = await acquireTokenInfoSilent(
        msalInstance,
        import.meta.env.VITE_AAD_CLIENTID! + '/.default'
      )
      return axios(
        import.meta.env.VITE_CBM_ENDPOINT! + `diagnosis/${inputToAPICall.key}`,
        {
          method: 'POST',
          data: { ...inputToAPICall.body },
          headers: {
            Authorization: `Bearer ${tokenResult!.accessToken}`,
          },
        }
      )
    },
    {
      onSuccess: async (response: DiagnosedAnomalyResponse) => {
        await queryClient.invalidateQueries('Active')
        await queryClient.invalidateQueries('Diagnosed')
        const {
          permitMessage,
          permitName,
          isolationCerts,
          measurementDocNumber,
        } = response.data!
        const { templateToApply, masterRelationshipTemplatesToCreate } =
          state.diagnosisResponseType
        const permitType = ParseStrategyDataRules(templateToApply)
        const issowType = ParseStrategyDataRules(
          masterRelationshipTemplatesToCreate
        )
        if (permitMessage) {
          if (
            permitMessage.includes(
              `The Template ${templateToApply} is not in the Planning Approved state`
            )
          ) {
            setContent(`
              This request was successfully submitted to SAP but unfortunately an
              error to do with planning status of the permit template has prevented
              us from processing the permit request in ISSOW. We will email you
              once the notification and work order are created.`)
          } else if (
            permitMessage.includes(
              `The Template ${masterRelationshipTemplatesToCreate} is not in the Planning Approved state`
            )
          ) {
            setContent(`
              This request was successfully submitted to SAP but unfortunately an
              error to do with planning status of the isolation certificate 
              template(s) has prevented us from processing the permit request in
              ISSOW. We will email you once the notification and work order are created.
            `)
          }
        } else {
          if (
            (permitType === ISSOWStrategyTypes.Templated && !permitName) ||
            (issowType === ISSOWStrategyTypes.Templated && !isolationCerts)
          ) {
            setContent(
              `This request was successfully submitted to SAP but unfortunately an
              error has occured preventing us from processing the permit request in
              ISSOW. We will email you once the notification and work order are
              created.`
            )
          }
        }

        if (permitName && isolationCerts && !measurementDocNumber) {
          setContent(
            `This request was successfully submitted to ISSOW but unfortunately an
            error has occured preventing us from processing the work request in
            SAP.`
          )
        } else if (!permitName && !isolationCerts && !measurementDocNumber) {
          setContent(
            `Unfortunately an error has occured preventing us from submitting this
            request to SAP and ISSOW. As the CBM workflow is unable to continue,
            this maintenance request has been cancelled.`
          )
        }
        setDiagnosisSubmitResp(response)
      },
      onError: (error: Error) => {
        setDiagnosisSubmitResp({ status: 500 })
      },
      onSettled: () => {
        setSubmitting(false)
      },
    }
  )

  const handleClickSubmitDiagnosis = () => {
    if (activeData && activeData.length > 0) {
      setOpenSubmissionDialogue(true)
    } else {
      handleSubmitDiagnosis()
    }
  }

  const handleSubmitDiagnosis = async () => {
    let queryKey = ''
    state.diagnosisResponseType.diagnosedBy = getWopIdOREmail(token)
    state.diagnosisResponseType.userEmail = token?.account?.username
    state.diagnosisResponseType.fuseEquipmentType =
      eqOverview?.equipmentType ?? ''
    const body = state.diagnosisResponseType.formattedDiagnosisResponse
    if (state.processingAlert) {
      queryKey = `${state.processingAlert.key}`
    } else {
      queryKey = `${floc}-AdhocAnomalyDiagnosis`
      body.adhocAnomalyDiagnosis = true
    }
    setSubmitting(true)
    mutatePostDiagnosis({ body, key: queryKey })
  }

  const handleDialogClose = () => {
    setDiagnosisSubmitResp({})
    myHistroy.push('/')
  }

  const ResponseSection = () => {
    return (
      <>
        <DiagnosisCard
          diagnosisState={state}
          stateChangeCallback={(newData) => {
            setState(newData)
          }}
          failureModesResponses={failureModesResponses!}
        />
        <ResponseCard
          diagnosisState={state}
          stateChangeCallback={(newData) => {
            setState(newData)
          }}
          failureModesResponses={failureModesResponses!}
        />
        <PlanningCard
          diagnosisState={state}
          stateChangeCallback={(newData) => {
            setState(newData)
          }}
        />
        <NavFooter>
          <ButtonStyled
            className="backBtn"
            variant="contained"
            data-testid="submitDiagnosisButton"
            disabled={submitting}
            onClick={(e) => {
              setOpenCancelWarningDialogue(true)
            }}
            startIcon={<CrossIcon />}
          >
            Cancel Request
          </ButtonStyled>
          <ButtonStyled
            variant="contained"
            id="muttonButton"
            title="Mutton Button"
            color="primary"
            startIcon={<TickIcon />}
            disabled={
              !SubmissionReadyCheckFunc(state.diagnosisResponseType) ||
              submitting
            }
            onClick={handleClickSubmitDiagnosis}
          >
            {submitting && !diagnosisSubmitResp.data ? (
              <>
                {'Submitting...'} <WaitingProgressComp size={20} />
              </>
            ) : (
              <>{'Submit to Maintenance'}</>
            )}
          </ButtonStyled>
        </NavFooter>
      </>
    )
  }

  const getValidComponent = () => {
    if (failureModeLoading) {
      return <WaitingProgressComp />
    } else {
      if (fmodeError) {
        return (
          <PaddedCard boxShadow={1} data-testid="error-backend">
            <Box style={{ color: '#f44336' }}>
              <b>Error:</b> Its looks like we’re currently unable to return the
              diagnosis options for this equipment. Please contact IT support or
              try again later.
            </Box>
            <Box style={{ opacity: '0.8' }}>
              You can continue with this response manually in SAP or come back
              to CBM a later time.
            </Box>
            <Link
              href="https://smLinkrt.app.woodside/my-notifications"
              target="_blank"
              rel="noreferrer"
              style={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                gap: '0.3rem',
              }}
            >
              <ExternalOpen style={{ width: '1rem', height: '1rem' }} />
              Open SAP Notification Smart App (Fixit)
            </Link>
          </PaddedCard>
        )
      } else {
        return failureModesResponses!.length === 0 ? (
          <InvalidFloc floc={floc!} isOnboarded={false} isValid={true} />
        ) : (
          ResponseSection()
        )
      }
    }
  }

  return (
    <PaddedTabBox hidden={currentTab !== 2}>
      <AlertSubmissionFailedDialogue
        open={diagnosisSubmitResp.status === 500}
      />
      <CancelRequestDialogue
        open={openCancelWarningDialogue}
        onClose={() => {
          setOpenCancelWarningDialogue(false)
        }}
      />
      <SubmissionDialogue
        open={openSubmissionDialogue}
        onClose={(submit: Boolean) => {
          setOpenSubmissionDialogue(false)
          if (submit) {
            handleSubmitDiagnosis()
          }
        }}
        diagnosisState={state}
        stateChangeCallback={(newData) => {
          setState(newData)
        }}
      />
      {diagnosisSubmitResp.data && (
        <SubmitSuccessDialogue
          onClose={handleDialogClose}
          isoNumber={diagnosisSubmitResp.data!.isolationCerts!}
          permitName={diagnosisSubmitResp.data!.permitName!}
          permitID={diagnosisSubmitResp.data!.permitID!}
          permitType={ParseStrategyDataRules(
            state.diagnosisResponseType.templateToApply
          )}
          issowType={ParseStrategyDataRules(
            state.diagnosisResponseType.masterRelationshipTemplatesToCreate
          )}
          measurementDocNumber={diagnosisSubmitResp.data!.measurementDocNumber!}
          image={
            diagnosisSubmitResp.data!.permitID &&
            diagnosisSubmitResp.data!.measurementDocNumber
              ? sucessImg
              : warningImg
          }
          alt={
            diagnosisSubmitResp.data!.permitID &&
            diagnosisSubmitResp.data!.measurementDocNumber
              ? 'SuccessImag'
              : 'warningImg'
          }
          heading={
            diagnosisSubmitResp.data!.permitID &&
            diagnosisSubmitResp.data!.measurementDocNumber
              ? 'Response Submitted!'
              : !diagnosisSubmitResp.data!.permitID &&
                  !diagnosisSubmitResp.data!.measurementDocNumber
                ? 'Submission Failed!'
                : 'Partial Success!'
          }
          content={content}
        />
      )}
      {checkUserCanActionOnAnomaly(token) ? (
        getValidComponent()
      ) : (
        <PaddedCard>
          <ErrorDiv data-testid="error-no-access">
            <Box id="red">
              <Box fontWeight="fontWeightMedium" display="inline">
                Error:
              </Box>{' '}
              Your user account is not enabled to continue with a maintenance
              response.
            </Box>
            <Box id="inline">
              To get access to this functionality please submit a request via
              access management. This can be found in Willow or at{' '}
              <Link href={'https://ssm-saviyntcloud.woodside.com.au/ECM'}>
                https://ssm-saviyntcloud.woodside.com.au/ECM
              </Link>
            </Box>
          </ErrorDiv>
        </PaddedCard>
      )}
    </PaddedTabBox>
  )
}

export default ResponseStep

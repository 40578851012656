import React, { useRef, forwardRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { AccordionDetails, AccordionSummary, Box } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import { withStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import ContextCard from '../context-card/component'
import MaintenanceJob from '../../../../shared/types/maintenanceJob'

interface SingleAccordionProps {
  expand: boolean
  handlePanalChange(accordionName: string): void
  panelHeight: number
  accordionName: string
  accordionHeading: string
  accordionSubheading?: string
  noDataMessage: string
  equipmentContextData: MaintenanceJob[]
}

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummaryStyled = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    flex-wrap: nowrap;
    flex-direction: column;
    cursor: default;
  }
  ,
  .MuiAccordionSummary-expandIcon {
    color: black;
  }
`

const ContextCardDiv = styled(Box)`
  margin: 0.5rem;
`

const DateComment = styled.p`
  font-size: 0.7rem;
  color: #666666;
  margin-left: 0.8rem;
`

const MaintenanceHeading = styled(Box)`
  margin-left: 0.8rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  h2 {
    font-size: 1rem;
    margin: 0;
    @media (max-height: 1000px) {
      font-size: 1rem;
    }
  }
  div {
    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: 500;
    background: #001543;
    color: white;
    text-align: center;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
`

const AccordionDetailsStyled = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: auto;
  position: relative;
  padding-top: 0 !important;
`

const NoDataDiv = styled(Box)`
  margin: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
`

const SingleAccordion = forwardRef((props: SingleAccordionProps, ref: any) => {
  const panelContent = useRef<HTMLDivElement | null>(null)

  //code for setting dynamic dialogue
  useEffect(() => {
    if (panelContent.current) {
      panelContent.current.style.height = `${props.panelHeight}px`
    }
  }, [props.panelHeight])

  return (
    <Accordion
      square
      expanded={props.expand}
      onChange={() => props.handlePanalChange(props.accordionName)}
    >
      <AccordionSummaryStyled
        expandIcon={<ExpandMoreIcon />}
        aria-controls={props.accordionName + '-content'}
        id={props.accordionName + '-header'}
        ref={ref}
      >
        <MaintenanceHeading style={{ marginBottom: '0' }}>
          <h2>{props.accordionHeading}</h2>
          {props.equipmentContextData &&
            props.equipmentContextData.length > 0 && (
              <div>{props.equipmentContextData.length}</div>
            )}
        </MaintenanceHeading>
        {props.accordionSubheading && (
          <DateComment>{props.accordionSubheading}</DateComment>
        )}
      </AccordionSummaryStyled>
      <AccordionDetailsStyled ref={panelContent}>
        {props.equipmentContextData.map((maintenanceJobInputData, index) => {
          return (
            <ContextCardDiv key={index}>
              <ContextCard maintenanceJobInputData={maintenanceJobInputData} />
            </ContextCardDiv>
          )
        })}
        {props.equipmentContextData &&
          props.equipmentContextData.length === 0 && (
            <NoDataDiv>{props.noDataMessage}</NoDataDiv>
          )}
      </AccordionDetailsStyled>
    </Accordion>
  )
})

export default SingleAccordion

import { AuthenticationResult } from '@azure/msal-browser'
import React, { createContext, ReactNode } from 'react'

import useResourcingToken from '../../services/hooks/useTokenService'

const UserTokenContext = createContext<AuthenticationResult | undefined>(
  undefined
)

export function useUserTokenContext() {
  return React.useContext(UserTokenContext)
}

type PropsType = {
  children: ReactNode
}

export default function UserTokenProvider({ children }: PropsType) {
  const { data: token } = useResourcingToken(
    import.meta.env.VITE_AAD_CLIENTID! + '/.default'
  )

  return (
    <UserTokenContext.Provider value={token}>
      {children}
    </UserTokenContext.Provider>
  )
}

import styled from '@emotion/styled'
import { Box, CircularProgress } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useState, useEffect } from 'react'

import EyeIcon from '../../../../../icons/eye.svg?react'
import useEquipmentHierarchy from '../../../../../services/hooks/useEquipmentHierarchyData'
import AlertNotification from '../../../../../shared/components/alert-notification'
import ValidFlocGuard from '../../../../../shared/components/ValidFlocGuard'
import {
  getFlatFlocTreeWithDesc,
  getlastUPdatedStringMinsAgo,
} from '../../../../../shared/utility'
import useSAData from '../../hooks/useSAData'
import SituationAwarenessView from './situation-map/component'
import { PaddedCard } from '../../../../../shared/components/padded-card'

const Heading = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`

const HeadingTitle = styled.h2`
  margin-left: 0.1rem;
  margin-bottom: 0;
  flex: 1 auto;
`

const WaitingProgressComp = styled(CircularProgress)`
  margin-top: 1rem;
`

const LastUpdatedDiv = styled(Box)`
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
`

interface SituationAwarenessCardProps {
  showFullLengthCallBack: (showFullLength: boolean) => void
}

const SituationAwarenessCard = (props: SituationAwarenessCardProps) => {
  //if browser height > 900, then default to true
  const [expanded, setExpanded] = useState<boolean>(false)
  const [currentFloc, setCurrentFloc] = useState('')
  const [finFanDeck, setFinFanDeck] = useState('')
  const [lastUpdatedUnix, setLastUpdatedUnix] = useState(0)
  const [flatFlocTree, setFlatFlocTree] =
    useState<[{ floc: string; desc: string }]>()
  // get current selection's hierarchy
  const {
    data: flocHierarchyData,
    error: flocHierarchyError,
    isLoading: flocHierachryLoading,
  } = useEquipmentHierarchy(currentFloc)
  const { data: flocHierarchyParentData } = useEquipmentHierarchy(finFanDeck)
  // get SA data from the hierarchy
  const { data: saResponse, error, isLoading } = useSAData(finFanDeck || '', 3)

  useEffect(() => {
    if (window.innerHeight > 700) {
      setExpanded(true) // default open if it has more than this height
    }
    //testing full width
    //will be dynamic based on number of fin-fans
    if (saResponse && saResponse.children) {
      const flocTester = new RegExp('K[0-9]+$')
      const flocs = saResponse.children.filter((floc: any) => {
        return flocTester.test(floc.floc)
      })
      props.showFullLengthCallBack(flocs.length > 19)
    }
  }, [saResponse])

  useEffect(() => {
    if (flocHierarchyData && flocHierarchyData.parentEquipmentTagname) {
      const parentAsset = flocHierarchyData.parentFloc.split('.')[0]
      setFinFanDeck(
        parentAsset + '.' + flocHierarchyData.parentEquipmentTagname
      )
    }
  }, [flocHierarchyData])

  useEffect(() => {
    // check parent hierarchy response exist and it's an exch (deck)
    if (flocHierarchyParentData && flocHierarchyParentData.attributes) {
      setFlatFlocTree(
        getFlatFlocTreeWithDesc(flocHierarchyParentData, [{}] as any)
      )
    }
  }, [flocHierarchyParentData])

  const isValidCallBack = (isValidFloc: boolean, currentFlocResp: string) => {
    if (isValidFloc) {
      setCurrentFloc(currentFlocResp)
    }
  }

  useEffect(() => {
    if (saResponse && saResponse.children && saResponse.children.length) {
      const groupStatus = saResponse.children.find((row) => {
        return row.measurements.find(
          (m) => m.key === 'MEASUREMENT_SITUATIONAL_AWARENESS_GROUP_STATUS'
        )
      })
      if (groupStatus) {
        const lastUpdatedUnix = groupStatus.measurements.find(
          (m) => m.key === 'MEASUREMENT_SITUATIONAL_AWARENESS_GROUP_STATUS'
        )!.values[0].timestamp
        setLastUpdatedUnix(lastUpdatedUnix)
      }
    }
  }, [saResponse])

  return (
    <PaddedCard>
      <Heading
        role="button"
        style={{ cursor: 'pointer' }}
        onClick={() => setExpanded(!expanded)}
      >
        <HeadingTitle>
          <EyeIcon style={{ marginRight: '.5rem', height: '1rem' }} />
          Situational Awareness
        </HeadingTitle>
        <LastUpdatedDiv>
          {lastUpdatedUnix &&
            `Last updated ${getlastUPdatedStringMinsAgo(lastUpdatedUnix)}`}
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </LastUpdatedDiv>
        <span />
      </Heading>
      <ValidFlocGuard isValidCallback={isValidCallBack}>
        {(isLoading || flocHierachryLoading) && <WaitingProgressComp />}
        {(error || flocHierarchyError) && (
          <AlertNotification
            type="error"
            message={
              ((flocHierarchyError &&
                'An error has occurred while fetching the floc hierarchy. Please come back later :) ') ||
                (error &&
                  'An error has occurred while fetching the SA data')) as string
            }
          />
        )}
        {saResponse && saResponse.equipmentTagname && flatFlocTree && (
          <SituationAwarenessView
            flocMetaDataForDesc={flatFlocTree}
            expanded={expanded}
            inputData={saResponse}
            finFanDeckLabel={finFanDeck}
          />
        )}
        {((!(isLoading || flocHierachryLoading) &&
          saResponse &&
          !saResponse.equipmentTagname) ||
          saResponse === null ||
          flatFlocTree === null) &&
          'No data to show at this time :)'}
      </ValidFlocGuard>
    </PaddedCard>
  )
}
export default SituationAwarenessCard

import moment from 'moment'
import { ALSResponse } from '../../features/anomaly-review/condition-tab/pump-cards/fluid-monitoring/hooks/ALSType'
import { EquipmentType } from './equipmentTypes'

export type AnomalyOutputType =
  | 'vibrationSeverity'
  | 'sealSeverity'
  | 'performanceSeverity'
  | 'equipFaultSeverity'
  | 'losslessSealPressure'

export function getDesiredAnomalyType(
  equipmentType: EquipmentType
): AnomalyOutputType[] {
  if (equipmentType === EquipmentType.pump) {
    return [
      'vibrationSeverity',
      'performanceSeverity',
      'sealSeverity',
      'equipFaultSeverity',
    ]
  } else {
    return ['equipFaultSeverity']
  }
}

export const AnomalySeverityPrettyNames = {
  vibrationSeverity: 'Vibration Anomaly Severity',
  performanceSeverity: 'Performance Anomaly Severity',
  sealSeverity: 'Seal Anomaly Severity',
  equipFaultSeverity: 'Overall Anomaly Severity',
}

export class AnomalyModelOutputObj {
  measureName: AnomalyOutputType
  confidence: number
  value: string
  type: string
  time: string
  datePart: number
  hourPart: number
  constructor(data: any) {
    Object.assign(this, data)
    this.datePart = moment(data.time).startOf('day').valueOf()
    this.hourPart = moment(data.time).startOf('hour').valueOf()
  }
  get valueOf() {
    return Number.parseFloat(this.value)
  }
}

// https://github.com/WET-IntelligentSystems/IntelligentAssets-Equipment360/blob/master/services/pam/src/appsync/equipmentPamTask.ts
export class PAMObj {
  equipment: string
  id: string
  description: string
  detail?: string
  purpose: string
  facilityName: string
  areaName: string
  locationName: string
  responsibleArea: string
  status: string
  consequences: string
  operatorAction: string
  workInstruction: string
  resultValueList: string
  unitOfMeasure: string
  limitLow: string
  limitHigh: string
  targetValue: string
  targetRange: string
  isDisabled: string
  results: PAMResult[]
}
export class PAMResult {
  result: string
  comments?: string
  recordedFor: string // "2019-12-29T00:00:00",
  recordedBy: string
  recordedDate: string //'2020-01-02T19:35:29.427';
  get getRecordDate() {
    return moment(this.recordedDate)
  }
}
export default class GetEquipment {
  id: string
  requesterId: string
  equipmentId: string
  FLOC: string
  pamTasks?: PAMObj[]
  equipmentType: string
  alsReports?: ALSResponse
  anomalyModelOutputs: AnomalyModelOutputObj[]
  constructor(data: Partial<GetEquipment>) {
    Object.assign(this, data)
    if (data.anomalyModelOutputs) {
      // force construction instead
      this.anomalyModelOutputs = data.anomalyModelOutputs.map(
        (o: any) => new AnomalyModelOutputObj(o)
      )
    }
  }
}

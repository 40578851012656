import styled from '@emotion/styled'
import { Box, Divider } from '@mui/material'
import React from 'react'

import { AlertDetail } from '../../../../../shared/types/alert'
import FlocCardContent from '../../../../anomaly-list/table-tabs/active/floc-card-content'

export interface ActiveListContentProps {
  alerts: AlertDetail[] | undefined
  value: number
  index: number
}

const EmptyBox = styled(Box)`
  display: grid;
  place-items: left;
  opacity: 60%;
  margin-top: 1rem;
`

const ContentRow = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
  #Content {
    width: 100%;
  }
  #CancelButton {
    margin-left: auto;
  }
`

const ActiveListContentTab = (props: ActiveListContentProps) => {
  const { value, index, ...other } = props
  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {props.alerts!.map((theAlert, index) => {
        const showDivider = index !== props.alerts!.length - 1
        return (
          <div key={index}>
            <ContentRow>
              <div id="Content">
                <FlocCardContent
                  alertDetail={theAlert}
                  renderedInDiagnosis={true}
                />
              </div>
            </ContentRow>
            {showDivider && <Divider />}
          </div>
        )
      })}
      {!props.alerts?.length && (
        <EmptyBox>
          <p>
            Good news! It looks like there are no new active alerts to review
            for this equipment. We will let you know if something new pops up.
          </p>
        </EmptyBox>
      )}
    </div>
  )
}

export default ActiveListContentTab

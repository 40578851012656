import { GlobalDateFormatter, sortByObjectProperty } from '../utility'
import { AlertDetail, AlertTypes } from './alert'
import { SortableEntity, SortDir, SortKeyEnum } from './sortableEntity'

// convert floc alert level to floc level
export class FlocAlerts implements SortableEntity {
  floc: string
  earliestActivetimestamp: Date
  earliestActiveNiceString: string
  alerts: AlertDetail[]
  getFirstAlertWithType(type: AlertTypes): AlertDetail {
    const finding = this.alerts.filter((row) => row.alertType === type)
    if (finding && finding.length > 0) {
      return finding[0]
    } else {
      return {} as AlertDetail
    }
  }

  getSortableField(sortKey: SortKeyEnum): any {
    switch (sortKey) {
      case SortKeyEnum.FLOC:
        return this.floc
      case SortKeyEnum.DATE:
        return this.earliestActivetimestamp
      case SortKeyEnum.TYPE:
        return this.alertTypes[0]
      case SortKeyEnum.SEVERITY:
        return this.alerts[0].severity // pick the first one.. ideally should be vibration only
    }
  }

  get alertTypes(): AlertTypes[] {
    return Array.from(new Set(this.alerts.map((row) => row.alertType)))
  }
  constructor(floc: string, allThisFlocAlerts: AlertDetail[]) {
    //egTime 2020-08-04 16:42:49.914135
    this.floc = floc
    allThisFlocAlerts = allThisFlocAlerts.sort((a, b) => {
      return a.timestampDateRaised.getTime() - b.timestampDateRaised.getTime()
    })

    const firstActiveTime = allThisFlocAlerts[0].timestampDateRaised

    this.earliestActivetimestamp = firstActiveTime
    this.earliestActiveNiceString = GlobalDateFormatter.format(firstActiveTime)
    this.alerts = allThisFlocAlerts
  }
}

export const ConvertToFlocAlerts = (allAlerts: AlertDetail[]) => {
  const groups = allAlerts.reduce((groups, item) => {
    const group = groups[item.floc] || []
    group.push(item)
    groups[item.floc] = group
    return groups
  }, {})
  const flocAlerts = Object.keys(groups).map((floc) => {
    return new FlocAlerts(floc, groups[floc])
  })

  return flocAlerts
}

export function SortFlocAlerts(
  flocAlerts: FlocAlerts[],
  sortKey: SortKeyEnum,
  sortDir: SortDir
) {
  return sortByObjectProperty(
    flocAlerts,
    sortKey,
    sortDir,
    sortKey === SortKeyEnum.FLOC
  )
}

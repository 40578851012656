import React, { useState, useEffect, ChangeEvent, ReactNode } from 'react'
import {
  FormControl,
  Button,
  Dialog,
  IconButton,
  Theme,
  Typography,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Box,
} from '@mui/material'
import { withStyles, createStyles, WithStyles } from '@mui/styles'
import MuiDialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import styled from '@emotion/styled'

import WarningIcon from '../../../../icons/exclamation-triangle.svg?react'
import QuestionMark from '../../../../icons/question-mark.svg?react'
import AlertTypeMappingRecord from '../../../../shared/components/alert-metadata'
import { AlertDetail } from '../../../../shared/types/alert'
import DiagnosisResponseType, {
  UIInternalDiagnosisStateType,
} from '../../../../shared/types/diagnosis'
import { useParamQuery } from '../../../../shared/utility'
import useActiveAlertList from '../../../anomaly-list/hooks/useActiveAlertList'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      height: 0,
      width: 500,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    body1: {
      fontSize: '25px',
      fontWeight: 500,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography
        align="left"
        display="inline"
        variant="body1"
        className={classes.body1}
      >
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    fontSize: '16px',
    fontWeight: 500,
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}))(MuiDialogContent)

const DialogContentInner = withStyles((theme: Theme) => ({
  root: {
    fontSize: '16px',
    fontWeight: 500,
    margin: 0,
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogContentNormal = withStyles((theme: Theme) => ({
  root: {
    fontSize: '16px',
    fontWeight: 400,
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const DialogStyled = withStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}))(Dialog)

const Hint = styled(Box)`
  margin-left: 1rem;
  font-size: 1rem;
  color: #0064ce;
  margin-top: 2rem;
  margin-bottom: 2rem;
  svg {
    margin-right: 0.5rem;
  }
`

const LabelDiv = styled(Box)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  svg {
    width: 1rem;
    height: 1rem;
  }
  i {
    font-size: 80%;
    opacity: 0.7;
  }
`

const WaitingProgressComp = styled(CircularProgress)`
  margin-right: 0.3rem;
`

const ToolTipSpan = styled.span`
  font-size: 0.9rem;
  color: #0064ce;
  margin-left: 0.5rem;
  svg {
    margin-right: 0.2rem;
    top: 0.1rem;
    width: 1rem;
    position: relative;
  }
`

interface SubmissionDialogueProps {
  open: boolean
  onClose: (submit: boolean) => void
  diagnosisState: UIInternalDiagnosisStateType
  stateChangeCallback: (newData: UIInternalDiagnosisStateType) => void
}

const SubmissionDialogue = (props: SubmissionDialogueProps) => {
  const { diagnosisState: state, stateChangeCallback: setState } = props
  const mySearchQuery = useParamQuery()
  const [processingAnomaly, setProcessingAnomaly] =
    useState<AlertDetail | null>(null)
  const { data: activeData } = useActiveAlertList(mySearchQuery.get('floc'))

  useEffect(() => {
    if (processingAnomaly) {
      setState({
        ...state,
        processingAlert: processingAnomaly,
        diagnosisResponseType: new DiagnosisResponseType({
          ...state.diagnosisResponseType,
          drivenFloc: processingAnomaly.floc,
          id: processingAnomaly.id!,
        }),
      })
    } else {
      setState({
        ...state,
        processingAlert: undefined,
        diagnosisResponseType: new DiagnosisResponseType({
          ...state.diagnosisResponseType,
          id: '',
        }),
      })
    }
  }, [processingAnomaly])

  useEffect(() => {
    if (activeData) {
      if (
        mySearchQuery.get('adhoc')! &&
        mySearchQuery.get('adhoc') === 'true'
      ) {
        setProcessingAnomaly(null)
      } else {
        setProcessingAnomaly(activeData[0])
      }
    } else {
      setProcessingAnomaly(null)
    }
  }, [activeData])

  const handleSelectAlertType = (event: ChangeEvent<HTMLInputElement>) => {
    const alertId = (event.target as HTMLInputElement).value
    if (alertId === 'ad-hoc') {
      setProcessingAnomaly(null)
    } else {
      const alert = activeData!.filter((row) => row.id === alertId)
      setProcessingAnomaly(alert[0])
    }
  }

  return (
    <DialogStyled
      open={props.open}
      aria-labelledby="form-dialog-title"
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return
        }
        props.onClose(false)
      }}
    >
      <DialogTitle
        onClose={() => {
          props.onClose(false)
        }}
        id="form-dialog-title"
      >
        Clear Active Alert
      </DialogTitle>
      <FormControl variant="standard">
        <DialogContentNormal>
          There is at least one active alert against this equipment. If you are
          taking action based on an alert it is recommended that you clear it
          after submitting a maintenance response.
        </DialogContentNormal>
        <DialogContent>
          Would you like to clear any alert
          {activeData && activeData.length > 1 ? 's' : ''} with this response
          (Select one)?
          <Tooltip
            title={
              'CBM can currently only process one alert at a time. If the same fault is causing multiple alerts, diagnose one of the alerts and then remove the others.'
            }
            disableInteractive
          >
            <ToolTipSpan id="questionSection">
              <QuestionMark style={{ width: '18px' }} />
            </ToolTipSpan>
          </Tooltip>
        </DialogContent>
        <DialogContentInner>
          <RadioGroup
            aria-label="alertTypeGroup"
            data-testid="multiRadioGroup"
            name="alertTypes"
            value={processingAnomaly ? processingAnomaly.id : 'ad-hoc'}
            onChange={handleSelectAlertType}
          >
            {activeData ? (
              activeData.map((alert, index) => {
                return (
                  <FormControlLabel
                    data-testid={`radioAlertLabel-${
                      AlertTypeMappingRecord[alert.alertType].displayText
                    }`}
                    key={index}
                    value={alert.id}
                    control={<Radio />}
                    label={
                      <LabelDiv>
                        {AlertTypeMappingRecord[alert.alertType].getIcon()}
                        {AlertTypeMappingRecord[alert.alertType].displayText}
                        <i>active since {alert.dateRaised}</i>
                      </LabelDiv>
                    }
                  />
                )
              })
            ) : (
              <WaitingProgressComp />
            )}
            <FormControlLabel
              data-testid={'radioAlertLabel-ad-hoc'}
              key={'ad-hoc'}
              value={'ad-hoc'}
              control={<Radio />}
              label={"Don't clear any alerts"}
            />
          </RadioGroup>
        </DialogContentInner>
      </FormControl>
      <Hint>
        <WarningIcon style={{ height: '0.8rem' }} />
        Actioned alert information can be found under the &quot;Actioned&quot;
        tab on the home page.
      </Hint>
      <DialogActions>
        <Button
          variant="contained"
          onClick={(e) => {
            props.onClose(true)
          }}
          color="primary"
        >
          {processingAnomaly === null ? '' : 'Clear and '}Submit
        </Button>
      </DialogActions>
    </DialogStyled>
  )
}

export default SubmissionDialogue

import { Snackbar, Alert } from '@mui/material'
import React, { useState, FC } from 'react'

import { useLoggingContext } from '../providers/spa-logging-provider'

export type AlertNotificationPropsType = {
  type: 'error' | 'info' | 'success' | 'warning'
  message: string
  duration?: number
  onHide?: () => void
}

const AlertNotification: FC<AlertNotificationPropsType> = ({
  type,
  message,
  duration,
  onHide,
}) => {
  const [open, setOpen] = useState<boolean>(true)
  const loggingContext = useLoggingContext()
  if (type === 'error') {
    loggingContext.log('ERROR', message)
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={duration ? duration : 6000}
      onClose={() => {
        setOpen(false)
        if (onHide) {
          onHide()
        }
      }}
    >
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  )
}

export default AlertNotification

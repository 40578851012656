import styled from '@emotion/styled'
import { Box, Divider } from '@mui/material'
import React from 'react'

import { FlocAlerts } from '../../../../shared/types/flocAlerts'
import FlocCardContent from './floc-card-content'

export interface FlocCardProps {
  filteredFlocAlert: FlocAlerts
}

const TitleDiv = styled.h3`
  min-width: 12%;
  flex: 0 0 10%;
  margin-bottom: 0;
  font-weight: ${(props: any) => props.fontWeight};
`

const AlertDetailsStyled = styled(Box)`
  display: flex;
  flex: 1 1 90%;
  flex-direction: column;
`

const BoxDetailsStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`

const StyledBox = styled(Box)`
  padding: 1.2rem;
  background-color: white;
  border-radius: 0.5rem;
`

const TextPair = styled(Box)`
  flex: 1 1 25%;
  display: flex;
  flex-direction: column;
  span {
    font-size: 16px;
  }
  h5 {
    opacity: 60%;
    margin-bottom: 0.4rem;
  }
`

const FlocCard = (props: FlocCardProps) => {
  return (
    <StyledBox data-testid={'flocCardRow'}>
      <BoxDetailsStyled>
        <TitleDiv data-testid="flocCardTitle">
          <TextPair>
            <h5>FLOC</h5>
            <span>{props.filteredFlocAlert.floc}</span>
          </TextPair>
        </TitleDiv>
        <AlertDetailsStyled>
          {props.filteredFlocAlert.alertTypes.map((alertType, index) => {
            const theAlert =
              props.filteredFlocAlert.getFirstAlertWithType(alertType)
            const showDivider =
              index !== props.filteredFlocAlert.alertTypes.length - 1
            return (
              <div key={alertType + theAlert.floc}>
                <FlocCardContent alertDetail={theAlert} />
                {showDivider && <Divider style={{ margin: '1rem 0' }} />}
              </div>
            )
          })}
        </AlertDetailsStyled>
      </BoxDetailsStyled>
    </StyledBox>
  )
}

export default FlocCard

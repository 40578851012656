import React from 'react'
import { Table, Tooltip } from 'antd'
import styled from '@emotion/styled'
import { CircularProgress, Box } from '@mui/material'

import { PartsTableData } from '../../../../shared/types/tasklistTypes'

const partsColumns = [
  {
    title: 'Operation No.',
    dataIndex: 'opNo',
  },
  {
    title: 'Material No.',
    dataIndex: 'materialNo',
  },
  {
    title: 'Material Description',
    dataIndex: 'matDesc',
    render: (text: string) => (
      <Tooltip title={text}>
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '20rem',
          }}
        >
          {text}
        </div>
      </Tooltip>
    ),
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Obsolete?',
    dataIndex: 'obsolete',
  },
  {
    title: 'Stock Level',
    dataIndex: 'stocklevel',
  },
  {
    title: 'Lead Time (days)',
    dataIndex: 'leadTime',
  },
]

const CircularProgressComp = styled(CircularProgress)`
  margin-bottom: 1rem;
`

const ErrorDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #666666;
  #red {
    color: #f44336;
    b {
      font-weight: 500;
    }
  }
  a {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`

export interface TaskListTableProps {
  materialTableData: undefined | PartsTableData[]
  showError: boolean
}

const TaskListPartsTable = (props: TaskListTableProps) => {
  return (
    <>
      {props.showError ? (
        <ErrorDiv>
          <div id="red">
            <b>Error:</b> It looks like we’re currently unable to show any
            maintenance planning data for this response.
          </div>
          <div>
            Either there is a connection issue or the SAP API is currently
            unavailable. You can still continue with the diagnosis and response
            submission.
          </div>
        </ErrorDiv>
      ) : (
        <>
          {!props.materialTableData && props.materialTableData ? (
            <CircularProgressComp />
          ) : (
            <Table
              style={{ width: '100%' }}
              columns={partsColumns}
              dataSource={props.materialTableData}
              size="small"
              pagination={false}
            />
          )}
        </>
      )}
    </>
  )
}

export default TaskListPartsTable

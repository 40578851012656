import styled from '@emotion/styled'
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material'
import { withStyles, createStyles } from '@mui/styles'
import React, { useState, ChangeEvent } from 'react'

import TriangleIcon from '../../../../../icons/exclamation-triangle.svg?react'
import AlertNotification from '../../../../../shared/components/alert-notification'
import { useParamQuery } from '../../../../../shared/utility'
import useActiveAlertList from '../../../../anomaly-list/hooks/useActiveAlertList'
import useResolvedAlertList from '../../../../anomaly-list/hooks/useResolvedList'
import useSnoozedAlertList from '../../../../anomaly-list/hooks/useSnoozedAlertList'
import ActionListTableTab from './action-list-table'
import ActiveListContentTab from './active-list-content'

const AlertBoxContainer = styled(Box)`
  background-color: white;
  padding: 0 1em 1em 0em;
`

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    height: '0.15rem',
    backgroundColor: '#00354D',
  },
})(Tabs)
interface StyledTabProps {
  label: string
}

const TabStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      textTransform: 'none',
      opacity: '0.60',
      fontWeight: 400,
      '&:hover': {
        color: '#00354D',
        opacity: 0.8,
      },
      '&$selected': {
        color: '#00354D',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#00354D',
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab {...props} />)

const Root = styled(Box)`
  grid-template-columns: repeat(14, 1fr);
  flex-shrink: 0;
  padding: 1em;
`

const WaitingProgressComp = styled(CircularProgress)`
  grid-column: 4/12;
`

const AnomalyListAlertCard = () => {
  const mySearchQuery = useParamQuery()
  const [tabSelectionValue, setTabSelectionValue] = useState(0)

  const { data: activeData, error: activeError } = useActiveAlertList(
    mySearchQuery.get('floc')
  )
  const { data: cancelledData, error: cancelledError } = useSnoozedAlertList(
    mySearchQuery.get('floc')
  )
  const { data: diagnosedData, error: diagnosedError } = useResolvedAlertList(
    mySearchQuery.get('floc')
  )

  const isLoaded = () => {
    return activeData && cancelledData && diagnosedData
  }

  const handleChange = (event: ChangeEvent<{}>, newTabValue: number) => {
    setTabSelectionValue(newTabValue)
  }

  function a11yProps(index: any) {
    return {
      id: `floc-alert-tab-${index}`,
      'aria-controls': `floc-alert-tabpanel-${index}`,
    }
  }

  return (
    <Root>
      <Typography variant="h3" sx={{ fontSize: '1.17rem', fontWeight: 500 }}>
        <TriangleIcon style={{ marginRight: '.5rem', height: '1rem' }} />
        Health Alerts
      </Typography>
      {!isLoaded() && <WaitingProgressComp />}
      {(activeError || cancelledError || diagnosedError) && (
        <AlertNotification
          type="error"
          message="Sorry, an error occurred in fetching existing alerts for this floc, please try again later."
        />
      )}
      {isLoaded() && (
        <AlertBoxContainer boxShadow={0}>
          <AntTabs
            value={tabSelectionValue}
            onChange={handleChange}
            aria-label="Nav Tabs"
          >
            <TabStyled
              label={`ACTIVE (${activeData?.length})`}
              {...a11yProps(0)}
            />
            <TabStyled
              label={`ACTIONED (${diagnosedData!.length})`}
              {...a11yProps(1)}
            />
          </AntTabs>
          <ActiveListContentTab
            alerts={activeData}
            value={tabSelectionValue}
            index={0}
          />
          <ActionListTableTab
            alerts={diagnosedData}
            value={tabSelectionValue}
            index={1}
          />
        </AlertBoxContainer>
      )}
    </Root>
  )
}
export default AnomalyListAlertCard

import { useQuery } from 'react-query'
import axios from 'axios'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { apm } from '@elastic/apm-rum'

import {
  ParseStrategyDataRules,
  ISSOWStrategyTypes,
} from '../../../../../shared/types/strategyRule'
import { acquireTokenInfoSilent } from '../../../../../shared/auth'

interface plantCodeMap {
  [key: string]: {
    [key: string]: string
  }
}

const plantCodeMap: plantCodeMap = {
  local: {
    AU01: 'AA53',
    AU21: 'AD50',
  },
  dev: {
    AU01: 'AA53',
    AU21: 'AD50',
  },
  test: {
    AU01: 'AA53',
    AU21: 'AD50',
  },
  prod: {
    AU01: 'AU01',
    AU21: 'AU21',
  },
}

const fetchTemplateData = async (
  msalInstance: IPublicClientApplication,
  templateId: string,
  plantId: 'AU01' | 'AU21'
): Promise<any> => {
  const templatedType = ParseStrategyDataRules(templateId)
  if (templatedType !== ISSOWStrategyTypes.Templated) {
    return null
  }
  const transaction = apm.startTransaction('usePermitData', 'Mulesoft')!
  const httpSpan = transaction.startSpan(
    `GET ${import.meta.env.VITE_PERMIT_MULE_URL!}`,
    'app'
  )!
  const plantCodeMapValues =
    plantCodeMap[import.meta.env.VITE_APM_APP_ENV || 'prod']
  transaction.addLabels({
    templateId,
    plantId,
  })
  return new Promise((resolve, reject) => {
    acquireTokenInfoSilent(
      msalInstance,
      import.meta.env.VITE_PERMIT_MULE_ID! + '/.default'
    ).then((tokenResponse) => {
      axios
        .get(
          import.meta.env.VITE_PERMIT_MULE_URL +
            `${templateId}/permits?plantcode=${plantCodeMapValues[plantId]}`,
          {
            headers: {
              client_id: import.meta.env.VITE_PERMIT_MULE_TRACKAPPID,
              client_secret: '',
              Authorization: `Bearer ${tokenResponse?.accessToken}`,
            },
          }
        )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.message &&
            response.data.statusCode
          ) {
            reject(
              `Mulesoft Error in Get ISSOW Templates: ${response.data.message}`
            )
          } else {
            resolve(response.data)
          }
        })
        .catch((e) => {
          const errorMsg = `Error from Mule: ${
            e.message ? (e.message as string) : e
          }`
          apm.captureError(
            new Error(
              `Get MaintenancePlan fetch failed with status ${errorMsg}`
            )
          )
          reject([errorMsg])
        })
        .finally(() => {
          httpSpan.end()
          transaction.end()
        })
    })
  })
}

export default function usePermitData(
  templateId: string,
  plantId: 'AU01' | 'AU21'
) {
  const { instance: msalInstance } = useMsal()
  return useQuery(
    ['permit', templateId, plantId],
    () => fetchTemplateData(msalInstance, templateId, plantId),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 10 * 1000 * 60, // 10 min stale time, unless alert raised during this 10 minutes or reload page, it will use this as fast chache
    }
  )
}

import React, { createContext, ReactNode, useContext, useState } from 'react'
import AppSearchDialogue from '../../features/navigation/search/component'

type AppSearchContextType = {
  openFlocSearch: (adHoc?: boolean) => void
}
const AppSearchContext = createContext({
  openFlocSearch: (adHoc?: boolean) => {},
} as AppSearchContextType)

export function useAppSearchContext(customContext?: any) {
  return useContext(AppSearchContext)
}

type PropsType = {
  children: ReactNode
}

export default function AppSearchProvider({ children }: PropsType) {
  const [open, setIsOpen] = useState<{ open: boolean; adHoc: boolean }>({
    open: false,
    adHoc: false,
  })

  function openFlocSearch(adHoc?: boolean) {
    setIsOpen({ open: true, adHoc: adHoc ? adHoc : false })
  }

  const contextValue = { openFlocSearch }
  return (
    <AppSearchContext.Provider value={contextValue}>
      {children}
      <AppSearchDialogue
        handleClose={(event) => {
          event.stopPropagation()
          setIsOpen({ open: false, adHoc: false })
        }}
        adHoc={open.adHoc}
        open={open.open}
      />
    </AppSearchContext.Provider>
  )
}

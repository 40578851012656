import React from 'react'
import styled from '@emotion/styled'
import { Box, CircularProgress, Tooltip } from '@mui/material'

import { EquipmentHierarchyObject } from '../../../../shared/types/equipmentHierachy'
import { useParamQuery } from '../../../../shared/utility'
import ExternalOpen from '../../../../icons/open-external.svg?react'
import { useFlocContext } from '../../../../shared/providers/floc-context-provider'

interface FlocParentPanelProps {
  children?: React.ReactNode
  value: number // current selection, should rename this maybe?
  index: number
  descmatching: string
}

const WaitingProgressComp = styled(CircularProgress)`
  margin: 1rem;
`

const walkTree = (node: EquipmentHierarchyObject, root: string[]) => {
  // 'nodes to shout out about'
  root.push(node.floc)
  for (let i = 0; i < node.children.length; i++) {
    walkTree(node.children[i], root)
  }
  return root // note that this is not a tail recussion, thus stack memory could be bad if too large
}

const MaintenanceHeading = styled(Box)`
  margin: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: baseline;
  h2 {
    font-size: 1.2rem;
  }
  div {
    border-radius: 50%;
    font-size: 12px;
    background: #001543;
    color: white;
    text-align: center;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
`

const FlocList = styled(Box)`
  display: flex;
  padding-top: 0;
  flex-direction: column;
  span {
    padding: 0.7rem 2.5rem 0.7rem 2.5rem;
    display: flex;
    justify-content: space-between;
    svg {
      width: 1rem;
      height: 1rem;
      opacity: 60%;
    }
    color: black;
  }
  span:hover {
    background-color: #bce5f8;
    cursor: pointer;
  }
`

const CurrentlyOpenSpan = styled.span`
  opacity: 50%;
  align-items: baseline;
  &:hover {
    cursor: default !important;
  }
`

const FlocParentPanel = (props: FlocParentPanelProps) => {
  const { children, value, index, ...other } = props
  const mySearchQuery = useParamQuery()
  const flocContext = useFlocContext()
  const [flocs, setFLocs] = React.useState([] as string[])

  React.useEffect(() => {
    flocContext
      .getSurroundingEquipments(mySearchQuery.get('floc')!, props.descmatching)
      .then((r) => setFLocs(r))
  }, [flocContext])

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ overflow: 'auto' }}
    >
      <MaintenanceHeading>
        <h2>Surrounding Equipment</h2>
        {flocs && flocs.length > 0 && <div>{flocs.length}</div>}
      </MaintenanceHeading>
      <FlocList>
        {flocs ? (
          flocs.map((floc) => {
            if (floc !== mySearchQuery.get('floc')!) {
              return (
                <Tooltip key={floc} title="Open in new tab" disableInteractive>
                  <a
                    href={`/floc/detail?floc=${floc}`}
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                    rel="noreferrer"
                  >
                    <span key={floc}>
                      {floc} <ExternalOpen />
                    </span>
                  </a>
                </Tooltip>
              )
            } else {
              // same floc
              return (
                <CurrentlyOpenSpan key={floc}>
                  {floc} <small>Currently Open</small>
                </CurrentlyOpenSpan>
              )
            }
          })
        ) : (
          <WaitingProgressComp />
        )}
      </FlocList>
    </div>
  )
}

export default FlocParentPanel

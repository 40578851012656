import {
  AuthenticationResult,
  IPublicClientApplication,
} from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import { acquireTokenInfoSilent } from '../../shared/auth'

export const fetchToken = async (
  msalInstance: IPublicClientApplication,
  resource: string
): Promise<AuthenticationResult> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const r = await acquireTokenInfoSilent(msalInstance, resource)
    if (!r) {
      reject(r)
    } else {
      resolve(r!)
    }
  })
}

export default function useResourcingToken(scope: string) {
  const { instance: msalInstance } = useMsal()
  return useQuery(['scope', scope], () => fetchToken(msalInstance, scope), {
    refetchOnWindowFocus: true,
    retry: 2,
    staleTime: 30 * 1000 * 60,
    refetchInterval: 30 * 1000 * 60,
    refetchIntervalInBackground: true,
  })
}

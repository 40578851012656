import styled from '@emotion/styled'
import { Box, CircularProgress } from '@mui/material'
import React from 'react'

import ExternalOpen from '../../../../../icons/open-external.svg?react'
import useEngineeringData from '../../../../../services/hooks/useEngineeringData'
import { useParamQuery } from '../../../../../shared/utility'
import SealPressureGraph from './seal-pressure-graph'

const Root = styled(Box)`
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  width: 100%;
  flex-direction: column;
`

const FlexContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 0 1rem 0;
  height: fit-content;
  h3 {
    margin-bottom: 1rem;
  }
`

const HorizontalFlexContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const TextPair = styled(Box)`
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  h4 {
    opacity: 0.6;
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 0;
  }
  #link {
    font-size: 0.8rem;
    display: flex;
    svg {
      width: 0.8rem;
      margin-right: 0.3rem;
    }
  }
`

const SealOverViewTab = () => {
  const mySearchQuery = useParamQuery()
  const { data, isLoading } = useEngineeringData(mySearchQuery.get('floc'))

  return (
    <Root>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <FlexContentContainer>
            <h3>Overview</h3>
            <HorizontalFlexContainer>
              <TextPair>
                <h4>Seal Type:</h4>
                <span>{data?.pump_seal_group}</span>
              </TextPair>
              {data?.drawing_seal && (
                <TextPair>
                  <h4>Seal GA Drawing:</h4>{' '}
                  <a
                    id="link"
                    href={data?.drawing_seal}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalOpen /> View GA Drawing (DRIMS)
                  </a>
                </TextPair>
              )}
              {data?.drawing_seal_p_id && (
                <TextPair>
                  <h4>SEAL P{'&'}ID:</h4>
                  <a
                    id="link"
                    href={data?.drawing_seal_p_id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalOpen /> View SEAL P{'&'}ID (DRIMS)
                  </a>
                </TextPair>
              )}
              {data?.pump_barrier_fluid && (
                <TextPair>
                  <h4>Barrier Fluid Type:</h4>
                  <span>{data?.pump_barrier_fluid}</span>
                </TextPair>
              )}
            </HorizontalFlexContainer>
          </FlexContentContainer>
          <SealPressureGraph />
        </>
      )}
    </Root>
  )
}

export default SealOverViewTab

import styled from '@emotion/styled'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { apm } from '@elastic/apm-rum'
import { Button, Tooltip, Box } from '@mui/material'

import SeverityBar from '../../../../shared/components/severity-bar'
import AlertTypeMappingRecord from '../../../../shared/components/alert-metadata'
import { AlertDetail } from '../../../../shared/types/alert'
import CancelWithFeedback from '../../../cancel-feedback/components/component'
import QuestionMarkIcon from '../../../../icons/question-mark.svg?react'

interface FlocCardContentProps {
  alertDetail: AlertDetail
  renderedInDiagnosis?: Boolean
}

const AlertRow = styled(Box)`
  width: 100%;
  height: fit-content;
  display: flex;
  padding: 0 0.5rem 0 0rem;
  gap: 0.5rem;
  h5 {
    opacity: 60%;
    margin-bottom: 0.4rem;
  }
`
const TextPair = styled(Box)`
  flex: 1 1 25%;
  display: flex;
  flex-direction: column;
  span {
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  h5 {
    display: flex;
  }
`

const ReviewButton = styled(Button)`
  align-items: center;
`

const CenteredBox = styled(Box)`
  display: flex;
  align-items: center;
`

const FlocCardContent = (props: FlocCardContentProps) => {
  const theAlert = props.alertDetail
  const history = useHistory()
  return (
    <AlertRow>
      <TextPair>
        <h5>Alert Type</h5>
        <span>
          {AlertTypeMappingRecord[theAlert.alertType].getIcon()} &nbsp;
          {AlertTypeMappingRecord[theAlert.alertType].displayText}
        </span>
      </TextPair>
      <TextPair>
        <h5>
          {AlertTypeMappingRecord[theAlert.alertType].getMeasurementTypeText()}
          {AlertTypeMappingRecord[theAlert.alertType].alertDescriptionText && (
            <Tooltip
              title={
                AlertTypeMappingRecord[theAlert.alertType].alertDescriptionText
              }
              disableInteractive
            >
              <>
                <QuestionMarkIcon
                  style={{
                    marginLeft: '0.5rem',
                    color: '#0064CE',
                    width: '18px',
                  }}
                />
              </>
            </Tooltip>
          )}
        </h5>
        <span style={{ width: '11.4rem' }}>
          <SeverityBar
            severity={theAlert.severity}
            textOnly={
              AlertTypeMappingRecord[theAlert.alertType].defaultAlarmValue
            }
            severityThresholds={theAlert.alertBandingUpperLimits}
          />
        </span>
      </TextPair>
      <TextPair>
        <h5>Date Raised</h5>
        <span>
          {theAlert.dateRaised}, {theAlert.timeRaised}
        </span>
      </TextPair>
      <CenteredBox>
        <CancelWithFeedback cancelKey={theAlert.key} />
        <ReviewButton
          hidden={Boolean(props.renderedInDiagnosis)}
          variant="contained"
          color="primary"
          onClick={() => {
            apm.addLabels({
              componentName: 'Review',
              componentType: 'Button',
              interactionType: 'click',
              floc: props.alertDetail.floc,
            })
            history.push(`/floc/detail?floc=${props.alertDetail.floc}`)
          }}
          data-testid={'review_btn' + props.alertDetail.floc}
        >
          Review
        </ReviewButton>
      </CenteredBox>
    </AlertRow>
  )
}

export default FlocCardContent

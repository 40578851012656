import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import { useQuery } from 'react-query'

import { acquireTokenInfoSilent } from '../../shared/auth'
import { EngineeringData } from '../../shared/types/engineeringData'
import { getEngineeringDataQuery } from '../graphql/StrategyQueries'

const fetchEngineeringData = async (
  msalInstance: IPublicClientApplication,
  floc: string
): Promise<EngineeringData> => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        (await acquireTokenInfoSilent(
          msalInstance,
          import.meta.env.VITE_STRATEGY_APPID! + '/.default'
        ))!.accessToken
      }`,
      'Content-Type': 'application/json',
    },
  }
  return new Promise((resolve, reject) =>
    axios
      .post(
        import.meta.env.VITE_STRATEGY_ENDPOINT!,
        JSON.stringify({
          query: getEngineeringDataQuery(floc),
        }),
        config
      )
      .then(function (response: any) {
        if (response.data.data.engineeringData.length > 0) {
          resolve(response.data.data.engineeringData[0] as EngineeringData)
        } else {
          reject('No data is found')
        }
      })
      .catch(function (error: any) {
        console.error(error)
        reject(error)
      })
  )
}

export default function useEngineeringData(floc: string | null) {
  const { instance: msalInstance } = useMsal()
  return useQuery(
    ['Engineering Data', floc],
    () => fetchEngineeringData(msalInstance, floc!),
    {
      enabled: Boolean(floc),
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 10 * 1000 * 60,
    }
  )
}

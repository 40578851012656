export class TimeRange {
  displayLabel: string
  aggregate: number
  aggregateUnit: 'DAY' | 'HOUR' | 'MIN'
  timeRangeID: number
  startTimestamp: number
  endTimestamp: number
}

export const GetTimeRangeCandidates = (): TimeRange[] => {
  const today = new Date()
  return [
    {
      timeRangeID: 1,
      displayLabel: '24 Hour',
      aggregate: 30,
      aggregateUnit: 'MIN',
      endTimestamp: Math.floor(today.getTime() / 1000),
      startTimestamp: Math.floor(
        new Date(today).setDate(today.getDate() - 1) / 1000
      ),
    },
    {
      timeRangeID: 2,
      displayLabel: '1 Week',
      aggregate: 120,
      aggregateUnit: 'MIN',
      endTimestamp: Math.floor(today.getTime() / 1000),
      startTimestamp: Math.floor(
        new Date(today).setDate(today.getDate() - 7) / 1000
      ),
    },
    {
      timeRangeID: 3,
      displayLabel: '1 Month',
      aggregate: 120,
      aggregateUnit: 'MIN',
      endTimestamp: Math.floor(today.getTime() / 1000),
      startTimestamp: Math.floor(
        new Date(today).setMonth(today.getMonth() - 1) / 1000
      ),
    },
    {
      timeRangeID: 4,
      displayLabel: '3 Month',
      aggregate: 1,
      aggregateUnit: 'DAY',
      endTimestamp: Math.floor(today.getTime() / 1000),
      startTimestamp: Math.floor(
        new Date(today).setMonth(today.getMonth() - 3) / 1000
      ),
    },
    {
      timeRangeID: 5,
      displayLabel: '6 Month',
      aggregate: 1,
      aggregateUnit: 'DAY',
      endTimestamp: Math.floor(today.getTime() / 1000),
      startTimestamp: Math.floor(
        new Date(today).setMonth(today.getMonth() - 6) / 1000
      ),
    },
    {
      timeRangeID: 6,
      displayLabel: '12 Month',
      aggregate: 1,
      aggregateUnit: 'DAY',
      endTimestamp: Math.floor(today.getTime() / 1000),
      startTimestamp: Math.floor(
        new Date(today).setMonth(today.getMonth() - 12) / 1000
      ),
    },
    {
      timeRangeID: 7,
      displayLabel: '24 Month',
      aggregate: 1,
      aggregateUnit: 'DAY',
      endTimestamp: Math.floor(today.getTime() / 1000),
      startTimestamp: Math.floor(
        new Date(today).setMonth(today.getMonth() - 24) / 1000
      ),
    },
  ]
}

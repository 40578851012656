import styled from '@emotion/styled'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Theme,
  Button,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import sucessImg from '../../../images/cancel-success.png'
import useResourcingToken from '../../../services/hooks/useTokenService'
import AlertNotification from '../../../shared/components/alert-notification'
import {
  checkUserCanActionOnAnomaly,
  getWopIdOREmail,
} from '../../../shared/utility'
import useSentCancelFeedback from '../hooks/useCancelFeedback'
import CancelDialogue, { DialogueState, FeedbackOptions } from './dialogue'

export interface CancelWithFeedbackProps {
  cancelKey: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      marginLeft: 'auto',
      marginBottom: 0,
      textTransform: 'none',
    },
  })
)

const WaitingProgressComp = styled(CircularProgress)`
  margin-left: 1rem;
  margin-bottom: 0.1rem;
`

const StyledContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    color: #01c851;
    padding: 4px;
  }
  p {
    text-align: center;
    color: transparent(0.6);
    font-size: 0.9rem;
  }
`
const CancelWithFeedback = ({ cancelKey }: CancelWithFeedbackProps) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { data: token } = useResourcingToken(
    import.meta.env.VITE_AAD_CLIENTID! + '/.default'
  )
  const [openCancelDialogue, setOpenCancelDialogue] = React.useState(false)
  const [openSuccessDialogue, setOpenSuccessDialogue] = React.useState(false)
  const [cancelBody, setCancelBody] = React.useState({})
  const [sentRequest, setSentRequest] = React.useState(false)
  const classes = useStyles()
  const { data, error, isLoading } = useSentCancelFeedback(
    cancelKey,
    cancelBody,
    sentRequest
  )

  useEffect(() => {
    if (data && data.status === 200) {
      setOpenSuccessDialogue(true)
    }
  }, [data])

  const handleClickOpen = () => {
    setOpenCancelDialogue(true)
  }

  const handleClose = () => {
    setOpenCancelDialogue(false)
  }

  const handleSubmit = (formValues: DialogueState) => {
    const cancelBody = {
      cancelledBy: getWopIdOREmail(token),
      cancelReason: FeedbackOptions[formValues.feedbackReasonIndex],
      otherReasons:
        FeedbackOptions[formValues.feedbackReasonIndex] === 'Other'
          ? formValues.otherReasons
          : 'N/A',
      comments: formValues.comments,
      surrondingEquipmentFeedback: formValues.checkedItems,
    }
    setCancelBody(cancelBody)
    setSentRequest(true)
    handleClose()
  }

  return (
    <>
      <Button
        color="primary"
        className={classes.button}
        size="small"
        onClick={handleClickOpen}
        disabled={isLoading || !checkUserCanActionOnAnomaly(token)}
      >
        {isLoading ? (
          <>
            Removing... <WaitingProgressComp size="1rem" />
          </>
        ) : (
          'Remove'
        )}
      </Button>
      {openCancelDialogue && (
        <CancelDialogue
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          open={true}
        />
      )}
      {error && (
        <AlertNotification
          type="error"
          message={`Sorry, an error occurred during submission. Error Message: ${error}`}
        />
      )}
      <Dialog
        maxWidth="xs"
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return
          }
          setOpenSuccessDialogue(false)
        }}
        aria-labelledby="dialog-diagnosis-action"
        aria-describedby="dialog-diagnosis-done"
        open={openSuccessDialogue}
      >
        <StyledContent>
          <img id="dialogue-main-image" src={sucessImg} alt="SuccessImag" />
          <h1>Alert Removed!</h1>
          <DialogContentText id="dialog-diagnosis-content">
            The selected health alert for this machine has been removed. Alerts
            will be re-triggered if the equipment condition degrades any
            further.
          </DialogContentText>
        </StyledContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSuccessDialogue(false)
              history.push('/')
              // go back home
              queryClient.invalidateQueries('Active')
              queryClient.invalidateQueries('Snoozed')
            }}
            color="primary"
          >
            Return to all alerts
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CancelWithFeedback

export class TimeSeries {
  name: string
  value: number
  time: number

  constructor(jsonData: any) {
    this.name = jsonData.name
    this.value = parseFloat(jsonData.value)
    this.time = new Date(jsonData.time).getTime()
  }
}

import styled from '@emotion/styled'
import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { faTemperatureHigh } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ESDGraph from '../esd-ts-graph'
import { AlertTypes } from '../../../../../shared/types/alert'

const Root = styled(Box)`
  padding: 1em;
`

const ErrorDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #666666;
  #red {
    color: #f44336;
    b {
      font-weight: 500;
    }
  }
  a {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  #inline {
    a {
      margin: 0;
      display: inline;
    }
  }
`

const Heading = styled(Box)`
  display: flex;
  place-content: space-between;
  #status {
    display: flex;
    flex-direction: row;
    width: 15%;
    min-width: 10rem;
    gap: 0.5rem;
    align-items: flex-start;
    #severity {
      width: 80%;
    }
  }
`

const WaitingProgressComp = styled(CircularProgress)`
  grid-column: 4/12;
`

const Container = styled(Box)`
  background-color: white;
  padding: 0 1em 1em 0em;
`

const ESDTemperatureGraph = () => {
  const isLoaded = () => {
    return true
  }
  return (
    <Root>
      <Heading>
        <h2>
          <FontAwesomeIcon
            icon={faTemperatureHigh}
            style={{ paddingRight: '0.5rem' }}
          />
          Temperature Monitoring
        </h2>
      </Heading>
      {!isLoaded() && <WaitingProgressComp />}
      {isLoaded() && false && (
        <ErrorDiv data-testid="error-no-access">
          <div id="red">
            <b>Error:</b> It looks like we’re currently unable to return any
            monitoring information for this equipment. Please contact IT support
            or try again later.
          </div>
          <div id="inline">
            You can still continue with the maintenance response through CBM.
          </div>
        </ErrorDiv>
      )}
      {isLoaded() && !false && (
        <Container boxShadow={0}>
          <ESDGraph alertType={AlertTypes.ESDTempModel} />
        </Container>
      )}
    </Root>
  )
}
export default ESDTemperatureGraph

import React, { useState } from 'react'
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Theme,
  Typography,
  Checkbox,
  Box,
} from '@mui/material'
import { withStyles, createStyles, WithStyles } from '@mui/styles'
import MuiDialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import styled from '@emotion/styled'

import AmazedFill from '../../../icons/feedback-faces/amazed-fill.svg?react'
import Amazed from '../../../icons/feedback-faces/amazed.svg?react'
import Sad from '../../../icons/feedback-faces/sad.svg?react'
import SadFill from '../../../icons/feedback-faces/sad-fill.svg?react'
import OKIcon from '../../../icons/feedback-faces/ok.svg?react'
import OKFillIcon from '../../../icons/feedback-faces/ok-fill.svg?react'
import Happy from '../../../icons/feedback-faces/happy.svg?react'
import HappyFill from '../../../icons/feedback-faces/happy-fill.svg?react'
import Angry from '../../../icons/feedback-faces/angry.svg?react'
import AngryFill from '../../../icons/feedback-faces/angry-fill.svg?react'
import { useUserTokenContext } from '../../../shared/providers/user-token-provider'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      height: 0,
      width: 500,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    body1: {
      fontSize: '25px',
    },
    questionText: {
      fontSize: '20px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface GlobalFeedbackBody {
  feedbackRate: number // number between 0 to 4
  message?: string
  furtherContactAllowed: boolean
  feedbackBy: string
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: (e: any) => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography
        align="left"
        display="inline"
        variant="body1"
        className={classes.body1}
      >
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    fontSize: '16px',
    fontWeight: 400,
    marginLeft: '5px',
    marginTop: '15px',
  },
}))(MuiDialogContent)

const QuestionP = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    fontSize: '1rem',
    fontWeight: 500,
    marginLeft: '5px',
    marginTop: '15px',
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const DialogStyled = withStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}))(Dialog)

const TextFieldStyled = withStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}))(TextField)

export interface AppFeedbackDialogueProps {
  handleClose: (e: any) => void
  onSubmitCallback: (body: GlobalFeedbackBody) => void
  open: boolean
}

const AppFeedbackDialogue = (props: AppFeedbackDialogueProps) => {
  const [feedbackLevel, setFeedbackLevel] = useState(-1)
  const [comment, setComment] = useState('')
  const [checkBoxState, setCheckBoxState] = useState(true)
  const handleCommentInput = (event: React.ChangeEvent<{ value: unknown }>) => {
    setComment(event.target.value as string)
  }
  const handleCheckBoxChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCheckBoxState(!checkBoxState)
  }
  const tokenContext = useUserTokenContext()

  enum COLORS {
    'D32F2F',
    'FF5722',
    'FF9800',
    '8BC34A',
    '03A9F4',
  }

  interface IconWrapperProps {
    index: number
    feedbackLevel: number
  }

  const IconWrapper = styled.span(
    ({ index, feedbackLevel }: IconWrapperProps) => ({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '0.5rem',
      '&:hover': {
        'svg,span': {
          color: '#' + COLORS[index],
        },
      },
      'svg,span': {
        color: feedbackLevel === index ? '#' + COLORS[index] : '#666666',
      },
    })
  )
  function getFeedbackIcon(index: number, feedbackLevel: number) {
    switch (index) {
      case 0:
        return (
          <IconWrapper index={index} feedbackLevel={feedbackLevel}>
            {feedbackLevel === 0 ? <AngryFill /> : <Angry />}
            <span>Terrible</span>
          </IconWrapper>
        )
      case 1:
        return (
          <IconWrapper index={index} feedbackLevel={feedbackLevel}>
            {feedbackLevel === 1 ? <SadFill /> : <Sad />}
            <span>Bad</span>
          </IconWrapper>
        )
      case 2:
        return (
          <IconWrapper index={index} feedbackLevel={feedbackLevel}>
            {feedbackLevel === 2 ? <OKFillIcon /> : <OKIcon />}
            <span>OK</span>
          </IconWrapper>
        )
      case 3:
        return (
          <IconWrapper index={index} feedbackLevel={feedbackLevel}>
            {feedbackLevel === 3 ? <HappyFill /> : <Happy />}
            <span>Good</span>
          </IconWrapper>
        )
      case 4:
        return (
          <IconWrapper index={index} feedbackLevel={feedbackLevel}>
            {feedbackLevel === 4 ? <AmazedFill /> : <Amazed />}
            <span>Amazing</span>
          </IconWrapper>
        )
      default:
        return 'step'
    }
  }

  const FaceDiv = styled(Box)`
    padding: 1.5rem 2rem 0 2rem;
    display: flex;
    justify-content: space-between;
    svg {
      height: 4rem;
    }
  `

  return (
    <DialogStyled
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle onClose={props.handleClose} id="form-dialog-title">
        Your Feedback
      </DialogTitle>
      <DialogContent>
        Do you have any suggestions, ideas, or problems you want us to look
        into? We would love to hear your feedback so that we can continue to
        make this tool even better!
      </DialogContent>
      <QuestionP>
        How has your experience been so far?
        <FaceDiv>
          {[0, 1, 2, 3, 4].map((number) => {
            return (
              <span
                key={number}
                onClick={() => {
                  setFeedbackLevel(number)
                }}
              >
                {getFeedbackIcon(number, feedbackLevel)}
              </span>
            )
          })}
        </FaceDiv>
      </QuestionP>
      <QuestionP>
        Would you like to leave us a message?{' '}
        <i style={{ color: '#b3b3b3' }}>(Optional)</i>
      </QuestionP>
      <TextFieldStyled
        autoFocus
        size="small"
        multiline={true}
        id="name"
        label=""
        type="text"
        minRows="4"
        fullWidth
        onChange={handleCommentInput}
        inputProps={{ maxLength: 1500 }}
      />
      <DialogContent>
        <Checkbox
          checked={checkBoxState}
          onChange={handleCheckBoxChange}
          inputProps={{ 'aria-label': 'primary checkbox happy feedback' }}
        />
        I am happy to be contacted further to discuss my feedback
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            event.stopPropagation()
            props.onSubmitCallback({
              feedbackRate: feedbackLevel,
              furtherContactAllowed: checkBoxState,
              message: comment,
              feedbackBy: tokenContext!.account!.username, // or getWopIdOREmail(tokenContext);
            })
          }}
          color="primary"
          disabled={feedbackLevel === -1}
        >
          Send Feedback
        </Button>
      </DialogActions>
    </DialogStyled>
  )
}

export default AppFeedbackDialogue

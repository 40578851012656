import styled from '@emotion/styled'
import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import React, { useEffect, useState, memo, ChangeEvent } from 'react'

import HelpTooltip from '../../../../shared/components/tooltip-help'

const DateSelectionDiv = styled(Box)`
  display: flex;
  gap: 1rem;
  .MuiRadio-root {
    color: #4d4d4f;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #4d4d4f;
  }
`

const TextFieldStyled = withStyles((theme: Theme) => ({
  root: {
    width: '5rem',
    marginRight: '1rem',
  },
}))(TextField)

const RadioDiv = styled(Box)`
  display: flex;
  align-items: baseline;
  .MuiRadio-root {
    top: 0.5rem;
  }
`

const timeUnits = [
  {
    value: 'days',
    label: 'Days',
  },
  {
    value: 'weeks',
    label: 'Weeks',
  },
  {
    value: 'months',
    label: 'Months',
  },
]

const RadioStyled = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
}))(FormControlLabel)

const SubTitle = styled(Box)`
  display: flex;
  flex: 1 0 100%;
`

interface DurationPickerProps {
  stateChangeCallback: (newState: {
    breakDown: boolean
    timeUnit: 'months' | 'days' | 'weeks'
    timeValue: number
    estimatedRiskEndDate: string
  }) => void
}

const DurationPicker = memo(({ stateChangeCallback }: DurationPickerProps) => {
  const [state, setState] = useState<{
    breakDown: boolean
    timeUnit: 'months' | 'days' | 'weeks'
    timeValue: number
    estimatedRiskEndDate: string
  }>({
    breakDown: false,
    timeUnit: 'months',
    timeValue: 0,
    estimatedRiskEndDate: '',
  })

  const DatePickerControl = () => {
    useEffect(() => {
      stateChangeCallback(state)
    }, [state])

    const handleTimeUnitChange = (event: ChangeEvent<HTMLInputElement>) => {
      state.timeUnit = event.target.value as any
      handleTimeChange()
    }

    const handleTimeChange = (event?: any) => {
      const currentDayValue = event
        ? parseInt(event.target.value)
        : state.timeValue
      if (event) {
        // store the new day change
        state.timeValue = currentDayValue
      }
      // block > 1000, < 0, and nullable values (exclude 0)
      if (
        (!currentDayValue || currentDayValue > 1000 || currentDayValue < 0) &&
        currentDayValue !== 0
      ) {
        //clear values;
        setState({ ...state, estimatedRiskEndDate: '' })
        return
      }

      let days = 0
      if (state.timeUnit === 'days') {
        days = currentDayValue
      } else if (state.timeUnit === 'weeks') {
        days = currentDayValue * 7
      } else if (state.timeUnit === 'months') {
        days = currentDayValue * 30
      }
      const newRiskEndDate = new Date(
        new Date().getTime() + 1000 * 60 * 60 * 24 * days
      ).toISOString()

      setState({ ...state, estimatedRiskEndDate: newRiskEndDate })
    }

    return (
      <RadioDiv>
        <Radio checked={!state.breakDown} color="secondary" />
        <Box
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            marginRight: '1rem',
            fontSize: '1rem',
          }}
        >
          Up to
        </Box>
        <TextFieldStyled
          inputProps={{ 'data-testid': 'timeInput' }}
          required
          id="standard-required-timeChange"
          placeholder="e.g. 6"
          value={
            state.timeValue !== null && state.timeValue > 0
              ? state.timeValue
              : ''
          }
          size="medium"
          type="number"
          autoComplete="off"
          onChange={(e) => {
            handleTimeChange(e)
          }}
          variant="standard"
        />
        <TextFieldStyled
          data-testid="timeUnitInput"
          id="standard-select-unit"
          select
          label=""
          value={state.timeUnit}
          onChange={handleTimeUnitChange}
          size="medium"
          variant="standard"
        >
          {timeUnits.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextFieldStyled>
      </RadioDiv>
    )
  }

  return (
    <Box>
      <SubTitle>
        <Typography variant="subtitle1">
          Estimate the effective remaining useful life of this equipment based
          on its condition
        </Typography>
        <HelpTooltip title="This time estimate will be used to populate the ‘Risk End Date’ in the maintenance work order. You do not need to consider maintenance planning and logistics at this stage." />
      </SubTitle>
      <DateSelectionDiv>
        <FormControl component="fieldset" variant="standard">
          <RadioGroup
            aria-label="detectionRiskGroup"
            name="detectionRisk"
            value={state.breakDown ? 0 : 1}
            color="default"
            onChange={(e) => {
              if (e.target.value !== '0') {
                setState({ ...state, breakDown: false })
              } else {
                setState({ ...state, breakDown: true })
              }
            }}
          >
            <RadioStyled
              key={1}
              value={1}
              control={DatePickerControl()}
              label={''}
              color="default"
            />
            <RadioStyled
              data-testid="alreadyBrokenRadio"
              key={0}
              value={0}
              control={<Radio />}
              label={'Failure has already occurred (breakdown)'}
              color="default"
            />
          </RadioGroup>
        </FormControl>
      </DateSelectionDiv>
    </Box>
  )
})

export default DurationPicker

import React, { useContext, useState } from 'react'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { useHistory } from 'react-router-dom'

import { mapPlantToFlocPrefix } from '../../../shared/types/plants'
import { DataFilterContext } from '../../anomaly-list/component'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
)
export interface SearchTextBoxProps {
  flocChangeHandler: (floc: string) => void
  handleClose: (e: any) => void
  adHoc?: boolean
}

export default function SearchTextBox(props: SearchTextBoxProps) {
  const classes = useStyles()
  const [floc, setFloc] = useState('')
  const history = useHistory()
  const filterContext = useContext(DataFilterContext)

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="e.g. AU21.1E1441K1"
        inputProps={{ 'aria-label': 'search floc' }}
        value={floc}
        onChange={(e) => {
          // bug of click search icon will result in clearning of input
          setFloc(e.target.value.toLocaleUpperCase())
          props.flocChangeHandler(e.target.value)
        }}
      />

      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
        onClick={(e) => {
          history.push(
            `/floc/detail?floc=${!floc.includes('.') ? mapPlantToFlocPrefix[filterContext.filterSetting.plant] + '.' : ''}${floc}${props.adHoc ? '&adhoc=true' : ''}`
          )
          e.preventDefault()
          props.handleClose(e)
        }}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

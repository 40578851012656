import styled from '@emotion/styled'
import { Box, CircularProgress, Tooltip } from '@mui/material'
import moment from 'moment'
import React, { useState, useEffect } from 'react'

import OilIcon from '../../../../../icons/oil.svg?react'
import QuestionMarkIcon from '../../../../../icons/question-mark.svg?react'
import useEquipmentHierarchy from '../../../../../services/hooks/useEquipmentHierarchyData'
import { useParamQuery } from '../../../../../shared/utility'
import useALSData from './hooks/useALSData'
import { SeverityMapping } from './status'
import OilOverviewTab, { OilResultUIDataModel } from './tabs/oil-overview'

const Root = styled(Box)`
  padding: 1em;
`

const ErrorDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #666666;

  #red {
    color: #f44336;
    b {
      font-weight: 500;
    }
  }
  a {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  #inline {
    a {
      margin: 0;
      display: inline;
    }
  }
`

const Heading = styled(Box)`
  display: flex;
  place-content: space-between;
  opacity: ${(props) => (props['aria-disabled'] ? 0.5 : 1)};
  #status {
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    display: flex;
    span {
      background-color: ${(props: any) => props.color};
      padding: 0.1rem 1rem;
      font-weight: 500;
      border-radius: 4px;
    }
    #tooltip {
      color: #0064ce;
      position: relative;
      margin-right: 0.2rem;
      top: 0.2rem;
    }
  }
`

const WaitingProgressComp = styled(CircularProgress)`
  grid-column: 4/12;
`

const OilBoxContainer = styled(Box)`
  background-color: white;
  padding: 0 1em 1em 0em;
`

const ToolTipSpan = styled.span`
  font-size: 0.9rem;
  margin-left: 1rem;
  svg {
    margin-right: 0.2rem;
    top: 0.1rem;
    width: 1rem;
    position: relative;
  }
`

const OilInfoCard = (props: any) => {
  // get context for state, populate the context
  const mySearchQuery = useParamQuery()
  const [oilFlocs, setOilFlocs] = useState<string[]>([])
  const [tabSelectionValue, setTabSelectionValue] = useState(0)
  const [oilResultData, setOilResultData] = useState<OilResultUIDataModel[]>([])
  const [highestStatus, setHighestStatus] = useState('')
  const isLoaded = () => {
    return !flocHierachryLoading && !false && !isLoading
  }
  const hasError = () => {
    return oilDataError || flocHierarchyError
  }
  const hasData = () => {
    return isLoaded() && !hasError() && oilResultData.length > 0
  }

  const { data, error: oilDataError, isLoading } = useALSData(oilFlocs)
  const {
    data: flocHierarchyData,
    error: flocHierarchyError,
    isLoading: flocHierachryLoading,
  } = useEquipmentHierarchy(mySearchQuery.get('floc')!)

  useEffect(() => {
    // need find enginers under this.. what's the rule?
    // TEST RULES
    if (flocHierarchyData) {
      const matchRules = ['ENG', 'MOTOR']
      let childrenMatched: any[] = []
      if (flocHierarchyData.children && flocHierarchyData.children.length) {
        // test all childs
        childrenMatched = flocHierarchyData?.children.filter((c) => {
          if (c.attributes.service) {
            return matchRules.includes(c.attributes.service.split(',')[0])
          }

          return false
        })
      }
      const children = childrenMatched.map((c) => c.floc)

      children.push(mySearchQuery.get('floc')!)

      setOilFlocs(children)
    }
  }, [flocHierarchyData])

  useEffect(() => {
    if (data) {
      // latest is first
      const sorted = data.sort((a, b) =>
        moment(a.date_sampled).toDate() < moment(b.date_sampled).toDate()
          ? 1
          : -1
      )
      const compartments = new Set(sorted.map((r) => r.compartment_name))
      const result = [] as OilResultUIDataModel[]
      compartments.forEach((comp) => {
        const latest = sorted.filter((r) => {
          return r.compartment_name === comp
        })[0]
        result.push({
          compartmentName: latest.compartment_name!,
          compartmentType: latest.compartment_type ?? 'Not available',
          lastUpdated: moment(latest.last_update_date).toDate(),
          status: latest.severity ?? 'Not available',
          fluidName: latest.sample_fluid ?? 'Not available',
          manufacturer: latest.sample_fluid_manufacturer ?? 'Not available',
          grade: latest.sample_fluid_grade ?? 'Not available',
          compartmentCapacity: latest.compartment_capacity
            ? (latest.compartment_capacity +
                ' ' +
                latest.compartment_capacity_uom ?? '')
            : 'Not available',
          problemCode: latest.problem_code ?? 'Not available',
          diagnosis: latest.diagnosis ?? 'Not available',
          reportLink: latest.sample_report_url ?? 'Not available',
          fluidType: latest.compartment_category ?? 'Not available',
        })
      })
      setOilResultData(result)
      if (result.length) {
        setHighestStatus(
          result.sort((a, b) =>
            SeverityMapping[a.status] > SeverityMapping[b.status] ? 1 : -1
          )[0].status
        )
      }
    }
  }, [data])

  return (
    <Root>
      <Heading aria-disabled={!hasData() && isLoaded()}>
        <h3>
          <OilIcon style={{ marginRight: '.5rem', height: '1rem' }} />
          ALS Fluid Monitoring
        </h3>
        {!hasData() && isLoaded() && (
          <Tooltip
            title={
              'This card is disabled either because the relevant monitoring is not conducted OR the required data is not available/configured.'
            }
            disableInteractive
          >
            <ToolTipSpan>
              <QuestionMarkIcon style={{ width: '18px' }} />
              Why is this disabled?
            </ToolTipSpan>
          </Tooltip>
        )}
      </Heading>
      {!isLoaded() && <WaitingProgressComp />}
      {isLoaded() && (oilDataError || flocHierarchyError) && (
        <ErrorDiv data-testid="error-no-access">
          <div id="red">
            <b>Error:</b> It looks like we’re currently unable to return ALS
            sampling information for this equipment. Please contact IT support
            or try again later.
          </div>
          <div id="inline">
            You can still continue with the maintenance response through CBM.
          </div>
        </ErrorDiv>
      )}
      {hasData() && (
        <OilBoxContainer boxShadow={0}>
          <OilOverviewTab
            value={tabSelectionValue}
            index={0}
            data={oilResultData}
          />
        </OilBoxContainer>
      )}
    </Root>
  )
}
export default OilInfoCard

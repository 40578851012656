import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import useEquipmentWithMeasurements from '../../../../../../services/hooks/useEquipmentMeasurements'
import useTSData from '../../../../condition-tab/hooks/useTSData'
import { lineDataStateRecoil } from './run-hour-main'

export type RunHourLineProps = {
  floc: string
  timebase: 'DAY' | 'HOUR' | 'MIN' // default to day
  interval: number // default 1
  startTimestamp: number
}
const now = new Date()
const yesterday = Math.floor(new Date().setDate(now.getDate() - 1) / 1000)

const RunHourKey = 'MEASUREMENT_RUN_HOURS'
const SeedTimeKey = 'SEED_TIME'

function RunHourLine(props: RunHourLineProps) {
  const [modelTag, setModelTag] = React.useState<string>('')
  const [seedValue, setSeed] = React.useState(0)

  const { data: tsDataHistory, isLoading: TSDataLoadingHistory } = useTSData(
    modelTag,
    'DAY',
    365,
    599616000,
    props.startTimestamp,
    props.floc,
    'SUM'
  )
  const { data: tsDataGraph, isLoading: TSDataLoadingGraph } = useTSData(
    modelTag,
    props.timebase,
    props.interval,
    props.startTimestamp,
    Math.ceil(now.getTime() / 1000),
    props.floc,
    'SUM'
  )

  // get the tag first
  const {
    data: dataForTag,
    error,
    isLoading,
  } = useEquipmentWithMeasurements(props.floc, 'SUM', 'DAY', 1, yesterday, true)

  const [lineDataState, setLineDataState] = useRecoilState(lineDataStateRecoil)
  useEffect(() => {
    if (
      dataForTag &&
      dataForTag.measurements &&
      dataForTag.measurements.length
    ) {
      const runHourMeasuremnent = dataForTag.measurements.filter(
        (m) => m.key === RunHourKey
      )
      if (runHourMeasuremnent.length) {
        if (runHourMeasuremnent[0].attributes.length) {
          const seedVariable = runHourMeasuremnent[0].attributes.find(
            (r) => r.name === SeedTimeKey
          )
          if (seedVariable) {
            setSeed(parseFloat(seedVariable.value))
          }
        }
        const modelId = runHourMeasuremnent[0].id.split('::')[1]
        setModelTag(modelId.split('~')[0])
      }
    }
  }, [dataForTag])

  useEffect(() => {
    if (TSDataLoadingGraph || TSDataLoadingHistory) {
      return
    }
    let currentState = lineDataState.find((o) => o.floc === props.floc)!
    if (currentState.isLoading !== isLoading) {
      if (tsDataGraph && tsDataHistory && tsDataGraph.length) {
        // add the seedValue to first Graph data Point
        let sum = seedValue
        tsDataHistory.forEach((v) => (sum += v.value))

        currentState = {
          ...currentState,
          isLoading: false,
          data: {
            response: tsDataGraph.map((dataPoint, idx) =>
              idx === 0
                ? { ...dataPoint, value: dataPoint.value + sum }
                : dataPoint
            ),
            error: '',
          },
        }
      } else {
        if (error) {
          currentState = {
            ...currentState,
            isLoading: false,
            data: {
              response: null,
              error: error,
            },
          }
        } else {
          currentState = {
            ...currentState,
            isLoading: false,
            data: {
              response: null,
              error: 'no measurements found.',
            },
          }
        }
      }

      setLineDataState(
        lineDataState.map(
          (obj) => [currentState].find((o: any) => obj.floc === o.floc) || obj
        )
      )
    }
  }, [
    tsDataHistory,
    TSDataLoadingHistory,
    tsDataGraph,
    TSDataLoadingGraph,
    lineDataState,
  ])

  return <></>
}

export default RunHourLine

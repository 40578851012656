export enum ISSOWStrategyTypes {
  LRP = 'LRP',
  Empty = 'EmptyValue',
  Templated = 'TemplatedValue',
  OwnIsolations = 'OwnIsolation',
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FuzzyMatching = require('fuzzy-matching')
export function ParseStrategyDataRules(
  strategyData: string | undefined | null
): ISSOWStrategyTypes {
  if (strategyData) {
    const data = strategyData.trim().toUpperCase()
    //only compare upper case
    const fm = new FuzzyMatching([data])
    if (fm.get('<LRP>').distance > 0.9) {
      return ISSOWStrategyTypes.LRP
    } else if (fm.get('<OWNISOLATIONS>').distance > 0.9) {
      return ISSOWStrategyTypes.OwnIsolations
    } else if (data === '') {
      // to handle ' ' after trim
      return ISSOWStrategyTypes.Empty
    }
  } else {
    return ISSOWStrategyTypes.Empty
  }

  return ISSOWStrategyTypes.Templated
}

import { useQuery } from 'react-query'
import axios from 'axios'
import {
  AlertDetail,
  AlertListResponse,
  convertToAlerts,
} from '../../../shared/types/alert'
import { acquireTokenInfoSilent } from '../../../shared/auth'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'

const fetchActiveAlerts = async (
  msalInstance: IPublicClientApplication,
  floc?: string | null
): Promise<AlertDetail[]> => {
  try {
    const tokenResponse = await acquireTokenInfoSilent(
      msalInstance,
      import.meta.env.VITE_AAD_CLIENTID! + '/.default'
    )
    const accessToken = tokenResponse!.accessToken
    const url =
      import.meta.env.VITE_CBM_ENDPOINT +
      '/anomalies?' +
      'state=Active' +
      (floc ? `&floc=${floc}` : '')
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    let responseProcessed: AlertListResponse[] = response.data

    if (floc && responseProcessed) {
      responseProcessed = responseProcessed.filter(
        (row) => row.drivenFloc === floc
      )
    }
    return convertToAlerts(responseProcessed)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default function useActiveAlertList(filterFloc?: string | null) {
  const { instance: msalInstance } = useMsal()
  return useQuery(
    ['Active', filterFloc],
    () => fetchActiveAlerts(msalInstance, filterFloc),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 10 * 1000 * 60, // 2min stale time, unless alert raised during this 10 minutes or reload page, it will use this as fast chache
    }
  )
}

import React from 'react'
import axios from 'axios'
import {
  FormControl,
  Button,
  Dialog,
  IconButton,
  TextField,
  Theme,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material'
import { withStyles, createStyles, WithStyles } from '@mui/styles'
import MuiDialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import styled from '@emotion/styled'

import WarningIcon from '../../../../icons/exclamation-triangle.svg?react'
import { useMutation, useQueryClient } from 'react-query'
import AlertNotification from '../../../../shared/components/alert-notification'
import { useUserTokenContext } from '../../../../shared/providers/user-token-provider'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      height: 0,
      width: 500,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography
        align="left"
        display="inline"
        variant="body1"
        className={classes.body1}
      >
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    fontSize: '13px',
    fontWeight: 500,
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogContentNormal = withStyles((theme: Theme) => ({
  root: {
    fontSize: '13px',
    fontWeight: 400,
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const DialogStyled = withStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}))(Dialog)

const TextFieldStyled = withStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}))(TextField)
const Hint = styled(Box)`
  margin-left: 1rem;
  font-size: 1rem;
  color: #ff9800;
  margin-top: 2rem;
  margin-bottom: 2rem;
  svg {
    margin-right: 0.5rem;
  }
`

const WaitingProgressComp = styled(CircularProgress)`
  margin-right: 0.5rem;
`

// Starting of class -------------------
export interface ReportMissingResponseDialogueProps {
  open: boolean
  onClose: () => void
}

// MUST CHANGE BELOW TO CORRECTLY TRIGGER OTHER TEXTBOX

export interface ReportMissingResponseDialogueProps {}

const ReportMissingResponseDialogue = (
  props: ReportMissingResponseDialogueProps
) => {
  const queryClient = useQueryClient()
  const tokenContext = useUserTokenContext()

  const [submitting, setSubmitting] = React.useState(false)
  const [submitError, setSubmitError] = React.useState('')
  const [submitResponse, setSubmitResponse] = React.useState(null)
  const [formValue, setFormValue] = React.useState({
    description: '',
  })

  const handleCommentInput = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFormValue({
      ...formValue,
      description: event.currentTarget.value as string,
    })
  }
  const {
    mutate: mutatePostDiagnosis,
    status,
    reset,
  } = useMutation(
    (inputToAPICall: any) => {
      return axios(
        import.meta.env.VITE_CBM_ENDPOINT! + `something/${inputToAPICall.key}`,
        {
          method: 'POST',
          data: { ...inputToAPICall.body },
          headers: {
            Authorization: `Bearer ${tokenContext!.accessToken}`,
          },
        }
      )
    },
    {
      onSuccess: async (resp: any) => {
        // invalid anomaly Selection and refresh active

        await queryClient.invalidateQueries('Active') // this is async, hence other UI may still access the old data, hence use await instead
        queryClient.invalidateQueries('Diagnosed')
        setSubmitResponse(resp)
        setSubmitting(false)
        // clear diagnosis context? or maybe not?. Need to test for continues alerting response
      },
      onError: (error: Error) => {
        setSubmitting(false)
        setSubmitError(
          'Sorry, An Error Happend, Please try again later. Error Message: ' +
            error.message
        )
      },
      onSettled: () => {},
    }
  )
  const handleSubmit = () => {
    setSubmitting(true)
    mutatePostDiagnosis({ body: formValue, key: '????' })
  }

  return (
    <>
      {submitError && (
        <AlertNotification
          type="error"
          message={submitError}
          onHide={() => {
            setSubmitError('')
          }}
        />
      )}
      <DialogStyled
        open={props.open && !submitResponse}
        aria-labelledby="form-dialog-title"
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return
          }
          props.onClose()
          setFormValue({
            description: '',
          })
        }}
      >
        <DialogTitle
          onClose={() => {
            props.onClose()
            setFormValue({
              description: '',
            })
          }}
          id="form-dialog-title"
        >
          Report Missing Response
        </DialogTitle>
        <FormControl>
          <DialogContentNormal>
            If you can’t find the relevant maintenance response for the
            identified diagnosis you report it and provide some information
            below. The CBM application support team will review your report and
            attempt to make corrections in future application upgrades.
          </DialogContentNormal>
          <DialogContent>
            Describe the response that you required:
          </DialogContent>
          <TextFieldStyled
            autoFocus
            size="small"
            multiline={true}
            minRows={3}
            id="name"
            label=""
            type="text"
            fullWidth
            onChange={handleCommentInput}
            inputProps={{ maxLength: 1500 }}
          />
        </FormControl>
        <Hint>
          <WarningIcon style={{ height: '0.8rem' }} />
          Submitting this report will cancel this maintenance request. You won’t
          be able to proceed further with CBM. Instructions will follow on the
          next screen.
        </Hint>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              handleSubmit()
            }}
            color="secondary"
            disabled={formValue.description.length < 5 || true}
          >
            {submitting && <WaitingProgressComp size={'1rem'} />}
            Report {'&'} Cancel Response
          </Button>
        </DialogActions>
      </DialogStyled>
    </>
  )
}

export default ReportMissingResponseDialogue

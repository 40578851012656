import {
  MaintenanceItemResponseObj,
  MaterialStatusMapping,
} from './maintenanceItem'

export class OperationTableData {
  key: string
  opNo: string
  opDesc: string
  workCenter: string
  plGroup: string
  noPeople: number
  workHours: number
  durationHours: number
  cost: number
}
export function parseToOperationTableData(
  maintenanceResponse: MaintenanceItemResponseObj | null | undefined
): OperationTableData[] | undefined {
  if (
    maintenanceResponse &&
    maintenanceResponse.taskListOperation &&
    maintenanceResponse.taskListOperation.length
  ) {
    //containing operations data:
    const parsedOperationData: OperationTableData[] = []
    maintenanceResponse.taskListOperation.forEach((row, index) => {
      parsedOperationData.push({
        key: index + '',
        opNo: row.operationNumber,
        opDesc: row.operationText,
        workCenter: row.opWorkCenterText,
        plGroup: maintenanceResponse.maintenanceItem.plannerGroup,
        noPeople: parseInt(row.numberOfCapacity),
        // other units are: DAY,MIN,MON,WK,YR
        workHours:
          row.workUom === 'H' || row.workUom === 'HR' ? parseInt(row.work) : -1,
        durationHours: row.durationUom === 'H' ? parseInt(row.duration) : -1,
        cost: -1,
      })
    })
    return parsedOperationData
  } else {
    return undefined
  }
}

export const sampleOperationData: OperationTableData[] = [
  {
    key: '1',
    opNo: '0010',
    opDesc: 'Isolate area',
    workCenter: 'Operations',
    plGroup: 'Panel Operator',
    noPeople: 1,
    workHours: 4,
    durationHours: 4,
    cost: 1000.0,
  },
  {
    key: '2',
    opNo: '0020',
    opDesc: 'Place scaffolding',
    workCenter: 'Vendor',
    plGroup: 'Scaffold Operator',
    noPeople: 2,
    workHours: 1,
    durationHours: 2,
    cost: 500.0,
  },
  {
    key: '3',
    opNo: '0030',
    opDesc: 'Replace bearing',
    workCenter: 'Maintenance',
    plGroup: 'Maintenance Technician',
    noPeople: 1,
    workHours: 3,
    durationHours: 3,
    cost: 500.0,
  },
]

export class PartsTableData {
  key: string
  opNo: string
  materialNo: string
  matDesc: string
  qty: number
  uom: string
  status: string
  obsolete: string
  stocklevel: number
  leadTime: string | '-'
  value: number
}

//Logic comes from:
//https://woodside-digital.atlassian.net/wiki/spaces/PCBM/pages/1120338963/SAP+API+1+-+Get+Maintenance+Plan
export function parseToPartsTableData(
  maintenanceResponse: MaintenanceItemResponseObj | null | undefined
): PartsTableData[] | undefined {
  if (
    maintenanceResponse &&
    maintenanceResponse.taskListMaterial &&
    maintenanceResponse.taskListMaterial.length
  ) {
    //containing operations data:
    const parsedPartData: PartsTableData[] = []
    maintenanceResponse.taskListMaterial.forEach((row, index) => {
      const leadtime =
        parseInt(row.plannedDeliveryTimeInDays) +
        parseInt(row.goodsReceiptProcTimeInDays)
      parsedPartData.push({
        key: index + '',
        opNo: row.operationNumber,
        materialNo: row.materialNumber,
        matDesc: row.materialDescription,
        qty: parseInt(row.materialQty),
        uom: row.materialUom,
        status: MaterialStatusMapping[row.materialStatus]
          ? MaterialStatusMapping[row.materialStatus]
          : 'Unknown',
        // if it exist and is 80
        obsolete: MaterialStatusMapping[row.materialStatus]
          ? parseInt(row.materialStatus) === 80
            ? 'Yes'
            : 'No'
          : 'Unknown',
        stocklevel: parseInt(row.availableStockQty),
        //Material Lead Time = PlannedDeliveryTimeInDays + GoodsReceiptProcTimeInDays
        leadTime: leadtime > 0 ? leadtime + '' : '-',
        value: -1,
      })
    })
    return parsedPartData
  } else {
    return []
  }
}

const samplePartsData = [
  {
    key: '1',
    opNo: '0010',
    materialNo: '10420516',
    matDesc:
      'MOTOR, AC;EX, 400V, 4P TO XYZ May indulgence difficulty ham can put especially. Bringing remember for supplied her why was confined. Middleton principle did she procuring extensive believing add. Weather adapted prepare oh is calling. These wrong of he which there smile to my front. He fruit oh enjoy it of whose table. Cultivated occasional old her unpleasing unpleasant. At as do be against pasture covered viewing started. Enjoyed me settled mr respect no spirits civilly.',
    qty: 1,
    uom: 'PCE',
    status: 'Available',
    obsolete: 'No',
    stocklevel: 25,
    leadTime: '-',
    value: 3225.95,
  },
  {
    key: '2',
    opNo: '0030',
    materialNo: '10312662',
    matDesc: 'BELT, SYNC; 4578MM, 14M 131qwqwd dqdqw text',
    qty: 1,
    uom: 'PCE',
    status: 'Out of Stock',
    obsolete: 'No',
    stocklevel: 0,
    leadTime: '30',
    value: 624.85,
  },
  {
    key: '3',
    opNo: '0040',
    materialNo: '10251392',
    matDesc: 'PULLEY; MTR,CI,JORD-BLA SUPER TEST 123',
    qty: 1,
    uom: 'PCE',
    status: 'Low Stock',
    obsolete: 'No',
    stocklevel: 1,
    leadTime: '-',
    value: 321.95,
  },
]

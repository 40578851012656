import styled from '@emotion/styled'
import {
  Box,
  Chip,
  Divider,
  Drawer,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Tooltip,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import React, {
  useContext,
  useMemo,
  useEffect,
  useState,
  useRef,
  SyntheticEvent,
} from 'react'

import navImgFan from '../../../images/navImgSmallFan.png'
import navImgPump from '../../../images/navImgSmallPump.png'
import useEquipmentHierarchy from '../../../services/hooks/useEquipmentHierarchyData'
import ValidFlocGuard from '../../../shared/components/ValidFlocGuard'
import { EquipmentOverviewContext } from '../../../shared/providers/floc-context-provider'
import { ItemLatestMeasurementsResponse } from '../../../shared/types/getOverviewResponse'
import {
  getDescByFloc,
  getFlatFlocTreeWithDesc,
  getlastUPdatedStringMinsAgo,
  getSAConditionColor,
  getSAColor,
  SAMeasurementKeys,
  toTitleCase,
  useParamQuery,
} from '../../../shared/utility'
import useSAData from '../context-tab/hooks/useSAData'
import FlocPanel from './floc-panel/component'
import FlocParentPanel from './floc-parent-panel/component'

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const NavImgDiv = styled(Box)`
  position: absolute;
  top: ${(props: any) => props['data-top']}px;
  height: ${(props: any) => props['data-height']}px;
  width: ${(props: any) => props['data-width']}px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(${(props: any) => props['data-bgimage']});
  z-index: 10;
`

const ContextPanelText = styled(Box)`
  z-index: 100;
  color: white;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  h2 {
    opacity: 100%;
    margin: 0;
    color: white;
    font-size: 26px;
    z-index: 100;
  }
  small {
    opacity: 100% !important;
    font-size: 18px;
    font-weight: 500;
    z-index: 100;
  }
`

const StyledBox = styled(Box)((props) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '21px',
  maxWidth: `${props['data-width']}px`,
  '& .inline-flex-item': {
    display: 'inline-flex',
    ul: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      marginLeft: '10px',
    },
    li: {
      margin: 0,
      lineHeight: '1.5rem',
      borderLeft: 'none',
    },
    'li:before': {
      position: 'relative',
      top: '-10px',
      height: '26px',
      width: '16px',
      color: 'white',
      borderBottom: '2px solid white',
      borderLeft: '2px solid white',
      content: '""',
      display: 'inline-block',
      left: '-0.5rem',
    },
    span: {
      alignSelf: 'center',
      width: `${props['data-width'] / 3}px`,
    },
  },
  '& .status-row': {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '0.8rem',
    fontWeight: 500,
    '& .status-text': {
      maxWidth: `${props['data-width'] / 3}px`,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
    },
    '& .status-indicator': {
      display: 'inline-block',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      backgroundColor: `${props['data-backgroundcolor']}`, //lowercase to support safari browser
      color: `${props['data-color']}`,
    },
  },
}))

enum KeyWordInDescToMatchComponent {
  Motor = 'MOTOR,', //Description if it's motor: "MOTOR,PROPANE CONDENSOR FAN,1E1441KXX", we match on this
  STARTER = 'START,', //Description if it's STARTER: "START,FIN FAN MOTOR,1E1441KMXX", we match on this
}

interface subFlocState {
  floc: string
  status: string
  background: string
  text: string
}

const ContextPanel = () => {
  const [childFlocs, setChildFlocs] = useState<subFlocState[]>([])
  const [SAconditionString, setSAConditionString] = useState<string>('')
  const [lastUpdatedUnix, setLastUpdatedUnix] = useState(0)
  const [flocDescriptions, setFlocDescriptions] =
    useState<[{ floc: string; desc: string }]>()
  const [drawerWidth, setDrawerWidth] = useState(370)
  const [headerHeight, setHeaderHeight] = useState(100)
  const querySearch = useParamQuery()
  const eqOverview = useContext(EquipmentOverviewContext)
  const toolbar = useRef<HTMLDivElement>(null)
  const headerPanel = useRef<HTMLDivElement>(null)
  const floc = querySearch.get('floc')

  const { data: flocHierarchyData } = useEquipmentHierarchy(floc!)
  const { data: saResponse } = useSAData(floc!, 3)

  useMemo(() => {
    if (
      flocHierarchyData &&
      saResponse &&
      saResponse.children &&
      saResponse.children.length &&
      flocHierarchyData.children &&
      flocHierarchyData.children.length
    ) {
      const flocDescription = getFlatFlocTreeWithDesc(flocHierarchyData, [
        {},
      ] as any)
      setFlocDescriptions(flocDescription)

      const fanLevelStatusData = saResponse.measurements.filter(
        (flocKmeasurement) =>
          flocKmeasurement.key === SAMeasurementKeys.IndividualStatus
      )

      const fanLevelMachineConditionData = saResponse.measurements.filter(
        (flocKmeasurement) =>
          flocKmeasurement.key === SAMeasurementKeys.MachineCondition
      )
      let motorDataPoint: ItemLatestMeasurementsResponse[] | null = null
      let starterDataPoint: ItemLatestMeasurementsResponse[] | null = null
      if (saResponse.children.length) {
        motorDataPoint = saResponse.children.filter((child) => {
          const desc = getDescByFloc(child.floc, flocDescription)
          return desc && desc.startsWith(KeyWordInDescToMatchComponent.Motor)
        })
        starterDataPoint = saResponse.children.filter((child) => {
          const desc = getDescByFloc(child.floc, flocDescription)
          return desc && desc.startsWith(KeyWordInDescToMatchComponent.STARTER)
        })
      }
      const motorLevelStatusData =
        motorDataPoint?.length &&
        motorDataPoint[0].measurements.filter(
          (flocKmeasurement) =>
            flocKmeasurement.key === SAMeasurementKeys.IndividualStatus
        )

      const StarterData =
        starterDataPoint?.length &&
        starterDataPoint[0].measurements.filter(
          (flocKmeasurement) =>
            flocKmeasurement.key === SAMeasurementKeys.IndividualStatus
        )

      let fullConditionString = 'unknown'
      if (
        fanLevelMachineConditionData.length &&
        fanLevelMachineConditionData[0].values
      ) {
        fullConditionString = fanLevelMachineConditionData[0].values[0].value
        setLastUpdatedUnix(fanLevelMachineConditionData[0].values[0].timestamp)
      }
      setSAConditionString(fullConditionString)

      const childFloc: any = []
      const status =
        fanLevelStatusData &&
        fanLevelStatusData.length &&
        fanLevelStatusData[0].values
          ? fanLevelStatusData[0].values[0].value
          : 'Unknown'
      const { background, text } = getSAColor(status || 'Data Quality Issue')
      childFloc.push({
        floc,
        status,
        background,
        text,
      })

      if (motorDataPoint && motorDataPoint.length) {
        const status =
          motorLevelStatusData &&
          motorLevelStatusData.length &&
          motorLevelStatusData[0].values
            ? motorLevelStatusData[0].values[0].value
            : 'Unknown'
        const { background, text } = getSAColor(status || 'Data Quality Issue')
        childFloc.push({
          floc: motorDataPoint[0].floc,
          status,
          background,
          text,
        })
      }
      if (starterDataPoint && starterDataPoint.length) {
        const status =
          StarterData && StarterData.length && StarterData[0].values
            ? StarterData[0].values[0].value
            : 'Unknown'
        const { background, text } = getSAColor(status || 'Data Quality Issue')
        childFloc.push({
          floc: starterDataPoint[0].floc,
          status,
          background,
          text,
        })
      }
      setChildFlocs(childFloc)
    }
  }, [flocHierarchyData, saResponse])

  useEffect(() => {
    if (headerPanel && headerPanel.current && eqOverview) {
      setHeaderHeight(headerPanel.current.clientHeight)
    }
  }, [headerPanel, eqOverview, childFlocs])

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      drawer: {
        width: drawerWidth,
      },
      drawerPaper: {
        width: drawerWidth,
        overflowX: 'hidden',
      },
    })
  )

  const classes = useStyles()
  const [tabSelectionValue, setTablSelectionValue] = useState(0)

  const handleChange = (event: SyntheticEvent, newTabValue: number) => {
    setTablSelectionValue(newTabValue)
  }

  const SAConditionalChip = () => {
    const SAconditionStringArray = SAconditionString.split('-')
    const saConditionLevel = SAconditionStringArray[0].split(' ')[1]
    const { background, text } = getSAConditionColor(SAconditionStringArray[1])
    return (
      <Chip
        label={`Condition ${saConditionLevel ?? 'Unknown'}`}
        sx={{
          backgroundColor: background,
          color: text,
          marginBottom: '0.5rem',
        }}
      />
    )
  }

  return (
    <ValidFlocGuard>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar ref={toolbar} />
        {eqOverview && (
          <NavImgDiv data-bgimage={navImgFan}>
            <h2>{floc}</h2>
            <small>Invalid Floc</small>
          </NavImgDiv>
        )}
        {eqOverview && (
          <>
            <NavImgDiv
              data-top={toolbar.current?.offsetHeight}
              data-height={headerHeight}
              data-width={drawerWidth}
              data-bgimage={eqOverview.isFan() ? navImgFan : navImgPump}
            />
            <ContextPanelText ref={headerPanel}>
              <Tooltip
                title={
                  (SAconditionString?.split('-')[1] ?? '') +
                  (lastUpdatedUnix
                    ? ` - Last updated ${getlastUPdatedStringMinsAgo(
                        lastUpdatedUnix
                      )}`
                    : '')
                }
                enterDelay={500}
                disableInteractive
              >
                {SAConditionalChip()}
              </Tooltip>
              <h2>{floc}</h2>
              <small>
                {eqOverview
                  ? eqOverview.serviceName().replace(/,/g, ', ')
                  : 'loading...'}
              </small>
              <br />
              <br />
              {childFlocs.map(({ status, floc, background, text }, index) => {
                return (
                  <StyledBox
                    data-width={drawerWidth}
                    key={index}
                    data-backgroundcolor={background}
                    data-color={text}
                  >
                    <Box className="inline-flex-item">
                      <ul>
                        <li></li>
                      </ul>
                      <Tooltip
                        enterDelay={500}
                        title={getDescByFloc(floc, flocDescriptions!) ?? ''}
                        disableInteractive
                      >
                        <span>{floc}</span>
                      </Tooltip>
                    </Box>
                    <Box className="status-row">
                      <Tooltip
                        title={toTitleCase(status)}
                        enterDelay={500}
                        disableInteractive
                      >
                        <Box className="status-text">{toTitleCase(status)}</Box>
                      </Tooltip>
                      <Box component="span" className="status-indicator"></Box>
                    </Box>
                  </StyledBox>
                )
              })}
            </ContextPanelText>
            <Divider />
            <Tabs
              value={tabSelectionValue}
              onChange={handleChange}
              aria-label="Nav Tabs"
              variant="fullWidth"
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#001543',
                },
              }}
            >
              <Tab label={'THIS ASSEMBLY'} {...a11yProps(0)} />
              <Tab
                disabled={eqOverview?.isESD()}
                label={'SURROUNDING'}
                {...a11yProps(1)}
              />
            </Tabs>
            <FlocPanel
              value={tabSelectionValue}
              index={0}
              currentfloc={eqOverview.FLOC || floc!}
            />
            <FlocParentPanel
              value={tabSelectionValue}
              index={1}
              descmatching={eqOverview.serviceName()}
            />
          </>
        )}
      </Drawer>
    </ValidFlocGuard>
  )
}

export default ContextPanel

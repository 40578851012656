import { useQuery } from 'react-query'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'

import { getEquipmentMeasurementsWithoutValue } from '../graphql/EQ360Queries'
import { Eq360GraphFetch } from '../../shared/auth'
import EquipmentOverview from '../../shared/types/getOverviewResponse'

async function fetchEquipmentMeasurementsByFlocWithOutValue(
  msalInstance: IPublicClientApplication,
  floc: string,
  startTime: number,
  endTime: number
) {
  const result = await Eq360GraphFetch(
    msalInstance,
    import.meta.env.VITE_EQ360_ID!,
    fetch,
    import.meta.env.VITE_EQ360_HOST!,
    getEquipmentMeasurementsWithoutValue(floc, endTime, startTime)
  )
  return new EquipmentOverview(result.getOverview)
}

const useEquipmentWithOutMeasurements = (
  floc: string,
  startTime: number,
  endTime: number,
  enabled: boolean
) => {
  const { instance: msalInstance } = useMsal()

  return useQuery(
    ['EquipmentMeasurementsWithOutValue', floc, startTime],
    () =>
      fetchEquipmentMeasurementsByFlocWithOutValue(
        msalInstance,
        floc,
        startTime,
        endTime
      ),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 20 * 1000 * 60, // 20 min stale time,
      enabled: enabled,
    }
  )
}
export default useEquipmentWithOutMeasurements

import styled from '@emotion/styled'
import {
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  IconButton,
  Theme,
  Box,
  Typography,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogueActions: {
      padding: '1rem',
    },
    backBtn: {
      color: '#00354D',
    },
  })
)

const StyledContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  h1 {
    padding: 4px;
    padding-left: 0;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }
`

const Content = styled(Box)`
  text-align: left;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
`

interface CancelRequestDialogueProps {
  open: boolean
  onClose: () => void
}

const CancelRequestDialogue = ({
  open,
  onClose,
}: CancelRequestDialogueProps) => {
  const classes = useStyles()
  const myHistroy = useHistory()
  return (
    <Dialog
      maxWidth="sm"
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return
        }
        onClose()
      }}
      aria-labelledby="dialog-diagnosis-action"
      aria-describedby="dialog-diagnosis-done"
      open={open}
    >
      <StyledContent>
        <Typography variant="h1">Cancellation Confirmation!</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Content id="dialog-diagnosis-content">
          Taking this action will cancel and clear any information you have
          provided for this maintenance request. You will be able to come back
          and process it again later but will need to start again.
        </Content>
      </StyledContent>
      <DialogActions className={classes.dialogueActions}>
        <Button onClick={onClose} className={classes.backBtn}>
          Back to Request
        </Button>
        <Button
          onClick={() => {
            myHistroy.push('/')
          }}
          color="secondary"
          variant="contained"
        >
          Cancel {'&'} Return to Alerts
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelRequestDialogue

import styled from '@emotion/styled'
import { Box, CircularProgress } from '@mui/material'
import React, { useState } from 'react'

import OpamIcon from '../../../../../icons/opamIcon.svg?react'
import useEquipmentOPAMData from '../../../../../services/hooks/useEquipmentOPAMData'
import UIOPAMResult, {
  ConvertToUIPamResult,
} from '../../../../../shared/types/UIOpamObject'
import { useParamQuery } from '../../../../../shared/utility'
import OPAMOverview from './overview'

const Root = styled(Box)`
  padding: 1em;
`
const ErrorDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #666666;

  #red {
    color: #f44336;
    b {
      font-weight: 500;
    }
  }
  a {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  #inline {
    a {
      margin: 0;
      display: inline;
    }
  }
`

const Heading = styled(Box)`
  display: flex;
  place-content: space-between;
  #status {
    display: flex;
    flex-direction: row;
    width: 15%;
    min-width: 10rem;
    gap: 0.5rem;
    align-items: flex-start;
    #severity {
      width: 80%;
    }
  }
`
const WaitingProgressComp = styled(CircularProgress)`
  grid-column: 4/12;
`
const OPAMContainer = styled(Box)`
  background-color: white;
  padding: 0 1em 1em 0em;
`

const today = Math.floor(new Date().getTime() / 1000)

const OPAMCard = (props: any) => {
  const mySearchQuery = useParamQuery()

  const { data, error, isLoading } = useEquipmentOPAMData(
    mySearchQuery.get('floc')!,
    8,
    true
  )
  const [opamUIData, setOpamUIData] = useState<UIOPAMResult[]>([])
  React.useMemo(() => {
    if (data && data.pamTasks && data.pamTasks.length) {
      setOpamUIData(
        ConvertToUIPamResult(data.pamTasks, mySearchQuery.get('floc')!).sort(
          (a, b) => b.recordDate.diff(a.recordDate)
        )
      )
    }
  }, [data])

  const isLoaded = () => !isLoading
  return (
    <Root>
      <Heading>
        <h3>
          <OpamIcon style={{ marginRight: '.5rem', height: '1rem' }} />
          OPAM Overview
        </h3>
      </Heading>
      {!isLoaded() && <WaitingProgressComp />}
      {isLoaded() && error && (
        <ErrorDiv data-testid="error">
          <div id="red">
            <b>Error:</b>It looks like we’re currently unable to return any OPAM
            data for this equipment. Please contact IT support or try again
            later.
          </div>
          <div id="inline">
            You can still continue with the maintenance response through CBM.
          </div>
        </ErrorDiv>
      )}
      {isLoaded() && data && (
        <OPAMContainer boxShadow={0}>
          <OPAMOverview tableData={opamUIData} />
        </OPAMContainer>
      )}
    </Root>
  )
}
export default OPAMCard

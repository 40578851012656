import styled from '@emotion/styled'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { Box } from '@mui/material'

import QuestionMark from '../../../../../icons/question-mark.svg?react'

const ColorMapping = {
  normal: '#8BC34A',
  caution: '#FFEB3B',
  abnormal: '#FF9800',
  severe: '#FF5722',
}

export const SeverityMapping = {
  normal: 1,
  caution: 2,
  abnormal: 3,
  severe: 4,
}

const ColorTextMapping = {
  normal: 'white',
  caution: 'black',
  abnormal: 'white',
  severe: 'white',
}

const StatusDiv = styled(Box)`
  #status {
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    display: flex;
    span {
      padding: 0.1rem 1rem;
      font-weight: 500;
      border-radius: 4px;
    }
    #tooltip {
      color: #0064ce;
      position: relative;
      margin-right: 0.2rem;
      top: 0.2rem;
    }
  }
`

export interface OilStatusProps {
  status: string
  isHighest?: Boolean
}

const OilStatus = (props: OilStatusProps) => {
  return (
    <StatusDiv>
      <div id="status">
        <span
          style={{
            backgroundColor:
              ColorMapping[props.status.toLowerCase()] ?? 'white',
            color: ColorTextMapping[props.status.toLowerCase()] ?? 'black',
          }}
        >
          {props.status}
        </span>
        <div id="tooltip">
          <Tooltip
            title={
              props.isHighest
                ? 'This status has been provided by ALS tribology services. It is based on the most severe status present from the last available ALS fluid sample results for this equipment. ALS statuses consist of (In increasing order of severity) Normal, Caution, Abnormal, Severe.'
                : 'This status has been provided by ALS tribology services. It is based on the last available ALS fluid sample results for this compartment type. ALS statuses consist of (In increasing order of severity) Normal, Caution, Abnormal, Severe.'
            }
            disableInteractive
          >
            <QuestionMark style={{ width: '18px' }} />
          </Tooltip>
        </div>
      </div>
    </StatusDiv>
  )
}
export default OilStatus

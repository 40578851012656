import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import { getEQ360EquipmentAnomalyModelOutputsQuery } from '../../../../services/graphql/EQ360Queries'
import { Eq360GraphFetch } from '../../../../shared/auth'
import { EquipmentType } from '../../../../shared/types/equipmentTypes'
import GetEquipment, {
  AnomalyOutputType,
  getDesiredAnomalyType,
} from '../../../../shared/types/getEquipmentResponse'

async function fetchEQ360Anomaly(
  msalInstance: IPublicClientApplication,
  itemId: string,
  from: number,
  to: number,
  anomalyOutputTypes: AnomalyOutputType[]
) {
  const result = await Eq360GraphFetch(
    msalInstance,
    import.meta.env.VITE_EQ360_ID!,
    fetch,
    import.meta.env.VITE_EQ360_HOST!,
    getEQ360EquipmentAnomalyModelOutputsQuery(
      itemId,
      anomalyOutputTypes,
      from,
      to
    )
  )
  return new GetEquipment(result.getEquipment)
}

const useAnomalyMeasureHistoryData = (
  itemId: string,
  from: number,
  to: number,
  eqType?: EquipmentType,
  outputType?: AnomalyOutputType[]
) => {
  const { instance: msalInstance } = useMsal()
  let anomalyOutputTypes: AnomalyOutputType[] = []
  if (eqType) {
    anomalyOutputTypes = getDesiredAnomalyType(eqType)
  }
  if (outputType) {
    anomalyOutputTypes = anomalyOutputTypes.concat(outputType)
  }
  return useQuery(
    ['anomalyHistory', itemId, from, to, anomalyOutputTypes],
    () => fetchEQ360Anomaly(msalInstance, itemId, from, to, anomalyOutputTypes),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 15 * 1000 * 60, // 15min stale time,
      enabled: Boolean(anomalyOutputTypes.length),
    }
  )
}

export default useAnomalyMeasureHistoryData

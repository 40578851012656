import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { render } from 'react-dom'
import 'normalize.css'
import 'antd/dist/antd.min.css'
import { init as initApm } from '@elastic/apm-rum'
import { MsalProvider } from '@azure/msal-react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { PublicClientApplication } from '@azure/msal-browser'

import App from './App'
import './index.css'
import { msalConfig } from './shared/auth'

// Initialise MS Clarity
(function (c, l, a, r, i, t, y) {
  c[a] =
    c[a] ||
    function (...args) {
      (c[a].q = c[a].q || []).push(args)
    }
  t = l.createElement(r)
  t.async = 1
  t.src = 'https://www.clarity.ms/tag/' + i
  y = l.getElementsByTagName(r)[0]
  y.parentNode.insertBefore(t, y)
})(window, document, 'clarity', 'script', import.meta.env.VITE_CLARITY_KEY)

const queryClient = new QueryClient()
const pca = new PublicClientApplication(msalConfig)
const apm = initApm({
  serviceName: 'Condition Based Maintenance',
  serverUrl: import.meta.env.VITE_APM_SERVER!,
  serviceVersion: '2.2.3',
  environment: import.meta.env.VITE_APM_APP_ENV,
})
apm.setUserContext({
  id: uuidv4(),
})

render(
  <MsalProvider instance={pca}>
    <QueryClientProvider client={queryClient}>
      <App />
      {/* <ReactQueryDevtools position={'top-left'} initialIsOpen={false} /> */}
    </QueryClientProvider>
  </MsalProvider>,
  document.getElementById('root')
)

import moment from 'moment'
import { AlertDetail } from './alert'
import { ISSOWStrategyTypes, ParseStrategyDataRules } from './strategyRule'

export const SubmissionReadyCheckFunc = (data: DiagnosisResponseType) => {
  let correctDate = false
  const momentExeDate = moment(data.proposedExeDate)
  let RULDate = false
  if (
    momentExeDate.isValid() &&
    momentExeDate.isAfter(moment().subtract(1, 'd'))
  ) {
    correctDate = true
  }
  if (data.equipmentAlreadyBrokeFlg || Boolean(data.estimatedRiskEndDate)) {
    RULDate = true
  }
  if (
    data.failureMode &&
    data.component &&
    correctDate &&
    data.response &&
    data.measuringPointId &&
    RULDate
  ) {
    return true
  }
  return false
}

export default class DiagnosisResponseType {
  equipmentType: string // NOTE this is equipment type of the failure mode, also known as UNIT
  failureMode: string // predefined description of the failure, currently defined in cbm-config repo's excel sheet
  component: string // motor, belt, etc ,, also known as failure component
  workLongText: string // response detail , describe problem in detail, goes to work order long text
  estimatedRiskEndDate?: string // expected broken down date, currently sending iso format, will forward to requiredEndDate in SAP
  equipmentAlreadyBrokeFlg?: boolean // if it's already broken (also known as enddate in sap)
  diagnosedBy?: string // wopID
  userEmail?: string // full email
  id?: string // unique uuid for backend to link with alert
  proposedExeDate: string // eg. "2021-01-18T05:45:20.072Z", also known as requiredStartDate in SAP

  //Current NOT Sending to SAP, but wrapped inside workLongText
  additionalComments: string // why user choosed this date, mapped to "Additonal Comments" in UI due to user feedback,
  taskListCode: string // not sending to anywhere, only for UI to show
  response: string // what do you do with the failure mode, mapped to a tasklist, predefined in cbm-config's repo
  maintenancePlanCode: string // mapped to a Measurement point
  measuringPointId: string // SAP measurement point for trigger the measurement Doc to create WO and Noti
  drivenFloc: string // the floc of concern
  masterRelationshipTemplatesToCreate?: string // isolation cert name, sent to ISSOW
  templateToApply?: string // Permit template, sent to ISSOW
  //Ad-hoc flag, not used
  adhocAnomalyDiagnosis?: boolean
  permitRequired?: boolean // if LRP, then sent this flag to indicate no ISSOW call is required.
  ownIsolation?: boolean // if ownIsolation, sent this flag

  // equipment type from eq360 for UI to use later.
  fuseEquipmentType: string
  constructor(data: any) {
    Object.assign(this, data)
  }

  /**
   * Format this object so that it can be successfully parsed and used by CBM-API
   * @return DiagnosisResponseType the response that is prepared and ready
   */
  get formattedDiagnosisResponse() {
    const formattedDiagnosisResponse = new DiagnosisResponseType(this) // make a copy
    let defaultDays = 0
    if (this.estimatedRiskEndDate && !this.equipmentAlreadyBrokeFlg) {
      defaultDays = Math.ceil(
        Math.abs(
          (new Date().getTime() -
            new Date(this.estimatedRiskEndDate).getTime()) /
            (1000 * 3600 * 24)
        )
      )
    }
    const workLongText = `
    *********************************************************
        Component: ${this.component} : ${this.response}

        ${moment().format()}, ${this.diagnosedBy}

        DESCRIBE THE PROBLEM IN DETAIL
        Problem Details: ${this.workLongText ? this.workLongText : 'none'}
        Unit: ${this.equipmentType}
        Component: ${this.component}
        Failure Mode: ${this.failureMode} 
        Estimated Remaining Useful Life: ${
          this.equipmentAlreadyBrokeFlg || defaultDays === 0
            ? 'already broken down'
            : defaultDays + ' days'
        }

        PROPOSE A SOLUTION
        Selected Response: ${this.response}
        Additional Comments: ${
          this.additionalComments ? this.additionalComments : 'none'
        }

        CBM REQUEST INFORMATION
        Alert ID: ${this.id ? this.id : 'ad-hoc'}
        Measuring Point ID: ${this.measuringPointId}
        Maintenance Plan Code: ${this.maintenancePlanCode}
        Driven FLOC: ${this.drivenFloc}
    *********************************************************
    `

    if (this.equipmentAlreadyBrokeFlg) {
      // RED is requiredEndDate which is estimatedRiskEndDate below
      // RSD is RequiredStartDate which is this.proposedExeDate below
      // set it to  exe date, according to Budi's email below (21/01/2021),
      // The RED vs RSD validation in Notification as follow:
      // RED < RSD status code: 1 (error), and Noti update is failed.
      // RED = blank (“”)  status code: 0 (success), and Noti update is completed without RED update.
      // The current RED will be left as defined on noti creation (based on Plan Start Date of Maintenance Plan & Priority calculation)
      // RED = RSD status cod: 0 (success), and Noti update is completed with both RSD and RED update (the same date)

      // Option #3 is the best option, if breakdown indicator is set, then RED should be made the same as RSD.
      formattedDiagnosisResponse.estimatedRiskEndDate = this.proposedExeDate
    }

    formattedDiagnosisResponse.workLongText = workLongText

    // based on D6 https://woodside-digital.atlassian.net/wiki/spaces/PCBM/pages/701761503/Design+and+architecture+decisions
    // ReqStartDate must be before or same as ReqEndDate.
    if (this.estimatedRiskEndDate) {
      if (
        new Date(this.estimatedRiskEndDate).getTime() <=
        new Date(this.proposedExeDate).getTime()
      ) {
        formattedDiagnosisResponse.estimatedRiskEndDate = this.proposedExeDate
      }
    }

    // diagnosedBy MUST be < 12 chars, should be handled by getWopIdOREmail already

    // clean permit and iso input
    if (
      ParseStrategyDataRules(this.masterRelationshipTemplatesToCreate) !==
      ISSOWStrategyTypes.Templated
    ) {
      formattedDiagnosisResponse.masterRelationshipTemplatesToCreate = ''
    }
    // set flag for iso if owniso is used
    if (
      ParseStrategyDataRules(this.masterRelationshipTemplatesToCreate) ===
      ISSOWStrategyTypes.OwnIsolations
    ) {
      // own isolations
      formattedDiagnosisResponse.ownIsolation = true
    }
    if (
      // permit check
      ParseStrategyDataRules(this.templateToApply) === ISSOWStrategyTypes.LRP
    ) {
      formattedDiagnosisResponse.permitRequired = false
    } else {
      formattedDiagnosisResponse.permitRequired = true
    }

    // add fuseEquipmentType
    formattedDiagnosisResponse.fuseEquipmentType = this.fuseEquipmentType

    return formattedDiagnosisResponse
  }
}

export class UIInternalDiagnosisStateType {
  multiAlert: boolean
  processingAlert?: AlertDetail
  diagnosisResponseType: DiagnosisResponseType
  timeValue?: number
  timeUnit?: 'months' | 'days' | 'weeks'
  constructor() {
    Object.assign(this, {
      multiAlert: false,
      timeUnit: 'months',
      diagnosisResponseType: {
        drivenFloc: '',
        equipmentType: '',
        failureMode: '',
        component: '',
        response: '',
        maintenancePlanCode: '',
        measuringPointId: '',
        additionalComments: '',
        proposedExeDate: '',
      },
    })
  }
}

interface Anomaly {
  permitID?: string
  permitMessage?: string
  permitName?: string
  isolationCerts?: string
  measurementDocNumber?: string
}

export interface DiagnosedAnomalyResponse {
  data?: Anomaly
  status?: Number
}

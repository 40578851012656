import axios from 'axios'
import React, { createContext, ReactNode } from 'react'
import useResourcingToken from '../../services/hooks/useTokenService'
import { v4 as uuidv4 } from 'uuid'

type LoggingContextType = {
  log: (level: 'INFO' | 'WARNING' | 'ERROR', message: string) => void
}

export const LoggingContext = createContext<LoggingContextType>({
  log: (level: 'INFO' | 'WARNING' | 'ERROR', message: string) => {},
})

export function useLoggingContext() {
  return React.useContext(LoggingContext)
}
type PropsType = {
  children: ReactNode
}

export default function LogProvider({ children }: PropsType) {
  const [sessionId, setSessionId] = React.useState(uuidv4())

  const { data: token } = useResourcingToken(
    import.meta.env.VITE_AAD_CLIENTID! + '/.default'
  )
  const log = async (level: 'INFO' | 'WARNING' | 'ERROR', message: string) => {
    const corrId = sessionId
    if (!token) {
      await setTimeout(() => {}, 3000)
    }
    if (token) {
      axios(import.meta.env.VITE_CBM_ENDPOINT + '/uilog', {
        method: 'POST',
        data: {
          correlationId: corrId,
          component: 'ui',
          level: level,
          message:
            typeof message === 'object'
              ? JSON.stringify(message)
              : String(message),
        },
        headers: {
          Authorization: `Bearer ${token!.accessToken}`,
        },
      }).catch(() => {
        console.warn('UI Logging failed')
      })
    }
  }

  return (
    <LoggingContext.Provider value={{ log }}>
      {children}
    </LoggingContext.Provider>
  )
}

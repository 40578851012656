import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

import { PAMObj, PAMResult } from './getEquipmentResponse'
import { GlobalDateFormatter, GlobalTimeFormatterHHMM } from '../utility'

// relocate this file with getEquipmentResponse?
export function ConvertToUIPamResult(
  pamTasks?: PAMObj[],
  filteringFloc?: string
): UIOPAMResult[] {
  const results: UIOPAMResult[] = []
  if (pamTasks && pamTasks.length) {
    let pamTasksFiltered = pamTasks
    if (filteringFloc) {
      pamTasksFiltered = pamTasks.filter((pamObj) => {
        return pamObj.equipment.includes(filteringFloc)
      })
    }
    pamTasksFiltered.forEach((pamObj) => {
      pamObj.results.forEach((result) => {
        results.push(
          new UIOPAMResult(result, pamObj.description, pamObj.equipment)
        )
      })
    })
  }
  return results
}
export default class UIOPAMResult {
  uniqueId: string
  tag: string
  taskDetail: string
  result: string
  recordDateString: string
  recordDate: moment.Moment
  comments: string
  recordedBy: string
  constructor(data: PAMResult, description: string, equipment: string) {
    this.uniqueId = uuidv4()
    this.recordDate = moment(data.recordedDate)
    this.recordDateString = `${GlobalDateFormatter.format(
      moment(data.recordedDate).toDate()
    )}, ${GlobalTimeFormatterHHMM.format(moment(data.recordedDate).toDate())}`
    this.taskDetail = description
    this.result = data.result
    this.comments = data.comments ?? ' - '
    this.tag = equipment
    this.recordedBy = data.recordedBy
  }
}

import styled from '@emotion/styled'
import {
  Box,
  CircularProgress,
  Tooltip as TooltipMaterial,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  TableContainer,
  Checkbox,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import QuestionMarkIcon from '../../../../icons/question-mark.svg?react'
import VibrationIcon from '../../../../icons/analytics.svg?react'
import { EquipmentType } from '../../../../shared/types/equipmentTypes'
import {
  AnomalyOutputType,
  getDesiredAnomalyType,
  AnomalySeverityPrettyNames,
  AnomalyModelOutputObj,
} from '../../../../shared/types/getEquipmentResponse'
import {
  ChartBandingColors,
  getBandingColor,
  useParamQuery,
} from '../../../../shared/utility'
import { useWindowSize } from '../../context-panel/floc-panel/useWindowResize'
import useAnomalyMeasureHistoryData from '../hooks/useAnomalyMeasureHistoryData'
import {
  changeGraphAnomalyState,
  getSeverityScoreFromState,
  isAnomalyOutputEnabled,
  setGraphStateLastValue,
  VibrationSummaryGraphState,
} from './chartState'
import { useBandingData } from '../../../../shared/components/useBandingData'

const useStyles = makeStyles({
  root: {
    margin: '1rem',
  },
  cardTitle: {
    marginTop: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardTitleDisabled: {
    opacity: 0.5,
  },
  chartWrapper: {
    marginTop: '0.5rem',
    position: 'relative',
  },
  chartOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableContainer: {
    marginTop: '1rem',
    padding: '0rem 1rem',
  },
  controlsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
    margin: '0.5rem 0',
  },
  timeRangeLabel: {
    color: '#666666',
    marginRight: '1rem',
  },
  modalContainer: {
    position: 'relative',
    display: 'inline-block',
    marginLeft: '50px',
    marginTop: '1rem',
  },
})

const ToolTipSpan = styled.span`
  font-size: 0.9rem;
  color: ${(props: any) => (props['aria-disabled'] ? '' : '#0064CE')};
  margin-left: 0.5rem;
  svg {
    margin-right: 0.2rem;
    top: 0.1rem;
    width: 1rem;
    position: relative;
  }
`

const AnalyticsModelLabelCellDiv = styled(Box)`
  #colorBox {
    background-color: ${(props) => props.color};
    width: 1rem;
    height: 1rem;
    border-radius: 5px;
    margin-right: 0.5rem;
  }
  #colorBoxRound {
    background-color: ${(props) => props.color};
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  display: flex;
  align-items: center;
`

const GraphMargin = 5

type TimeRange = {
  displayLabel: string
  noOfMonths: number
  startTimestamp: number
  endTimestamp: number
}

// TODO: Overlay maintenance activities https://recharts.org/en-US/examples/CustomizedDotLineChart
interface SeverityHisoryProps {
  equipmentType: EquipmentType
}

function getColorByAnomalyType(severityType: AnomalyOutputType) {
  switch (severityType) {
    case 'vibrationSeverity':
      return '#D71638'
    case 'sealSeverity':
      return '#FF8E50'
    case 'performanceSeverity':
      return '#2E7D32'
    case 'equipFaultSeverity':
      return '#6B9AFF'
    default:
      return '#6B9AFF'
  }
}

const TimeRangeContainer = styled(Box)`
  background: rgba(0, 21, 67, 0.08);
  display: inline-block;
  margin-top: -5px;
  select {
    font-weight: 500;
    font-size: 13px;
    text-align: center;
  }
`

const TableCellHead = styled(TableCell)`
  padding: 2rem 0;
`

const ShowAlertContainer = styled(Box)`
  font-size: 14px;
  vertical-align: middle;
`

const SeverityHistory = (props: SeverityHisoryProps) => {
  const classes = useStyles()
  const mySearchQuery = useParamQuery()
  const [showSeverityHistory, setShowSeverityHistory] = React.useState(true)
  const bandingGrid = useRef<any>(null)
  const today = new Date()
  const timeRangeCandidates: TimeRange[] = [
    {
      noOfMonths: 3,
      displayLabel: '3 Months',
      endTimestamp: Math.floor(today.getTime() / 1000),
      startTimestamp: Math.floor(
        new Date(today).setMonth(today.getMonth() - 3) / 1000
      ),
    },
    {
      noOfMonths: 6,
      displayLabel: '6 Months',
      endTimestamp: Math.floor(today.getTime() / 1000),
      startTimestamp: Math.floor(
        new Date(today).setMonth(today.getMonth() - 6) / 1000
      ),
    },
    {
      noOfMonths: 12,
      displayLabel: '1 Year',
      endTimestamp: Math.floor(today.getTime() / 1000),
      startTimestamp: Math.floor(
        new Date(today).setMonth(today.getMonth() - 12) / 1000
      ),
    },
  ]

  const [chartState, setChartState] = useState<VibrationSummaryGraphState>({
    enabledAnomalyOutputType: getDesiredAnomalyType(props.equipmentType),
    lastValues: [],
  })
  const [timeRange, setTimeRange] = useState<TimeRange>(timeRangeCandidates[0])
  const windowSize = useWindowSize()

  const { data, error, isLoading } = useAnomalyMeasureHistoryData(
    mySearchQuery.get('floc')!,
    timeRange.startTimestamp,
    timeRange.endTimestamp,
    props.equipmentType
  )
  const [gridThresholdPoints, setGridThresholdPoints] = useState([] as number[])
  const [hideGrid, setHideGrid] = useState(false)
  const thresholds = useBandingData(
    mySearchQuery.get('floc')!,
    props.equipmentType
  )

  const calculateBandingDataPoints = (bandThresholds: number[]) => {
    if (bandingGrid.current && bandThresholds.length) {
      let sum = 0
      const totalHeight = bandingGrid.current.props.height + GraphMargin

      const finalPoints = [] as number[]
      for (let i = 0; i < bandThresholds.length - 1; i++) {
        sum = (bandThresholds[i] - bandThresholds[i + 1]) * totalHeight + sum
        finalPoints.push(sum)
      }

      return finalPoints.reverse()
    } else return []
  }

  useEffect(() => {
    if (bandingGrid.current) {
      setGridThresholdPoints(calculateBandingDataPoints(thresholds))
    }
  }, [thresholds])

  useEffect(() => {
    setTimeout(() => {
      const points = calculateBandingDataPoints(thresholds)
      if (points.length) {
        setGridThresholdPoints(points)
      }
    }, 5000)
  }, [windowSize])

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        setShowSeverityHistory(false)
      }
    }
  }, [data, error, isLoading])

  const [chartData, setChartData] = useState<any[]>([
    {
      date: timeRange.startTimestamp,
    },
    {
      date: timeRange.endTimestamp,
    },
  ])

  useMemo(() => {
    if (data && data.anomalyModelOutputs && data.anomalyModelOutputs.length) {
      const currentTimeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000
      const desiredAnomalyTypes = getDesiredAnomalyType(props.equipmentType)
      let graphData: any[] = []
      let newChartState = { ...chartState }

      desiredAnomalyTypes.forEach((lineType) => {
        const tsData = data.anomalyModelOutputs.filter(
          (value) =>
            value.measureName === lineType && Number.parseFloat(value.value) > 0
        )
        if (tsData.length) {
          const lastValue = tsData[0].valueOf
          newChartState = setGraphStateLastValue(
            newChartState,
            lineType,
            Number.parseFloat(lastValue.toFixed(2))
          )
        }
        const groupedDate = tsData.reduce((last, current) => {
          Object.assign({ ...last, date: current.datePart })
          last[current.datePart] = (last[current.datePart] || []).concat(
            current
          )
          return last
        }, {})
        Object.keys(groupedDate).forEach((date) => {
          const readingsOftheDay: AnomalyModelOutputObj[] = groupedDate[date]
          const dailyAvg =
            readingsOftheDay.reduce(
              (a, b) => a.valueOf() + (b.valueOf || 0),
              0
            ) / readingsOftheDay.length
          graphData = graphData.concat({
            date: new Date(parseInt(date)).getTime() - currentTimeZoneOffset,
            [lineType]: Number.parseFloat(dailyAvg.toFixed(2)),
          })
        })
      })

      let mergedGraphData: any = []
      graphData.forEach(function (item) {
        const existing = mergedGraphData.filter(function (v: any, i: any) {
          return v.date === item.date
        })
        if (existing.length) {
          const existingIndex = mergedGraphData.indexOf(existing[0])
          mergedGraphData[existingIndex] = {
            ...mergedGraphData[existingIndex],
            ...item,
          }
        } else {
          mergedGraphData.push(item)
        }
      })

      mergedGraphData = mergedGraphData.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      )
      if (!mergedGraphData.length) {
        setShowSeverityHistory(false)
      }
      setChartState(newChartState)
      setChartData(mergedGraphData)
    }
  }, [data])

  function formatDate(timestamp: number) {
    const d = new Date(timestamp)
    const ye = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${da} ${mo} ${ye}`
  }

  return (
    <div
      className={`${classes.root} ${
        !showSeverityHistory ? classes.cardTitleDisabled : ''
      }`}
    >
      <div>
        <div className={`${classes.cardTitle}`}>
          <h3 style={{ marginBottom: 0 }}>
            <VibrationIcon
              style={{ height: '1rem', width: '1rem', marginRight: '0.5rem' }}
            />
            Anomaly History
          </h3>
          {!showSeverityHistory && (
            <TooltipMaterial
              title={
                'This card is disabled either because the relevant monitoring is not conducted OR the required data is not available/configured.'
              }
            >
              <ToolTipSpan aria-disabled={true}>
                <QuestionMarkIcon style={{ width: '18px' }} />
                Why is this disabled?
              </ToolTipSpan>
            </TooltipMaterial>
          )}
        </div>
        {showSeverityHistory && (
          <>
            {chartData && (
              <div
                className={classes.chartWrapper}
                data-testid="anomaly-history-graph"
              >
                {isLoading && (
                  <div className={classes.chartOverlay}>
                    <CircularProgress />
                  </div>
                )}
                <ResponsiveContainer width="99%" aspect={5} debounce={300}>
                  <LineChart
                    data={chartData}
                    margin={{
                      top: GraphMargin,
                      right: 0,
                      left: 0,
                      bottom: GraphMargin,
                    }}
                  >
                    <CartesianGrid
                      ref={bandingGrid}
                      fill="#fcfcfc"
                      vertical={false}
                      strokeDasharray="1 4"
                      horizontalPoints={
                        !hideGrid ? gridThresholdPoints : undefined
                      }
                      horizontalFill={
                        gridThresholdPoints.length && !hideGrid
                          ? ChartBandingColors
                          : undefined
                      }
                    />
                    <XAxis
                      type="number"
                      dataKey="date"
                      tickMargin={15}
                      reversed={false}
                      tickCount={10}
                      minTickGap={20}
                      tick={{ fontSize: 11 }} // dx: 10 if we want to move the tick text to the right
                      tickFormatter={formatDate}
                      domain={[
                        timeRange.startTimestamp * 1000,
                        timeRange.endTimestamp * 1000,
                      ]}
                      axisLine={{ stroke: '#C8C8C8' }}
                    />
                    <YAxis
                      yAxisId="right"
                      type="number"
                      tick={{ fontSize: 11 }}
                      orientation="right"
                      domain={[0, 1]}
                      axisLine={false}
                      tickLine={false}
                    />
                    <YAxis
                      yAxisId="left"
                      tick={false}
                      orientation="left"
                      axisLine={false}
                      tickLine={false}
                      label={{
                        dy: 50,
                        offset: 40,
                        value: 'Anomaly Severity',
                        angle: -90,
                        position: 'insideLeft',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    />
                    <Tooltip
                      labelFormatter={(val: number) => formatDate(val)}
                    />
                    {getDesiredAnomalyType(props.equipmentType).map((type) => (
                      <Line
                        isAnimationActive={false}
                        name={AnomalySeverityPrettyNames[type]}
                        key={type}
                        yAxisId="right"
                        type="linear"
                        dot={false}
                        dataKey={type}
                        stroke={getColorByAnomalyType(type)}
                        strokeWidth={2}
                        hide={
                          !isAnomalyOutputEnabled(chartState, type) ||
                          getSeverityScoreFromState(chartState, type) === null
                        }
                      />
                    ))}
                    <Legend wrapperStyle={{ paddingTop: '10px' }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
            <Box className={classes.controlsContainer}>
              <ShowAlertContainer>
                <Checkbox
                  aria-label={'alertBand-checkbox'}
                  defaultChecked
                  disabled={!gridThresholdPoints.length}
                  color="default"
                  onChange={(e, checked) => {
                    setHideGrid(!checked)
                  }}
                />
                Show alert bands
                <TooltipMaterial
                  title={
                    'To avoid unnecessary noise, the CBM application uses band based alerting. Health alerts are generated based on a number of rules and the movement of the ‘Overall Anomaly Severity’ through these band ranges.'
                  }
                >
                  <ToolTipSpan>
                    <QuestionMarkIcon style={{ height: '18px' }} />
                  </ToolTipSpan>
                </TooltipMaterial>
              </ShowAlertContainer>
              <div>
                <label className={classes.timeRangeLabel}>Time Range:</label>
                <TimeRangeContainer>
                  <Select
                    style={{ width: '6rem' }}
                    native
                    variant="standard"
                    onChange={(e) => {
                      const selected = parseInt(e.target.value as string)
                      const timeRange = timeRangeCandidates.find(
                        (x) => x.noOfMonths === selected
                      )!
                      setTimeRange(timeRange)
                      e.preventDefault()
                    }}
                  >
                    {timeRangeCandidates.map((x) => (
                      <option value={x.noOfMonths} key={x.noOfMonths}>
                        {x.displayLabel}
                      </option>
                    ))}
                  </Select>
                </TimeRangeContainer>
              </div>
            </Box>
            <TableContainer className={classes.tableContainer}>
              {isLoading ? (
                <CircularProgress style={{ margin: '1rem' }} />
              ) : (
                <Table aria-label="floc select">
                  <TableHead>
                    <TableRow>
                      <TableCellHead>Analytics Model Label</TableCellHead>
                      <TooltipMaterial
                        arrow={true}
                        enterDelay={1000}
                        placement={'top'}
                        title={
                          'This number is the output of machine learning analytics. It is calculated using sensor and statistical equipment data and should be used as an indication of potential equipment faults.'
                        }
                      >
                        <TableCellHead padding={'none'}>
                          Severity Score (0-1)
                        </TableCellHead>
                      </TooltipMaterial>
                      <TableCellHead padding={'none'}>
                        Severity Band
                      </TableCellHead>
                      <TableCellHead align={'center'}>
                        Show on Graph
                      </TableCellHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getDesiredAnomalyType(props.equipmentType).map(
                      (type, index) => {
                        if (
                          getSeverityScoreFromState(chartState, type) !== null
                        ) {
                          return (
                            <React.Fragment key={index}>
                              <TableRow>
                                <TableCell width={'25%'} padding={'none'}>
                                  <AnalyticsModelLabelCellDiv
                                    color={getColorByAnomalyType(type)}
                                  >
                                    <div id="colorBox" />{' '}
                                    {AnomalySeverityPrettyNames[type]}
                                  </AnalyticsModelLabelCellDiv>
                                </TableCell>
                                <TableCell padding={'none'}>
                                  {getSeverityScoreFromState(
                                    chartState,
                                    type
                                  ) ?? 'Unknown'}
                                </TableCell>
                                <TableCell padding={'none'}>
                                  <AnalyticsModelLabelCellDiv
                                    color={
                                      getBandingColor(
                                        thresholds,
                                        getSeverityScoreFromState(
                                          chartState,
                                          type
                                        )
                                      )[0]
                                    }
                                  >
                                    <div id="colorBoxRound" />{' '}
                                    {
                                      getBandingColor(
                                        thresholds,
                                        getSeverityScoreFromState(
                                          chartState,
                                          type
                                        )
                                      )[1]
                                    }
                                  </AnalyticsModelLabelCellDiv>
                                </TableCell>
                                <TableCell padding={'none'} align={'center'}>
                                  <Checkbox
                                    aria-label={type + '-checkbox'}
                                    defaultChecked
                                    color="secondary"
                                    disabled={
                                      getSeverityScoreFromState(
                                        chartState,
                                        type
                                      ) === null
                                    }
                                    onChange={(e, checked) => {
                                      setChartState(
                                        changeGraphAnomalyState(
                                          chartState,
                                          type,
                                          checked
                                        )
                                      )
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          )
                        } else {
                          return null
                        }
                      }
                    )}
                  </TableBody>
                </Table>
              )}
              <br />
            </TableContainer>
          </>
        )}
      </div>
    </div>
  )
}

export default SeverityHistory

import styled from '@emotion/styled'
import React from 'react'
import { Box } from '@mui/material'

import { getBandingColor } from '../utility'

interface SeverityBarProps {
  severity: number // a number between 0 and 1
  disabledColor?: boolean // show grey instead
  textOnly?: string // show string text, ignore severity
  severityThresholds?: number[] // if exist, override default thresholds
}

const Root = styled(Box)`
  height: 10px;
  width: 100%;
  background-color: #e9ecef;
  border-radius: 40px;
  margin-bottom: 5px;
`

const SeverityBar = ({
  severity,
  disabledColor,
  textOnly,
  severityThresholds,
}: SeverityBarProps) => {
  // if it's disbaled. then color should be #E9ECEF as background, #B2B2B4 as ColorPath
  const getColorProp = (severity: number, disabledColor?: boolean) => {
    const severity2Dec = severity.toFixed(2)
    if (!textOnly) {
      const colorAndText = disabledColor
        ? '#B2B2B4'
        : getBandingColor(severityThresholds, severity)
      return [colorAndText[0], `${colorAndText[1]} (${severity2Dec})`]
    }
    return [disabledColor ? '#B2B2B4' : '#FF5722', textOnly, 'white']
  }

  const MarkedBar = styled(Box)`
    height: 10px;
    width: ${(props: any) =>
      // eslint-disable-next-line react/prop-types
      props['data-textonly'] ? '100%' : `${severity * 100}%`};
    background-color: ${getColorProp(severity, disabledColor)[0]};
    border-radius: 40px;
    text-align: 'right';
  `

  const CenteredBox = styled(Box)`
    display: flex;
    align-items: center;
    font-weight: 500;
  `

  return (
    <div>
      <Root>
        <MarkedBar data-textonly={textOnly} />
      </Root>
      <CenteredBox>
        <span>
          {textOnly ? textOnly : getColorProp(severity, disabledColor)[1]}
        </span>
      </CenteredBox>
    </div>
  )
}

export default SeverityBar

//UI definition of Equipment Type
export enum EquipmentType {
  'fan' = 'fan',
  'filter' = 'filter',
  'pump' = 'pump',
  'esd' = 'esd',
}

interface EquipmentTypeMetaData {
  name: string
}

export const equipmentTypeMetaData: Record<
  EquipmentType,
  EquipmentTypeMetaData
> = {
  fan: { name: 'Fin Fan' },
  filter: { name: 'Filter' },
  pump: { name: 'Pump' },
  esd: { name: 'ESD' },
}

export const equipmentTypeFilterData: Record<
  'fan' | 'pump' | 'all' | 'esd',
  EquipmentTypeMetaData
> = {
  all: { name: 'All Equipment' },
  fan: { name: 'Fin Fan' },
  // filter: { name: 'Filter' },
  pump: { name: 'Pump' },
  esd: { name: 'ESD' },
}

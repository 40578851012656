import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'
import { Box } from '@mui/material'

import { WOSYSStateMapping } from '../../../../shared/types/maintenanceJob'
import useCBMWOStatus from './hooks/ussCBMWOStatus'

export interface WOStatusProps {
  floc: string
  WONumber?: string
}

const placeHolderShimmer = keyframes`
 0% { height: 100px; width: 100px; }
 30% { height: 400px; width: 400px; opacity: 1 }
 40% { height: 405px; width: 405px; opacity: 0.3; }
 100% { height: 100px; width: 100px; opacity: 0.6; }
`

const PlaceHolderText = styled(Box)`
  background: #eee;
  animation-duration: 1.1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 1rem;
  position: relative;
`

const WOStatus = (props: WOStatusProps) => {
  const { status, data, error, isLoading } = useCBMWOStatus(
    props.floc,
    props.WONumber
  )
  return (
    <span>
      {isLoading ? (
        <PlaceHolderText />
      ) : (
        <>
          {data && !error
            ? WOSYSStateMapping[data.workOrderCurrentSystemStatusCode]
            : 'Not Available'}
        </>
      )}
    </span>
  )
}

export default WOStatus

import { useQuery } from 'react-query'
import { Eq360GraphFetch } from '../../../../../../shared/auth'
import { ALSResponse } from './ALSType'
import { forkJoin } from 'rxjs'
import { useMsal } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'
import { getEquipmentALSReport } from '../../../../../../services/graphql/EQ360Queries'

const fetchALSReportByFloc = (
  msalInstance: IPublicClientApplication,
  floc: string
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) =>
    Eq360GraphFetch(
      msalInstance,
      import.meta.env.VITE_EQ360_ID!,
      fetch,
      import.meta.env.VITE_EQ360_HOST!,
      getEquipmentALSReport(floc)
    )
      .then(async (r) => {
        if (r.getEquipment) {
          resolve(r.getEquipment.alsReports)
        } else {
          reject(r)
        }
      })
      .catch((e) => {
        reject(e)
      })
  )

export async function fetchALSDataFromDataLake(
  msalInstance: IPublicClientApplication,
  flocs: string[]
): Promise<ALSResponse[]> {
  // for local testing

  const response = await forkJoin(
    flocs.map((floc) => fetchALSReportByFloc(msalInstance, floc))
  ).toPromise()
  return [].concat.apply([], response)
}

const useALSData = (flocs: string[]) => {
  const { instance } = useMsal()
  return useQuery(
    ['pump-oil', flocs],
    () => fetchALSDataFromDataLake(instance, flocs),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 30 * 1000 * 60, // 30min stale time,
      enabled: Boolean(flocs.length),
    }
  )
}

export default useALSData

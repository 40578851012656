import React, { memo, useState } from 'react'
import styled from '@emotion/styled'
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { Divider } from 'antd'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import CalenderIcon from '../../../../icons/calender.svg?react'
import TriangleIcon from '../../../../icons/exclamation-triangle-filled.svg?react'
import HelpTooltip from '../../../../shared/components/tooltip-help'
import MaintenanceInsightsTable from './insights-table'
import { GlobalDateFormatter } from '../../../../shared/utility'
import DiagnosisResponseType, {
  UIInternalDiagnosisStateType,
} from '../../../../shared/types/diagnosis'
import { PaddedCard } from '../../../../shared/components/padded-card'

const FlexContentContainer = styled(Box)`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`

const RULHelper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  gap: 0.5rem;
  #riskEndDateSpan {
    margin-right: 0.5rem;
    opacity: 0.7;
  }
  div {
    display: flex;
  }
`

const TextPairLarge = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: fit-content;
`

const FilterDiv = styled(Box)`
  width: 100%;
  display: flex;
  div:first-of-type {
    margin: 0 2rem 0 0;
    display: flex;
    align-items: center;
    p {
      font-size: 1rem;
      margin: 0;
    }
  }
  .MuiRadio-root {
    color: #4d4d4f;
  }
`

const TooltipSpanV2 = styled.span`
  position: relative; /* making the .tooltip span a container for the tooltip text */
  border-bottom: 1px dashed #000; /* little indicater to indicate it's hoverable */
  &:hover {
    border-bottom-color: rgba(0, 0, 0, 0.5);
    cursor: ${(props: any) => (props['data-clickable'] ? 'pointer' : '')};
  }
  &:before {
    content: attr(data-text); /* here's the magic */
    position: absolute;

    /* vertically center */
    top: 50%;
    transform: translateY(-50%);

    /* move to right */
    left: 100%;
    margin-left: 10px; /* and add a small left margin */

    /* basic styles */
    width: 250px;
    padding: 0.5rem;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0.95;
    color: #fff;
    text-align: center;
    font-size: 0.7rem;
    display: none;
  }
  &:hover:before {
    display: block;
    z-index: 1000;
  }
`

const OptionalSpan = styled.span`
  display: inline;
  color: rgba(0, 0, 0, 0.6);
`

const SubTitle = styled(Box)`
  display: flex;
  flex: 1 0 100%;
`

interface PlanningCardProps {
  diagnosisState: UIInternalDiagnosisStateType
  stateChangeCallback: (newData: UIInternalDiagnosisStateType) => void
}

const PlanningCard = memo((props: PlanningCardProps) => {
  const { diagnosisState: state, stateChangeCallback: setState } = props
  const [selectedExeDate, setSelectedExeDate] = useState<Date | null>(null)

  const [filters, setFilters] = useState({
    pmco: true,
    pmrc: true,
  })

  const handleReasonInput = (e: any) => {
    setState({
      ...props.diagnosisState,
      diagnosisResponseType: new DiagnosisResponseType({
        ...props.diagnosisState.diagnosisResponseType,
        additionalComments: e.target.value,
      }),
    })
  }

  const handleDateChange = (date: Date) => {
    setSelectedExeDate(date)
    if (moment(date).isValid()) {
      setState({
        ...props.diagnosisState,
        diagnosisResponseType: new DiagnosisResponseType({
          ...props.diagnosisState.diagnosisResponseType,
          proposedExeDate: date.toISOString(),
        }),
      })
    } else {
      setState({
        ...props.diagnosisState,
        diagnosisResponseType: new DiagnosisResponseType({
          ...props.diagnosisState.diagnosisResponseType,
          proposedExeDate: null,
        }),
      })
    }
  }

  return (
    <PaddedCard>
      <Typography variant="h2">
        <CalenderIcon
          style={{
            marginRight: '.5rem',
            marginBottom: '.5rem',
            height: '1rem',
          }}
        />
        Maintenance Planning
      </Typography>
      <Typography variant="subtitle1">Upcoming Maintenance Timeline</Typography>
      <FilterDiv>
        <Box>View upcoming:</Box>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.pmco}
                color="default"
                onChange={() => {
                  setFilters({ ...filters, pmco: !filters.pmco })
                }}
                name="PMCO"
              />
            }
            label="Corrective Maintenance (PMCO)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.pmrc}
                color="default"
                onChange={() => {
                  setFilters({ ...filters, pmrc: !filters.pmrc })
                }}
                name="PMRC"
              />
            }
            label="Recurring Maintenance (PMRC)"
          />
        </FormGroup>
      </FilterDiv>
      <MaintenanceInsightsTable filters={filters} />
      <Divider />
      <FlexContentContainer>
        <SubTitle>
          <Typography variant="subtitle1">
            Propose a Maintenance Execution Date
          </Typography>
          <HelpTooltip title="Based on your diagnosis and equipment information provided, propose an ideal maintenance execution date. This date will be reviewed and confirmed by Maintenance before being scheduled." />
        </SubTitle>
        <Box data-testid="proposedExecutionDate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              renderInput={(params) => <TextField {...params} />}
              inputFormat="dd/MM/yyyy"
              disablePast={true}
              value={selectedExeDate}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
        </Box>
        {!state.diagnosisResponseType.equipmentAlreadyBrokeFlg &&
          state.diagnosisResponseType.estimatedRiskEndDate && (
            <RULHelper>
              <Box>
                <Box component="span" id="riskEndDateSpan">
                  Estimated Remaining Useful Life:{' '}
                </Box>
                <TooltipSpanV2 data-text="The estimated remaining useful life is based on the answer you provided in the diagnosis section above.">
                  {moment(
                    state.diagnosisResponseType.estimatedRiskEndDate
                  ).diff(moment(), 'days') + 1}{' '}
                  days
                </TooltipSpanV2>
              </Box>
              <Box>
                <Box component="span" id="riskEndDateSpan">
                  Risk End Date:{' '}
                </Box>
                <TooltipSpanV2
                  data-clickable={true}
                  onClick={() => {
                    handleDateChange(
                      moment(
                        state.diagnosisResponseType.estimatedRiskEndDate
                      ).toDate()
                    )
                  }}
                  data-text="The risk end date is calculated by taking todays date and adding the estimated remaining useful life. This should be used as an indication of the latest that maintenance should commence by. Click to use this date."
                >
                  {GlobalDateFormatter.format(
                    moment(
                      state.diagnosisResponseType.estimatedRiskEndDate
                    ).toDate()
                  )}{' '}
                  <TriangleIcon style={{ height: '1rem', color: '#FF9800' }} />
                </TooltipSpanV2>
              </Box>
            </RULHelper>
          )}
        <TextPairLarge>
          <Typography variant="subtitle1">
            Additional Comments: <OptionalSpan> (Optional)</OptionalSpan>
          </Typography>
          <TextField
            style={{ width: '30rem', marginTop: '1rem' }}
            onChange={handleReasonInput}
            value={
              state.diagnosisResponseType.additionalComments
                ? state.diagnosisResponseType.additionalComments
                : ''
            }
            inputProps={{ maxLength: 200 }}
            helperText={`${
              state.diagnosisResponseType.additionalComments
                ? state.diagnosisResponseType.additionalComments.length
                : 0
            }/200`}
            placeholder={'e.g. aligning work with upcoming corrective…'}
            variant="standard"
          />
        </TextPairLarge>
      </FlexContentContainer>
    </PaddedCard>
  )
})

export default PlanningCard

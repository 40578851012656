import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

import { DiagnosedAlert } from '../../../../shared/types/alert'
import emptyImg from '../../../../images/app-noalerts.png'
import { useWindowSize } from '../../../anomaly-review/context-panel/floc-panel/useWindowResize'
import ActionedTable from './actioned-table'

// index == 1, means resolved/cancelled, dont show any actions, show status instead
interface ResolvedTabProps {
  input: DiagnosedAlert[]
  value: number
  index: number
}

const EmptyDiv = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  width: 13rem;
  height: 11rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(${emptyImg});
  margin-bottom: 1rem;
`

const EmptyBox = styled(Box)`
  height: 60vh;
  display: grid;
  place-items: center;
`

const ActionedDiv = styled(Box)`
  padding: 1rem 0rem;
  flex: 1 1 100%;
  gap: 0.6rem;
  display: flex;
  flex-direction: column;
`

const Root = styled(Box)`
  flex: 1 1 100%;
  display: contents;
`

const ResolvedTab = ({ input, value, index, ...other }: ResolvedTabProps) => {
  const actionedBackgroundDiv = useRef<HTMLDivElement | null>(null)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(8)
  const size = useWindowSize()
  const RowHeightApprox = 80

  React.useMemo(() => {
    // so that page reset when external filter changes
    setCurrentPage(1)
  }, [input])

  const EmptyCard = () => {
    return (
      <EmptyBox>
        <div>
          <EmptyDiv />
          <p>
            Looks like there are no actioned alerts for this type of equipment.
            <br /> We will let you know if something pops up!
          </p>
        </div>
      </EmptyBox>
    )
  }

  React.useMemo(() => {
    if (actionedBackgroundDiv && actionedBackgroundDiv.current) {
      const totalSpaceinPixiel =
        actionedBackgroundDiv.current!.getBoundingClientRect().height
      if (totalSpaceinPixiel) {
        setRowsPerPage(Math.ceil(totalSpaceinPixiel / RowHeightApprox))
      }
    }
  }, [actionedBackgroundDiv, size])

  return (
    <Root
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <ActionedDiv ref={actionedBackgroundDiv}>
        {input && input.length ? (
          <>
            <ActionedTable diagnosedAlerts={input} />
          </>
        ) : (
          <EmptyCard />
        )}
      </ActionedDiv>
    </Root>
  )
}

export default ResolvedTab

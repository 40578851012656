import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { apm } from '@elastic/apm-rum'
import { useQuery } from 'react-query'
import { getItemLatestMeasurementsQuery } from '../../../../services/graphql/EQ360Queries'
import { Eq360GraphFetch } from '../../../../shared/auth'
import { ItemLatestMeasurementsResponse } from '../../../../shared/types/getOverviewResponse'

export async function fetchSADataByFinFanDeckFloc(
  msalInstance: IPublicClientApplication,
  floc: string,
  depth: number
): Promise<ItemLatestMeasurementsResponse> {
  const transaction = apm.startTransaction('useSA', 'EQ360')!
  // the following are standardized across all Elastic APM agents: app, db, cache, template, and ext.
  const httpSpan = transaction.startSpan(
    `POST ${import.meta.env.VITE_EQ360_HOST!}`,
    'app'
  )!
  transaction.addLabels({ floc: floc, depth: depth })

  return new Promise((resolve, reject) =>
    Eq360GraphFetch(
      msalInstance,
      import.meta.env.VITE_EQ360_ID!,
      fetch,
      import.meta.env.VITE_EQ360_HOST!,
      getItemLatestMeasurementsQuery(floc, depth)
    )
      .then((response) => {
        if (response.getItemLatestMeasurements) {
          resolve(
            response.getItemLatestMeasurements as ItemLatestMeasurementsResponse
          )
        } else {
          apm.captureError(
            new Error(
              `get getItemLatestMeasurements is not in the response for floc ${floc}.`
            )
          )
          reject('get getItemLatestMeasurements is not in the response.')
        }
      })
      .catch((e) => {
        let errorMessage = e
        if (e.length) {
          errorMessage = e[0].message
        }
        apm.captureError(
          new Error(`SA data fetch failed with error: ${errorMessage}`)
        )
        reject(e)
      })
      .finally(() => {
        httpSpan.end()
        transaction.end()
      })
  )
}

const useSAData = (floc: string, depth: number) => {
  const { instance: msalInstance } = useMsal()

  return useQuery(
    ['SA', floc, depth],
    () => fetchSADataByFinFanDeckFloc(msalInstance, floc, depth),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 30 * 1000 * 60, // 30min stale time,
      enabled: floc ? true : false,
    }
  )
}
export default useSAData

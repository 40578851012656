import styled from '@emotion/styled'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React from 'react'

import SealIcon from '../../../../../icons/seal.svg?react'
import SealOverViewTab from './overview'

const AccordionSummaryStyled = styled(AccordionSummary)`
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`
const Heading = styled(Box)`
  display: flex;
  place-content: space-between;
  width: 100%;
  #status {
    display: flex;
    flex-direction: row;
    place-items: center !important;
    width: 15%;
    min-width: 10rem;
    gap: 0.5rem;
    align-items: flex-start;
    #severity {
      width: 80%;
    }
  }
`
const SealCard = () => {
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummaryStyled
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="content"
        id="header"
      >
        <Heading>
          <h3>
            <SealIcon style={{ marginRight: '.5rem', height: '1rem' }} />
            Seal Monitoring
          </h3>
        </Heading>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <SealOverViewTab />
      </AccordionDetails>
    </Accordion>
  )
}
export default SealCard

/* eslint-disable no-irregular-whitespace */
import moment from 'moment'
import { AnomalyOutputType } from '../../shared/types/getEquipmentResponse'
import { getEq360PlantFromFloc } from '../../shared/types/plants'

/**
 * https://confluence.woodside.com.au/display/IEP2/Equipment360+Operations
 * Schema: https://github.com/WET-IntelligentSystems/IntelligentAssets-Equipment360/blob/master/schema.graphql
 */
export function getEquipmentOverviewQueryWithMeasurement(
  itemId: string,
  aggregationType: 'SUM',
  timeBase: 'HOUR' | 'DAY' | 'MIN',
  interval: number, // aggregation intervel
  startTime: number
): string {
  return `query getOverviewQuery {
        getOverview(itemId: "${itemId}",
        aggregationType: ${aggregationType},
        timebase: ${timeBase},
        interval: ${interval},
        startTimestamp: ${startTime},
        assetId: "${getEq360PlantFromFloc(itemId)}") {
          equipmentId
          equipmentTag
          type
          equipmentType
          FLOC
          measurements {
            key
            name
            source
            id
            attributes {
              name
              value
            }
            values {
              value
              timestamp
            }
            type
            unitOfMeasure
          }
          attributes {
            name
            key
            value
          }
        }
      }`
}

export function getItemLatestMeasurementsQuery(
  itemId: string,
  depth: number
): string {
  return `query getItemLatestMeasurementsQuery {
    getItemLatestMeasurements(itemId: "${itemId}", depth: ${depth}, assetId: "${getEq360PlantFromFloc(
      itemId
    )}") {
      parentEquipmentTagname
      equipmentTagname
      floc
      measurements {
        id
        key
        name
        source
        values {
          value
          timestamp
        }   
      }
      children {
        parentEquipmentTagname
        equipmentTagname
        floc
        measurements {
          id
          key
          name
          source
          values {
            value
            timestamp
          }
        }
        children {
          parentEquipmentTagname
          equipmentTagname
          floc
          measurements {
            id
            key
            name
            source
            values {
              value
              timestamp
            }
          }
          children {
            parentEquipmentTagname
            equipmentTagname
            floc
            measurements {
              id
              key
              name
              source
              values {
                value
                timestamp
              }
            }
            children {
              parentEquipmentTagname
              equipmentTagname
              floc
              measurements {
                id
                key
                name
                source
                values {
                  value
                  timestamp
                }
              }
            }
          }
        }
      }
    }
  }`
}

export function getEquipmentOverviewQuery(
  itemId: string,
  timestamp: number
): string {
  return `
  query getOverviewQuery {
    getOverview(itemId: "${itemId}",timestamp: ${timestamp}, assetId: "${getEq360PlantFromFloc(itemId)}") {
      equipmentId
      equipmentTag
      type
      equipmentType
      FLOC
      attributes {
        name
        key
        value
      }
    }
  }`
}

export function getEquipmentMeasurementsWithoutValue(
  itemId: string,
  endTime: number,
  startTime: number
): string {
  return `query getOverviewQuery {
    getOverview(itemId: "${itemId}",timestamp: ${endTime},startTimestamp:${startTime}, assetId: "${getEq360PlantFromFloc(
      itemId
    )}") {
      equipmentId
      equipmentTag
      type
      equipmentType
      FLOC
      measurements {
        key
        name
        source
        id
        type
        unitOfMeasure
      }
    }
  }`
}

export function getMultiEquipmentMaintenanceQuery(flocs: string[]): string {
  return `query getMaintenanceData {
    ${flocs.map((floc, index) => {
      return equipmentMaintenanceQuery(floc, index)
    })}
  }`
}

function equipmentMaintenanceQuery(floc: string, index: number): string {
  return `
    q${index}: getMaintenanceData(floc: "${floc}", assetId: "${getEq360PlantFromFloc(
      floc
    )}") {
    notificationShortText
    notificationLongText
    notificationNumber
    notificationCurrentSystemStatusCode
    notificationCurrentSystemStatusSequenceNumber
    notificationCurrentUserStatusCode
    notificationCurrentUserStatusSequenceNumber
    notificationCreatedDate
    notificationPriorityIndicator
    notificationPriorityTypeCode
    workOrderCreatedDate
    workOrderNumber
    workOrderText
    workOrderTypeText
    workOrderMaxConfirmationDate
    workOrderBasicEndDate
    workOrderBasicStartDate
    workOrderCurrentSystemStatusCode
    workOrderCurrentUserStatusCode
  }`
}

export function getEquipmentALSReport(floc: string): string {
  return `
  query getOverviewQuery {
    getEquipment(itemId: "${floc}", assetId: "${getEq360PlantFromFloc(floc)}") {​​​​​​​​​ 
      id
      FLOC
      alsReports {
        company_name
        date_sampled
        severity
        compartment_name
        compartment_type
        compartment_category
        last_update_date
        unit_id
        sample_fluid
        sample_fluid_grade
        sample_fluid_manufacturer
        compartment_capacity
        compartment_capacity_uom
        problem_code
        sample_report_url
        diagnosis
      }
    }
  }`
}

export function getEquipmentOPAM(
  floc: string,
  fromDate: Date,
  toDate: Date,
  exactMatch = false
): string {
  return `query getOpamQuery {
    getEquipment(itemId:  "${floc}", assetId: "${getEq360PlantFromFloc(
      floc
    )}") { 
      id
      FLOC
      pamTasks(fromDate: "${moment(fromDate.toLocaleDateString('en-CA')).format(
        'YYYY-MM-DD'
      )}", toDate: "${moment(toDate.toLocaleDateString('en-CA')).format(
        'YYYY-MM-DD'
      )}", exactMatch: ${exactMatch}) {
        equipment
        id
        facilityName
        areaName
        locationName
        responsibleArea
        description
        detail
        purpose
        consequences
        operatorAction
        workInstruction
        resultValueList
        unitOfMeasure
        limitLow
        limitHigh
        targetValue
        targetRange
        isDisabled
        results { 
          result
          comments
          recordedFor
          recordedBy
          recordedDate
        }
      } 
    }
  }`
}

export function getEquipmentHistoryQuerySmall(floc: string): string {
  return `query getMaintenanceData {
    getMaintenanceData(floc: "${floc}", assetId: "${getEq360PlantFromFloc(
      floc
    )}") {
      workOrderCurrentSystemStatusCode
      workOrderCurrentUserStatusCode
      workOrderNumber
    }
  }`
}

export function getEquipmentHierarchy(floc: string, asset?: string): string {
  return `query getEquipmentHierarchy {
    getEquipmentHierarchyV2(itemId: "${floc}", depth: 3, assetId: "${
      asset ?? getEq360PlantFromFloc(floc)
    }") {
      floc
      parentEquipmentTagname
      parentFloc
      equipmentTagname
      attributes {
          service
      }
      children {
        floc
        parentFloc
        equipmentTagname
        parentEquipmentTagname
        attributes {
            service
        }
        children {
          floc
          parentFloc
          equipmentTagname
          parentEquipmentTagname
          attributes {
              service
          }
          children {                    
            floc
            parentFloc
            equipmentTagname
            parentEquipmentTagname
            attributes {
                service
            }
            children {
              floc
              parentFloc
              equipmentTagname
              parentEquipmentTagname
              attributes {
                  service
              }
              children {
                floc
                parentFloc
                equipmentTagname
                parentEquipmentTagname
                attributes {
                    service
                }
                children {
                  floc
                  parentFloc
                  equipmentTagname
                  parentEquipmentTagname
                  attributes {
                      service
                  }
                }    
              }    
            }
          }
        }
      }
    }
  }`
}

export function getTSData(
  phdTag: string,
  timeBase: 'HOUR' | 'DAY' | 'MIN',
  interval: number,
  startTime: number,
  endtime: number,
  floc: string,
  aggregationType?: 'SUM' | 'AVG'
): string {
  return `query TimeseriesData {​​​​​​​
    getTimeseriesData(
      tags: ["${phdTag}"], 
      timebase: ${timeBase}, 
      interval: ${interval}, 
      ${aggregationType ? 'aggregationType:' + aggregationType : ''},
      startTimestamp:${startTime},
      timestamp: ${endtime},
      assetId: "${getEq360PlantFromFloc(floc)}"
    ) {​​​​​​​
      name
      value
      time
    }​​​​​​​
  }​​​​​​​`
}

export function getEQ360EquipmentAnomalyModelOutputsQuery(
  itemId: string,
  measureName: AnomalyOutputType[],
  from: number,
  to: number
): string {
  const names = measureName.join(' ')
  return `query GetOutputQuery {
    getEquipment(itemId: "${itemId}", assetId: "${getEq360PlantFromFloc(
      itemId
    )}") {
      id 
      requesterId 
      equipmentId 
      FLOC 
      equipmentType 
      anomalyModelOutputs(from: ${from}, to: ${to},measureNames:[${names}]) {
        measureName 
        confidence 
        value 
        type 
        time
      }
    }
  }`
}

import moment from 'moment'
import AlertTypeMappingRecord from '../components/alert-metadata'
import {
  getUIEquipmentTypeFromFuseEquipmentType,
  GlobalDateFormatter,
  GlobalTimeFormatter,
  GlobalTimeFormatterHHMM,
  sortByObjectProperty,
} from '../utility'
import { EquipmentType } from './equipmentTypes'
import { convertFlocToPlantName, Plant } from './plants'
import { SortableEntity, SortDir, SortKeyEnum } from './sortableEntity'

// all types of alert

export enum AlertTypes {
  'FinFanVibration' = 'FinFanVibration',
  'FinFanLowLoad' = 'FinFanLowLoad',
  'FilterPressure' = 'FilterPressure',
  'AdHocRequest' = 'AdHocRequest',
  'PumpModel' = 'PumpModel',
  'ESDN2Model' = 'ESDN2Model',
  'ESDCycleModel' = 'ESDCycleModel',
  'ESDTempModel' = 'ESDTempModel',
}

// TODO: merge below with above
// map response to UI types
enum MLPlatformEventTypes {
  'fanvibration' = 'AnomalyModelOutput-Vibration',
  'fanlowload' = 'UkniteeModelOutput-LowLoad',
  'filterpressure' = 'FilterEvent-Pressure',
  'pumpmodel' = 'AnomalyModelOutput-Pump',
  'adhoc' = 'AdhocAnomalyDiagnosis',
  'esdn2' = 'UkniteeModelOutput-ESDSkidN2',
  'esdtemp' = 'UkniteeModelOutput-ESDSkidHydTemp',
  'esdcycle' = 'UkniteeModelOutput-ESDSkidCycle',
}
// mapper of events
const EventToAlertTypeMapping = (responseEvent: string): AlertTypes => {
  switch (responseEvent) {
    case MLPlatformEventTypes.fanvibration: {
      return AlertTypes.FinFanVibration
      break
    }
    case MLPlatformEventTypes.fanlowload: {
      return AlertTypes.FinFanLowLoad
      break
    }
    case MLPlatformEventTypes.filterpressure: {
      return AlertTypes.FilterPressure
      break
    }
    case MLPlatformEventTypes.adhoc: {
      return AlertTypes.AdHocRequest
      break
    }
    case MLPlatformEventTypes.pumpmodel: {
      return AlertTypes.PumpModel
    }
    case MLPlatformEventTypes.esdn2: {
      return AlertTypes.ESDN2Model
    }
    case MLPlatformEventTypes.esdcycle: {
      return AlertTypes.ESDCycleModel
    }
    case MLPlatformEventTypes.esdtemp: {
      return AlertTypes.ESDTempModel
    }
    default: {
      return AlertTypes.FinFanVibration // for default
    }
  }
}

/// BELOW CODE FOR ACTIVE
export class AlertDetail {
  dateRaised: string
  timeRaised?: string
  alertBandingUpperLimits: number[]
  floc: string
  equipmentType: EquipmentType
  alertType: AlertTypes // pending backend for filter
  state: string
  id?: string
  key: string
  severity: number
  timestampDateRaised: Date
  lastUpdatedDate: Date // not been used in UI anywhere else
  get plantName(): Plant {
    return convertFlocToPlantName(this.floc)
  }
  constructor(response: AlertListResponse) {
    // 2020-08-04 16:42:49.914135
    this.key = response.key
    let timeCreatedObject
    if (response.created_at) {
      timeCreatedObject = moment.utc(response.created_at).toDate()
    } else {
      timeCreatedObject = moment.utc(response.modelEndTime).toDate()
    }
    const dateFormatter = GlobalDateFormatter
    this.dateRaised = dateFormatter.format(timeCreatedObject)

    const timeFormatter = GlobalTimeFormatterHHMM
    this.timeRaised = timeFormatter.format(timeCreatedObject)

    this.floc = response.drivenFloc
    this.alertType = EventToAlertTypeMapping(response.eventType)
    this.equipmentType = getUIEquipmentTypeFromFuseEquipmentType(
      response.fuseEquipmentType
    )
    this.timestampDateRaised = timeCreatedObject
    this.state = response.state
    this.id = response.id
    this.severity = parseFloat(
      parseFloat(response.output__equipFaultSeverity).toFixed(2)
    )
    this.lastUpdatedDate = moment.utc(response.updated_at).toDate()
    this.alertBandingUpperLimits = response.alertBandingUpperLimits
  }
}

export interface AlertListResponse {
  modelEndTime: string
  eventType: string
  created_at: string
  alertBandingUpperLimits: number[]
  drivenFloc: string
  equipmentType: string
  fuseEquipmentType: string
  updated_at: string
  state: string
  id?: string
  key: string
  output__equipFaultSeverity: string
}
export function convertToAlerts(response: AlertListResponse[]): AlertDetail[] {
  if (!response) {
    return [] as AlertDetail[]
  }
  const alertDetails: AlertDetail[] = []
  response.forEach((alertResponse) => {
    alertDetails.push(new AlertDetail(alertResponse))
  })
  return alertDetails.sort((a, b) =>
    a.timestampDateRaised < b.timestampDateRaised ? 1 : -1
  )
}

export interface SnoozeFeedback {
  cancelledBy: string
  cancelReason: string
  comments: string
  surrondingEquipmentFeedback: {
    inteferenceObserved: boolean
    processRelated: boolean
    recentMaintenance: boolean
  }
}

export class CancelledAlert {
  get cancelReason() {
    return this.cancellationFeedback?.cancelReason ?? ''
  }
  get additionalComments() {
    return this.cancellationFeedback?.comments ?? ''
  }
  cancellationFeedback?: SnoozeFeedback
  lastUpdated: string
  eventType: string
  equipmentType: EquipmentType
  alertType: AlertTypes
  floc: string
  modelEndTime: Date
  equipFaultSeverity: number
  state: string
  snoozedUntil: string
  snoozedUntilDate: Date
  id: string
  key: string
  lastUpdatedDate: Date
  get cancelledBy(): string {
    return this.cancellationFeedback?.cancelledBy ?? 'Unknown'
  }
  get alertTypeNiceName(): string {
    return AlertTypeMappingRecord[this.alertType].displayText
  }
  get plantName(): Plant {
    return convertFlocToPlantName(this.floc)
  }

  constructor(response: AlertSnoozedResponse) {
    this.key = response.key
    const dateFormatter = GlobalDateFormatter
    this.snoozedUntilDate = moment.utc(response.snoozed_until).toDate()
    this.snoozedUntil = dateFormatter.format(this.snoozedUntilDate)
    this.lastUpdatedDate = moment.utc(response.updated_at).toDate()
    this.lastUpdated = dateFormatter.format(this.lastUpdatedDate)
    this.alertType = EventToAlertTypeMapping(response.eventType)
    this.equipmentType = getUIEquipmentTypeFromFuseEquipmentType(
      response.fuseEquipmentType
    )

    this.floc = response.drivenFloc
    if (response.cancellationFeedback) {
      this.cancellationFeedback = JSON.parse(
        response.cancellationFeedback
      ) as SnoozeFeedback
    }
    this.eventType = response.eventType
    this.modelEndTime = moment.utc(response.modelEndTime).toDate()

    this.equipFaultSeverity = parseFloat(
      parseFloat(response.output__equipFaultSeverity).toFixed(2)
    )
    this.state = response.state

    this.id = response.id
  }
}

export function convertToSnoozedAlerts(
  response: AlertSnoozedResponse[]
): CancelledAlert[] {
  if (!response) {
    return [] as CancelledAlert[]
  }
  const snoozedAlert: CancelledAlert[] = []
  response.forEach((alertResponse) => {
    snoozedAlert.push(new CancelledAlert(alertResponse))
  })
  return snoozedAlert.sort((a, b) =>
    a.snoozedUntilDate < b.snoozedUntilDate ? 1 : -1
  )
}

export interface AlertSnoozedResponse {
  drivenFloc: string
  cancellationFeedback: string
  eventType: string
  fuseEquipmentType: string
  modelEndTime: string
  output__equipFaultSeverity: string
  snoozed_until: string
  state: string
  id: string
  key: string
  updated_at: string
}

export class DiagnosedAlert implements SortableEntity {
  dateResolved: string
  timeResolved: string
  failureModeSelectedResponse: string
  originalTimeStampResolvedDate: Date
  floc: string
  equipmentType: EquipmentType
  alertType: AlertTypes
  unit: string
  component: string
  permitID?: string
  failureMode: string
  resolvedBy: string
  workLongText: string
  id: string
  key: string
  isolationCerts?: string
  permitName?: string
  measurementDocNumber?: string
  notificationNumber?: string
  workOrderNumber?: string
  templateToApply?: string
  ownIsolation?: boolean
  get alertTypeNiceName(): string {
    return AlertTypeMappingRecord[this.alertType].displayText
  }
  get plantName(): Plant {
    return convertFlocToPlantName(this.floc)
  }

  getSortableField(sortKey: SortKeyEnum): any {
    switch (sortKey) {
      case SortKeyEnum.FLOC:
        return this.floc
      case SortKeyEnum.DATE:
        return this.originalTimeStampResolvedDate
      case SortKeyEnum.TYPE:
        return this.alertType
    }
  }

  constructor(response: AlertDiagnosedResponse) {
    this.key = response.key
    // 2020-08-04 16:42:49.914135
    // TODO, changed to created date
    let timeObject = null
    if (response.created_at) {
      timeObject = moment.utc(response.created_at).toDate()
    } else {
      timeObject = moment.utc(response.updated_at).toDate()
    }
    if (timeObject.toString() === 'Invalid Date') {
      console.error(`Invalid Updated_at Time for floc: ${response.drivenFloc}`)
    }
    this.originalTimeStampResolvedDate = timeObject
    const dateFormatter = GlobalDateFormatter
    const timeFormatter = GlobalTimeFormatter
    try {
      this.dateResolved = dateFormatter.format(
        this.originalTimeStampResolvedDate
      )
      this.timeResolved = timeFormatter.format(
        this.originalTimeStampResolvedDate
      )
    } catch (e) {
      console.error(e)
      this.dateResolved = 'Error'
      this.timeResolved = 'Error'
    }

    this.floc = response.drivenFloc
    this.alertType = EventToAlertTypeMapping(response.eventType)
    // for diagnoised, we can get it from response
    // add an check if it's adhoc,
    this.equipmentType = getUIEquipmentTypeFromFuseEquipmentType(
      response.fuseEquipmentType
    )
    this.component = response.component
    this.unit = response.equipmentType // from strategy db, backend named it equipmentType
    this.failureMode = response.failureMode
    this.resolvedBy = response.diagnosedBy
    this.workLongText = response.workLongText
    this.id = response.id
    this.failureModeSelectedResponse = response.response
    this.permitName = response.permitName
    this.isolationCerts = response.isolationCerts
    this.permitID = response.permitID
    this.measurementDocNumber = response.measurementDocNumber
    this.workOrderNumber = response.workOrderNumber
    this.notificationNumber = response.notificationNumber
    this.templateToApply = response.templateToApply
    this.ownIsolation = response.ownIsolation
  }
}

export function convertToDiagnosedAlerts(
  response: AlertDiagnosedResponse[]
): DiagnosedAlert[] {
  if (!response) {
    return [] as DiagnosedAlert[]
  }
  const diagnosedAlert: DiagnosedAlert[] = []
  response.forEach((alertResponse) => {
    diagnosedAlert.push(new DiagnosedAlert(alertResponse))
  })
  return diagnosedAlert.sort((a, b) =>
    a.originalTimeStampResolvedDate < b.originalTimeStampResolvedDate ? 1 : -1
  )
}

export interface AlertDiagnosedResponse {
  drivenFloc: string
  equipmentType: string
  component: string
  failureMode: string
  diagnosedBy: string
  created_at: string
  workLongText: string
  updated_at: string
  fuseEquipmentType: string
  id: string
  eventType: string
  key: string
  response: string //maintenance response in string
  measurementDocNumber: string
  permitName?: string // only there if success
  templateToApply?: string // what was submitted
  permitID?: string
  isolationCerts?: string // only there if success
  workOrderNumber?: string // only there if queue success
  notificationNumber?: string // only there is queue success
  ownIsolation: boolean // whether own isolation was used during submission
}

export function SortDiagnosedAlerts(
  flocAlerts: DiagnosedAlert[],
  sortKey: SortKeyEnum,
  sortDir: SortDir
) {
  return sortByObjectProperty(
    flocAlerts,
    sortKey,
    sortDir,
    sortKey === SortKeyEnum.FLOC
  )
}

import styled from '@emotion/styled'
import React, { useRef } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import Table from 'antd/lib/table'
import { AutoComplete, Button, Space } from 'antd'
import Highlighter from 'react-highlight-words'
import { Box } from '@mui/material'

import AlertTypeMappingRecord from '../../../../shared/components/alert-metadata'
import { CancelledAlert } from '../../../../shared/types/alert'
import { compareAlphaNumeric } from '../../../../shared/utility'

export interface CancelledTableProps {
  cancelledAlerts: CancelledAlert[]
}

const TitleIcon = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`

const ActionedTable = (props: CancelledTableProps) => {
  const [filterFlocs, setFilterFlocs] = React.useState<
    {
      text: string
      value: string
    }[]
  >()
  const [filterAlertType, setFilterAlertType] = React.useState<
    {
      text: string
      value: string
    }[]
  >()
  const [filterReasons, setFilterReasons] = React.useState<
    {
      text: string
      value: string
    }[]
  >()
  const [searchState, setSearchState] = React.useState<{
    searchText: string
    searchedColumn: string
  }>()
  const searchInput = useRef<any>(null)

  React.useMemo(() => {
    if (props.cancelledAlerts.length) {
      // set unique flocs for filter
      let flocs = props.cancelledAlerts.map((row) => {
        return { value: row.floc, text: row.floc }
      })
      flocs = flocs.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.value === thing.value)
      )
      setFilterFlocs(
        flocs.sort((a, b) => compareAlphaNumeric(a.value, b.value))
      )
      // set alert types
      const alertTypes = props.cancelledAlerts
        .map((row) => {
          return { value: row.alertType, text: row.alertTypeNiceName }
        })
        .filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.value === thing.value)
        )
      setFilterAlertType(
        alertTypes.sort((a, b) => compareAlphaNumeric(a.value, b.value))
      )
      const removalReasons = props.cancelledAlerts
        .map((row) => {
          return { value: row.cancelReason, text: row.cancelReason }
        })
        .filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.value === thing.value)
        )
      setFilterReasons(removalReasons)
    }
  }, [props.cancelledAlerts])

  const getColumnSearchProps = (dataIndex: string, prettyName: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <AutoComplete
          ref={searchInput}
          placeholder={`Search ${prettyName}`}
          onChange={(e: string) => {
            setSelectedKeys(e ? [e] : [])
          }}
          filterOption={true}
          options={props.cancelledAlerts
            .map((alert: CancelledAlert) => {
              return { value: alert[dataIndex] }
            })
            .filter(
              (thing, index, self) =>
                index === self.findIndex((t) => t.value === thing.value)
            )}
          // use onSearch to customise search result include highlight key words
          // onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: CancelledAlert) => {
      const res = record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : ''
      return res
    },
    render: (text: string) =>
      searchState && searchState.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchState!.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm()
    setSearchState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }
  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchState({ searchText: '', searchedColumn: '' })
  }

  const columns = [
    {
      title: 'Removal Date',
      dataIndex: 'lastUpdated',
      key: '1',
      sorter: (a: CancelledAlert, b: CancelledAlert) =>
        a.lastUpdatedDate.getTime() - b.lastUpdatedDate.getTime(),
    },
    {
      title: 'FLOC',
      dataIndex: 'floc',
      key: '2',
      sorter: (a: CancelledAlert, b: CancelledAlert) => {
        return compareAlphaNumeric(a.floc, b.floc)
      },
      filters: filterFlocs,
      onFilter: (value: string, record: CancelledAlert) =>
        record.floc.includes(value),
    },
    {
      title: 'Alert Type',
      dataIndex: 'alertTypeNiceName',
      key: '3',
      filters: filterAlertType,
      onFilter: (value: string, record: CancelledAlert) =>
        record.alertType.includes(value),
      render: (text: string, record: CancelledAlert) => (
        <TitleIcon>
          {AlertTypeMappingRecord[record.alertType].displayText}
        </TitleIcon>
      ),
    },
    {
      title: 'Removal Reason',
      dataIndex: 'cancelReason',
      key: '4',
      ellipsis: true,
      filters: filterReasons,
      onFilter: (value: string, record: CancelledAlert) =>
        record.cancelReason.includes(value),
    },
    {
      title: 'Additional Comments',
      dataIndex: 'additionalComments',
      key: '4',
      ellipsis: true,
      ...getColumnSearchProps('additionalComments', 'Comments'),
    },
    {
      title: 'Removed By',
      dataIndex: 'cancelledBy',
      key: '9',
      ...getColumnSearchProps('cancelledBy', 'Wop ID'),
    },
  ]

  return (
    <Table
      data-testid="cancalledTable"
      rowKey={'id'}
      columns={columns}
      dataSource={props.cancelledAlerts}
      pagination={{ hideOnSinglePage: true, size: 'small' }}
    />
  )
}

export default ActionedTable

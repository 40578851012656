import { getUIEquipmentTypeFromFuseEquipmentType } from '../utility'
import { EquipmentType } from './equipmentTypes'

export interface Measurement {
  id: string //"sa::AU21.1E1441K22_AU21.1E1441KM22_sa_status~LAST_60MIN"
  key: string //'MEASUREMENT_SITUATIONAL_AWARENESS_STATUS';
  name: string // 'Equipment Status';
  source: string //'TAG_V2';
  type: string //'SITUATIONAL_AWARENESS_STATUS';
  unitOfMeasure: string //'status';
  attributes: MeasurementAttribute[]
  values: [{ value: string; timestamp: number }]
}
export interface MeasurementAttribute {
  name: string
  value: string
}

export interface ItemLatestMeasurementsResponse {
  equipmentTagname: string
  parentEquipmentId: string
  floc: string
  measurements: Measurement[]
  children: ItemLatestMeasurementsResponse[]
}

// response type for alertlist
export interface GetOverviewQueryResponse {
  getOverview: GetOverViewResponse
}
export interface GetOverViewResponse {
  FLOC: string
  attributes: [key: string, name: string, value: string]
  equipmentId: string
  equipmentTag: string
  equipmentType: string
  measurements: Measurement[]
  type: string
}

export interface Eq360OverviewAnomaly {
  vibration: Eq360VibrationObj[]
}

export interface Eq360VibrationObj {
  measureName: string
  value: string
  type: string
  time: string
  mptId: string
  correlationId: string
}

export enum CBMAttributes {
  FlocGroupHead = 'CBM-FLOCGROUP',
  EquipmentType = 'CBM-EQUIPMENTTYPE',
}
export default class EquipmentOverview {
  FLOC!: string
  equipmentId!: string
  equipmentTag!: string
  equipmentType!: string
  type!: string
  measurements?: Measurement[]
  serviceName(): string {
    if (this.attributes && this.attributes.length > 1) {
      const serviceTag = this.attributes.filter(
        (v) => v.key.toUpperCase() === 'SERVICE'
      )
      if (serviceTag.length >= 1) {
        return serviceTag[0].value
      }
    }
    return this.equipmentTag ?? ''
  }
  attributes!: [{ name: string; key: string; value: string }]
  anomalies?: Eq360OverviewAnomaly
  isValid(): boolean {
    return Boolean(this.FLOC) || Boolean(this.equipmentType)
  }
  // deprecated as there is no good way to maintain this attribute.
  getCBMAttribute(attributeType: CBMAttributes): string {
    if (this.attributes && this.attributes.length) {
      const foundAttribute = this.attributes.filter(
        (attribute) =>
          attribute.key.toUpperCase() === attributeType.valueOf().toUpperCase()
      )
      if (foundAttribute.length) {
        return foundAttribute[0].value
      } else {
        return ''
      }
    } else return ''
  }

  isOnboarded() {
    return this.isFan() || this.isPump() || this.isESD()
  }

  isFilter() {
    return false
  }

  getCBMEquipmentType(): EquipmentType | null {
    if (this.isFan()) {
      return EquipmentType.fan
    } else if (this.isPump()) {
      return EquipmentType.pump
    } else if (this.isFilter()) {
      return EquipmentType.filter
    } else if (this.isESD()) {
      return EquipmentType.esd
    } else {
      return null
    }
  }

  isFan() {
    return (
      getUIEquipmentTypeFromFuseEquipmentType(this.equipmentType) ===
      EquipmentType.fan
    )
  }
  isPump() {
    return (
      getUIEquipmentTypeFromFuseEquipmentType(this.equipmentType) ===
      EquipmentType.pump
    )
  }
  isESD() {
    return (
      getUIEquipmentTypeFromFuseEquipmentType(this.equipmentType) ===
      EquipmentType.esd
    )
  }

  constructor(data: Partial<EquipmentOverview>) {
    Object.assign(this, data)
  }
}

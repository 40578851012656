import styled from '@emotion/styled'
import {
  DialogContent,
  Dialog,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'

import sadImg from '../../../../images/alert-warning.png'

const StyledContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
  img {
    height: 7rem !important;
  }
  h1 {
    color: #ff9800;
    padding: 4px;
    font-weight: 400;
  }
  p {
    text-align: center;
    color: transparent(0.8);
    font-size: 0.9rem;
  }
`

interface AlertSubmissionFailedProps {
  open: boolean
}

const AlertSubmissionFailedDialogue = (props: AlertSubmissionFailedProps) => {
  const myHistroy = useHistory()
  return (
    <Dialog
      aria-labelledby="dialog-diagnosis-action"
      aria-describedby="dialog-diagnosis-done"
      open={props.open}
      maxWidth="xs"
    >
      <StyledContent>
        <img id="dialogue-main-image" src={sadImg} alt="SadImg" />
        <h1>Connection Issue!</h1>
        <DialogContentText id="dialog-diagnosis-content">
          Unfortunately there was a problem connecting to the CBM backend.
          Please come back and try again later.
        </DialogContentText>
      </StyledContent>
      <DialogActions>
        <Button
          onClick={() => {
            myHistroy.push('/')
          }}
          color="primary"
        >
          Return to All Alerts
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default AlertSubmissionFailedDialogue

import { useState } from 'react'
import { AlertTypes } from '../../../shared/types/alert'
import { EquipmentType } from '../../../shared/types/equipmentTypes'
import { Plant } from '../../../shared/types/plants'
import { SortKeyEnum } from '../../../shared/types/sortableEntity'
import { FilterSetting } from '../component'

export class SortPerference {
  sortKey: SortKeyEnum
  sortDirection: 'asc' | 'desc'
}
export class UserPerference {
  0: SortPerference
  1: SortPerference
  2: SortPerference
  filter: FilterSetting
  defaultPage: number
}

const DefaultUserPreference = {
  0: {
    sortKey: SortKeyEnum.DATE,
    sortDirection: 'asc',
  },
  1: {
    sortKey: SortKeyEnum.DATE,
    sortDirection: 'desc',
  },
  2: {
    sortKey: SortKeyEnum.DATE,
    sortDirection: 'desc',
  },
  filter: {
    plant: Plant.pluto,
    equipmentType: 'fan',
    alertType: 'all',
  },
  defaultPage: 0,
} as UserPerference

export const PerferenceStorageKeyName = 'CBM-User-Sorting-Pref-Tab'

export function useUserPreference(initialValue = DefaultUserPreference) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const setDefault = () => {
    window.localStorage.setItem(
      PerferenceStorageKeyName,
      JSON.stringify(DefaultUserPreference)
    )
  }

  const validateStoragePref = (jsonItem: any) => {
    if (Object.keys(jsonItem).length !== 5) {
      throw 'missing keys'
    }
    Object.keys(jsonItem).forEach((key) => {
      if (
        key !== '0' &&
        key !== '1' &&
        key !== '2' &&
        key !== 'filter' &&
        key !== 'defaultPage'
      ) {
        throw 'corrupted keys'
      }
      // test for page
      if (key === 'defaultPage') {
        const page = parseInt(jsonItem[key])

        if (page > 3 || page < 0 || isNaN(page)) {
          // invalid
          throw 'corrupted, wrong page'
        }
      } // test for filter:
      else if (key === 'filter') {
        if (
          !jsonItem[key].plant ||
          !jsonItem[key].equipmentType ||
          !jsonItem[key].alertType
        ) {
          throw 'corrupted, filter'
        } else {
          // check plant is one of the plant
          if (!Object.keys(Plant).includes(jsonItem[key].plant)) {
            throw 'Unknown plant in perference'
          }
          // check equipment type is one of the equipmentType
          if (
            !Object.keys(EquipmentType).includes(jsonItem[key].equipmentType) &&
            jsonItem[key].equipmentType !== 'all'
          ) {
            throw 'Unknown equipmentType in perference'
          }
          // check alertType is actually alertType
          if (
            !Object.keys(AlertTypes).includes(jsonItem[key].alertType) &&
            jsonItem[key].alertType !== 'all'
          ) {
            throw 'Unknown AlertType in perference'
          }
        }
      } else {
        // test for others
        if (!jsonItem[key].sortKey || !jsonItem[key].sortDirection) {
          throw 'corrupted, not containing sort keys and sortDirections'
        }
        let oneOfIt = false
        Object.keys(SortKeyEnum).forEach((sortKey) => {
          if (jsonItem[key].sortKey === SortKeyEnum[sortKey]) {
            oneOfIt = true
          }
        })
        if (!oneOfIt) {
          throw 'corrupted, Wrong SortKey'
        }
        if (
          jsonItem[key].sortDirection !== 'desc' &&
          jsonItem[key].sortDirection !== 'asc'
        ) {
          throw 'corrupted, wrong direction'
        }
      }
    })
  }
  const [storedPreference, setStoredPreference] = useState(() => {
    //only run first time render
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(PerferenceStorageKeyName)
      // validator
      if (item) {
        const jsonItem = JSON.parse(item)
        validateStoragePref(jsonItem)
        return jsonItem
      }

      return initialValue
    } catch (error) {
      // If error also return initialValue and refresh setting to default
      console.log('errored storage, reset reason:', error)
      setDefault()
      return initialValue
    }
  })
  // prevent too many access to localStorage
  const [cache, setCache] = useState<UserPerference>(storedPreference)

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setUserPreference = (newPerference: UserPerference) => {
    try {
      // let valueToStore = JSON.parse(JSON.stringify(storedPreference));
      // valueToStore[tabNumber] = value;
      // Save state
      setStoredPreference(newPerference)

      //Save Cache
      setCache(newPerference)
      // Save to local storage
      window.localStorage.setItem(
        PerferenceStorageKeyName,
        JSON.stringify(newPerference)
      )
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }
  return [cache, setUserPreference] as [
    UserPerference,
    (newPerference: UserPerference) => void,
  ]
}

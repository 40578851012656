export interface MaintenanceItem {
  maintenanceItem: string
  maintenanceItemText: string
  itemSequence: string
  equipmentNumber: string
  plannerGroup: string
  mainWorkCenter: string
  mainWorkCenterText: string
  functionalLocation: string
  functionalLocationText: string
  lastWONumber: string
  systemCondition: string
  orderType: string
  maintenanceActivityType: string
  priority: string
  revisionCode: string
  itemStatus: string
  performanceStandard: string
  consequenceCategory: string
  consequence: string
  likelihood: string
  proposedPriority: string
  technicalIntegrity: boolean
  regulatoryRequirements: string
  fapIndicator: boolean
  doNotReleaseImmediatelyFlag: boolean
}

export interface TaskList {
  taskListType: string
  taskListGroup: string
  groupCounter: string
  taskListText: string
  validFromDate: string
  tlHeaderDeletionIndicator: boolean
  taskListUsage: string
  tlStatus: string
  tlAssembly: string
}

export interface TaskListOperation {
  tlOpDeletionIndicator: boolean
  operationNumber: string
  controlKey: string
  opWorkCenter: string
  opWorkCenterText: string
  opWorkCenterPlant: string
  operationText: string
  duration: string
  durationUom: string
  work: string
  workUom: string
  numberOfCapacity: string
  activityType: string
}

export interface TaskListMaterial {
  materialDeletionIndicator: boolean
  materialNumber: string
  operationNumber: string
  itemCategory: string
  materialQty: string
  materialUom: string
  materialDescription: string
  plannedDeliveryTimeInDays: string
  goodsReceiptProcTimeInDays: string
  mrpType: string
  specialProcType: string
  materialStatus: string
  availableStockQty: string
}

//NOTE THAT the actual response returns a list,
export interface MaintenanceItemResponseObj {
  measuringPoint: string
  maintenancePlan: string
  maintenancePlanText: string
  schedulingPeriod: string
  schedulingIndicator: string
  countOfMaintItem: string
  callNumber: string
  sortField: string
  maintenanceItem: MaintenanceItem
  taskList: TaskList
  taskListOperation: TaskListOperation[]
  taskListMaterial: TaskListMaterial[]
  // BELOW FOR ERROR HANDLING
  message?: string // error message from Mule
  statusCode?: string // error code from SAP
}
export const MaterialStatusMapping = {
  '03': 'Insurance Item',
  '04': 'Refurbishable Item',
  '08': 'Record Superceded to CMA',
  '10': 'Mat for Info Display Only',
  '20': 'technical Review Required',
  '30': 'Active Record',
  '40': 'Awaiting Stock Placement',
  '50': 'No Purchasing Allowed',
  '60': 'Record Potentially Obsolete',
  '70': 'OEM Discountinued',
  '72': 'NLA, Info Only',
  '73': 'NLA, New part, No Cat',
  '74': 'NLA, New part, Cat',
  '80': 'Record Obsolete',
  '99': 'DO NOT USE',
}

export const sampleMaintenancePlanResp = [
  {
    measuringPoint: '398725',
    maintenancePlan: 'CBM-FINFAN',
    maintenancePlanText: 'Single Cycle Plan for CBm fan belt',
    schedulingPeriod: '000',
    schedulingIndicator: '3',
    countOfMaintItem: '0001',
    callNumber: '16',
    sortField: 'CBM',
    maintenanceItem: {
      maintenanceItem: '104629',
      maintenanceItemText: 'Single Cycle Plan for CBm fan belt',
      itemSequence: '0001',
      equipmentNumber: '',
      plannerGroup: 'CIT',
      mainWorkCenter: '04CC',
      mainWorkCenterText: 'Core Crew',
      functionalLocation: 'AU04.AF891A',
      functionalLocationText: 'FAN,AM810B GENERATOR COOLING SYSTEM',
      lastWONumber: '2200352868',
      systemCondition: '',
      orderType: 'PMRC',
      maintenanceActivityType: '080',
      priority: '3',
      revisionCode: '',
      itemStatus: 'WAAP',
      performanceStandard: '',
      consequenceCategory: 'L',
      consequence: 'B',
      likelihood: '0',
      proposedPriority: '3',
      technicalIntegrity: false,
      regulatoryRequirements: '',
      fapIndicator: false,
      doNotReleaseImmediatelyFlag: true,
    },
    taskList: {
      taskListType: 'A',
      taskListGroup: 'AU040191',
      groupCounter: '1',
      taskListText: '3M INSP T200 STRIP GAS COMPRESSOR',
      validFromDate: '20071214',
      tlHeaderDeletionIndicator: false,
      taskListUsage: '4',
      tlStatus: '4',
      tlAssembly: '',
    },
    taskListOperation: [
      {
        tlOpDeletionIndicator: false,
        operationNumber: '0010',
        controlKey: 'INT',
        opWorkCenter: '04CCOPT',
        opWorkCenterText: 'Core Crew Prod Tech',
        opWorkCenterPlant: 'AU04',
        operationText: '3M - INSP T200 STRIP GAS COMPRESSOR',
        duration: '6.0',
        durationUom: 'H',
        work: '6.0',
        workUom: 'H',
        numberOfCapacity: '1',
        activityType: 'TM4011',
      },
      {
        tlOpDeletionIndicator: false,
        operationNumber: '0020',
        controlKey: 'INT',
        opWorkCenter: '04CCIET',
        opWorkCenterText: 'Core Crew Inlec Tech',
        opWorkCenterPlant: 'AU04',
        operationText: '3M - Inspect Sample',
        duration: '1.0',
        durationUom: 'H',
        work: '2.0',
        workUom: 'H',
        numberOfCapacity: '2',
        activityType: 'TM4011',
      },
    ],
    taskListMaterial: [
      {
        materialDeletionIndicator: false,
        materialNumber: '10000224',
        operationNumber: '0010',
        itemCategory: 'L',
        materialQty: '1.00',
        materialUom: 'PCE',
        materialDescription: 'VALVE,BALL,10MM,800LB,SCR NPT,SS316,FB',
        plannedDeliveryTimeInDays: '20',
        goodsReceiptProcTimeInDays: '19',
        mrpType: 'ND',
        specialProcType: '42',
        materialStatus: '30',
        availableStockQty: '0.000',
      },
      {
        materialDeletionIndicator: false,
        materialNumber: '10000323',
        operationNumber: '0020',
        itemCategory: 'L',
        materialQty: '1.00',
        materialUom: 'PCE',
        materialDescription: 'FRAME,ELMAR L-690027 TEST UNIT',
        plannedDeliveryTimeInDays: '62',
        goodsReceiptProcTimeInDays: '17',
        mrpType: 'ND',
        specialProcType: '42',
        materialStatus: '30',
        availableStockQty: '0.000',
      },
      {
        materialDeletionIndicator: false,
        materialNumber: '10498281',
        operationNumber: '0030',
        itemCategory: 'L',
        materialQty: '1.00',
        materialUom: 'PCE',
        materialDescription: 'CABLE,FIBRE,ARM,600UM,ICON SCIE,COL-0021',
        plannedDeliveryTimeInDays: '12',
        goodsReceiptProcTimeInDays: '2',
        mrpType: 'ND',
        specialProcType: '49',
        materialStatus: '30',
        availableStockQty: '0.000',
      },
    ],
  },
]

import { useQuery } from 'react-query'
import axios from 'axios'

import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { acquireTokenInfoSilent } from '../../shared/auth'

// State: Active and Cancelled enum
const fetchEquipmentGroupHead = async (
  msalInstance: IPublicClientApplication,
  floc: string
): Promise<string> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    axios
      .get(
        import.meta.env.VITE_CBM_ENDPOINT + '/flocgroups?' + `floc=${floc}`,
        {
          headers: {
            Authorization: `Bearer ${
              (
                await acquireTokenInfoSilent(
                  msalInstance,
                  import.meta.env.VITE_AAD_CLIENTID! + '/.default'
                )
              )?.accessToken
            }`,
          },
        }
      )
      .then((response) => {
        if (response) {
          resolve(response.data as string)
        } else {
          resolve('')
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// get the floc group head from a floc, return "" if not found
export default function useCBMEquipmentGroup(floc?: string) {
  const { instance: msalInstance } = useMsal()
  return useQuery(
    ['CBM Equipment Group Search', floc],
    () => fetchEquipmentGroupHead(msalInstance, floc!),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 10 * 1000 * 60, // 2min stale time, unless alert raised during this 10 minutes or reload page, it will use this as fast chache
      enabled: Boolean(floc),
      // retryDelay: 1000,
    }
  )
}

import { useQuery } from 'react-query'
import axios from 'axios'
import { MaintenanceItemResponseObj } from '../../../../../shared/types/maintenanceItem'
import { acquireTokenInfoSilent } from '../../../../../shared/auth'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { apm } from '@elastic/apm-rum'

function ApplyCleaningLogicToHideDeletedData(
  data: MaintenanceItemResponseObj[]
) {
  // first check if any errors in response
  const errorResponse = data.find((record) => {
    return Boolean(record.message)
  })
  const active = data.find((record) => {
    if (record.message) {
      // errored record
      return false
    }
    return record.taskList.tlHeaderDeletionIndicator === false
  })
  if (!active) {
    if (errorResponse) {
      // no active in response, but got somem errors in response,should allow continue?
      throw 'Can not obatin the active TaskList. '
    } else {
      // no active can be found, and no errors in response., should not allow continue?
      throw 'Can not an active TaskList from SAP.'
    }
  }
  // Found Active
  // filter all deleted Material
  active.taskListMaterial = active.taskListMaterial.filter(
    (m) => !m.materialDeletionIndicator
  )
  // filter all deleted Operations
  active.taskListOperation = active.taskListOperation.filter(
    (m) => !m.tlOpDeletionIndicator
  )
  return active as MaintenanceItemResponseObj
}

const fetchGetMaintenancePlanData = async (
  msalInstance: IPublicClientApplication,
  measuringPointId: string
): Promise<MaintenanceItemResponseObj> => {
  const transaction = apm.startTransaction(
    'useGetMaintenancePlanData',
    'Mulesoft'
  )!
  const httpSpan = transaction.startSpan(
    `GET ${import.meta.env.VITE_PLANT_M_ERP_MULE_URL!}`,
    'app'
  )!
  transaction.addLabels({
    measurementPoint: measuringPointId,
  })
  return new Promise((resolve, reject) => {
    acquireTokenInfoSilent(
      msalInstance,
      import.meta.env.VITE_PLANT_M_ERP_MULE_ID! + '/.default'
    )
      .then((tokenResponse) => {
        axios
          .get(
            import.meta.env.VITE_PLANT_M_ERP_MULE_URL +
              `?measuringPoint=${measuringPointId}`,
            {
              headers: {
                client_id: import.meta.env.VITE_PLANT_M_ERP_MULE_TRACKAPPID,
                client_secret: '',
                Authorization: `Bearer ${tokenResponse?.accessToken}`,
              },
            }
          )
          .then((response) => {
            if (response.data.length) {
              try {
                resolve(ApplyCleaningLogicToHideDeletedData(response.data))
              } catch (e) {
                apm.captureError(
                  new Error(`Get MaintenancePlan failed with error: ${e}'`)
                )
                reject(e)
              }
            }
          })
      })
      .catch((e) => {
        apm.captureError(
          new Error(`Get MaintenancePlan fetch failed with status ${e}`)
        )
        reject(e)
      })
      .finally(() => {
        httpSpan.end()
        transaction.end()
      })
  })
}

export default function useGetMaintenancePlanData(measuringPointId: string) {
  const { instance } = useMsal()
  return useQuery(
    ['maintenance plan', measuringPointId],
    () => fetchGetMaintenancePlanData(instance, measuringPointId),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 10 * 1000 * 60, // 10 min stale time, unless alert raised during this 10 minutes or reload page, it will use this as fast chache
      // retryDelay: 1000,
      enabled: Boolean(measuringPointId),
    }
  )
}

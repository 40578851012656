import axios from 'axios'
import React, { createContext, ReactNode, useState } from 'react'
import { useMutation } from 'react-query'

import UserTokenProvider, {
  useUserTokenContext,
} from '../../../shared/providers/user-token-provider'
import AppFeedbackDialogue, { GlobalFeedbackBody } from './component'
import FeedbackDialogueSuccess from './success-dialogue'

export type FeedbackContext = {
  openFeedback: () => void
  callback: (success: boolean) => void
  onClose?: () => void | undefined
}

export const AppFeedbackContext = createContext<FeedbackContext | undefined>(
  undefined
)

export function useAppFeedback() {
  const context = React.useContext(AppFeedbackContext)
  if (context === undefined) {
    throw new Error(
      'useAppFeedback must be used within a useAppFeedbackProvider'
    )
  }
  return context
}

type PropsType = {
  callback: (success: boolean) => void
  onClose?: () => void
  children: ReactNode
}

export default function AppFeedbackProvider({
  children,
  callback,
  onClose,
}: PropsType) {
  const [open, setOpen] = useState<boolean>(false)
  const [success, setIsSuccessful] = useState<boolean>(false)
  const tokenContext = useUserTokenContext()
  const { mutate: mutatePostFeedback } = useMutation(
    (inputToAPICall: any) =>
      axios(import.meta.env.VITE_CBM_ENDPOINT! + 'feedback', {
        method: 'POST',
        data: { ...inputToAPICall.body },
        headers: {
          Authorization: `Bearer ${tokenContext!.accessToken}`,
        },
      }),
    {
      onSuccess: async () => {
        setIsSuccessful(true)
      },
      onError: (error: any) => {
        console.log('Something went wrong:', error)
      },
      onSettled: () => {},
    }
  )

  const handleSubmitFeedback = (body: GlobalFeedbackBody) => {
    setOpen(false)
    mutatePostFeedback({ body: body })
  }

  return (
    <AppFeedbackContext.Provider
      value={{
        openFeedback: () => {
          setOpen(true)
        },
        callback: callback,
        onClose: onClose,
      }}
    >
      {children}
      <UserTokenProvider>
        <AppFeedbackDialogue
          handleClose={(event) => {
            event.stopPropagation()
            if (onClose) {
              onClose()
            }
            setOpen(false)
          }}
          onSubmitCallback={handleSubmitFeedback}
          open={open}
        />
      </UserTokenProvider>
      <FeedbackDialogueSuccess
        open={success}
        onClose={() => {
          setIsSuccessful(false)
          callback(success)
        }}
      />
    </AppFeedbackContext.Provider>
  )
}

import React, { useContext, useState } from 'react'
import { Theme } from '@mui/material/styles'
import { makeStyles, createStyles } from '@mui/styles'
import styled from '@emotion/styled'
import {
  Select,
  Divider,
  Button,
  Toolbar,
  AppBar,
  Drawer,
  CssBaseline,
  Link,
  Box,
} from '@mui/material'
import { apm } from '@elastic/apm-rum'
import { css } from '@emotion/core'

import SearchIcon from '../../../icons/search.svg?react'
import PlusIcon from '../../../icons/plusIcon.svg?react'
import FeedbackIcon from '../../../icons/feedback.svg?react'
import { DataFilterContext } from '../component'
import { plantMetaData } from '../../../shared/types/plants'
import AlertTypeMappingRecord from '../../../shared/components/alert-metadata'
import { useAppSearchContext } from '../../../shared/providers/app-search-provider'
import { equipmentTypeFilterData } from '../../../shared/types/equipmentTypes'
import AppFeedbackProvider, {
  AppFeedbackContext,
} from '../../navigation/global-feedback/feedback-provider'

const FilterDiv = styled(Box)`
  padding: 1.5rem;
  h3 {
    font-size: 1rem;
    font-weight: 500;
  }
`

const DropDownDiv = styled(Box)`
  margin: 1rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  span {
    color: #666666;
  }
`

const AlertTypeDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  span {
    color: #666666;
    font-weight: 500;
  }
  b {
    font-weight: 500;
  }
`

const QuickActionDiv = styled(Box)`
  padding: 1.5rem;
  align-items: flex-start;
  h3 {
    font-size: 1rem;
    font-weight: 500;
  }
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`

const SearchIconStyled = styled((props) => <SearchIcon {...props} />)`
  width: 1rem;
  height: 1rem;
  margin-right: 0;
`

const PlusIcontyled = styled((props) => <PlusIcon {...props} />)`
  width: 1rem;
  height: 1rem;
  margin-right: 0;
`

const FeedbackContainer = styled(Box)`
  text-align: center;
  margin: 32px;
  .feedbackBtn.MuiButton-contained {
    background-color: rgba(0, 21, 67, 0.08) !important;
    color: #001543;
  }
  .feedbackBtn.MuiButton-root {
    border-radius: 0px;
  }
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: 'calc(100% - 270px)',
      marginLeft: 270,
    },
    drawer: {
      width: 270,
      flexShrink: 0,
    },
    drawerPaper: {
      width: 270,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  })
)

export interface FilterDrawerProps {}

const FilterDrawer = React.memo((props: FilterDrawerProps) => {
  const classes = useStyles()
  const filterContext = useContext(DataFilterContext)
  const { openFlocSearch } = useAppSearchContext()
  const [assetFilter, setAssetFilter] = useState(
    filterContext.filterSetting.plant
  )
  const [equipmentType, setEquipmentType] = useState(
    filterContext.filterSetting.equipmentType
  )
  const [alertTypeFilter, setAlertTypeFilter] = useState(
    filterContext.filterSetting.alertType
  )

  interface AlertTypeBoxProps {
    index: string
  }

  const AlertTypeBox = styled(Box)(
    css`
      padding: 1rem;
      display: flex;
      flex-direction: row;
      border-radius: 0.5rem;
      gap: 0.3rem;
      b {
        font-size: 0.8rem;
      }
      small {
        color: #666666;
        position: relative;
      }
      svg {
        margin-left: auto;
        width: 0.8rem;
        height: 0.8rem;
      }
    `,
    ({ index }: AlertTypeBoxProps) => ({
      borderColor: index === alertTypeFilter ? '#0064CE' : '',
      fontWeight: index === alertTypeFilter ? 500 : 400,
      boxShadow: '0 0 ' + '1px black',
      svg: {
        color: index === alertTypeFilter ? '#0064CE' : '',
      },
    })
  )

  const handleAssetFilterChange = (e: any) => {
    setAssetFilter(e.target.value)
    filterContext.filterSetting.plant = e.target.value
    if (filterContext.onFilterChange) {
      filterContext.onFilterChange()
    }
  }

  const handleEquipmentTypeFilterChange = (e: any) => {
    setEquipmentType(e.target.value)
    filterContext.filterSetting.equipmentType = e.target.value
    if (filterContext.onFilterChange) {
      filterContext.onFilterChange()
    }
  }

  const onFeedbackClick = (consumerContext: any) => {
    if (consumerContext) {
      consumerContext.openFeedback()
      apm.addLabels({
        componentName: 'SideMenu-Feedback',
        componentType: 'IconButton',
        interactionType: 'click',
      })
    }
  }

  const getCurrentSelectedAlerts = () => {
    let theCount = null
    if (filterContext.filterSetting.alertType !== 'all') {
      // old code that filter by alertType
      theCount = filterContext.alertCounts.find(
        (count) => count.alertType === alertTypeFilter
      )?.activeCount
    } else {
      // count all ( NOT Filtering anything (not even plant or equipment type))
      theCount = filterContext.alertCounts.reduce(
        (a, b) => a + b.activeCount,
        0
      )
    }

    return (
      <small style={{ fontSize: '0.8rem', color: '#666666' }}>
        There are currently{' '}
        {theCount ? (
          <>
            <b>
              {theCount} active{' '}
              {filterContext.filterSetting.equipmentType === 'all'
                ? ''
                : equipmentTypeFilterData[
                    filterContext.filterSetting.equipmentType
                  ].name}
            </b>
            {`  alert${theCount === 1 ? '' : 's'} at ${
              plantMetaData[filterContext.filterSetting.plant].name
            }`}
          </>
        ) : (
          <>
            <b>
              NO active
              {` ${
                filterContext.filterSetting.equipmentType === 'all'
                  ? ''
                  : equipmentTypeFilterData[
                      filterContext.filterSetting.equipmentType
                    ].name
              } `}
            </b>
            alert
            {` at ${plantMetaData[filterContext.filterSetting.plant].name}.`}
          </>
        )}
      </small>
    )
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar></Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <FilterDiv>
          <h3>Filter Settings:</h3>
          <DropDownDiv>
            <span>Asset:</span>
            <Select
              data-testid="assetFilterSelect"
              fullWidth={true}
              native
              value={assetFilter}
              onChange={handleAssetFilterChange}
              inputProps={{
                name: 'asset',
                id: 'asset-filter-selector',
              }}
              variant="standard"
            >
              {Object.keys(plantMetaData).map((key) => {
                return (
                  <option
                    aria-label={plantMetaData[key].name}
                    value={key}
                    key={key}
                  >
                    {plantMetaData[key].name}
                  </option>
                )
              })}
            </Select>
          </DropDownDiv>
          <DropDownDiv>
            <span>Equipment Type:</span>
            <Select
              data-testid="equipmentTypeSelect"
              fullWidth={true}
              native
              value={equipmentType}
              onChange={handleEquipmentTypeFilterChange}
              inputProps={{
                name: 'equipment-type',
                id: 'equipment-filter-selector',
              }}
              variant="standard"
            >
              {Object.keys(equipmentTypeFilterData).map((key) => {
                return (
                  <option
                    aria-label={equipmentTypeFilterData[key].name}
                    value={key}
                    key={key}
                  >
                    {equipmentTypeFilterData[key].name}
                  </option>
                )
              })}
            </Select>
          </DropDownDiv>
        </FilterDiv>
        <Divider />
        <FilterDiv>
          <AlertTypeDiv>
            <span>Alert Summary:</span>
            {getCurrentSelectedAlerts()}
            {filterContext.alertCounts.map((alertCountFilterType, index) => {
              return (
                <AlertTypeBox
                  key={index}
                  index={alertCountFilterType.alertType}
                >
                  <b>
                    {alertCountFilterType.activeCount +
                      ' ' +
                      AlertTypeMappingRecord[alertCountFilterType.alertType]
                        .displayTextPlural}
                  </b>
                  {AlertTypeMappingRecord[
                    alertCountFilterType.alertType
                  ].getIcon()}
                </AlertTypeBox>
              )
            })}
          </AlertTypeDiv>
        </FilterDiv>
        <Divider />
        <QuickActionDiv>
          <h3>Quick Actions:</h3>
          <Button
            color="primary"
            startIcon={<PlusIcontyled />}
            onClick={() => {
              openFlocSearch(true)
            }}
          >
            New Maintenance Request
          </Button>
          <Button
            color="primary"
            startIcon={<SearchIconStyled />}
            onClick={() => {
              openFlocSearch()
            }}
          >
            Search FLOC
          </Button>
        </QuickActionDiv>
        <AppFeedbackProvider callback={(e) => {}}>
          <AppFeedbackContext.Consumer>
            {(consumerContext) => (
              <FeedbackContainer>
                <Button
                  className="feedbackBtn"
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={() => {
                    onFeedbackClick(consumerContext)
                  }}
                >
                  <FeedbackIcon
                    style={{ marginRight: '.5rem', height: '0.9rem' }}
                  />
                  Give Feedback
                </Button>
                <Link
                  href="https://woodsideenergy.sharepoint.com/sites/OperationsTransformation/SitePages/Condition-Based-Maintenance---Transform.aspx?Mode=Edit"
                  color="primary"
                  target="_blank"
                  style={{ fontSize: '.875rem' }}
                >
                  Training Material
                </Link>
              </FeedbackContainer>
            )}
          </AppFeedbackContext.Consumer>
        </AppFeedbackProvider>
      </Drawer>
    </div>
  )
})

export default FilterDrawer

import React from 'react'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(2),
    },
  })
)

export interface FeedbackCheckboxesGroupProps {
  checkBoxCallback: (arg: any) => void
}

export default function FeedbackCheckboxesGroup(
  props: FeedbackCheckboxesGroupProps
) {
  const classes = useStyles()
  const [checkboxStates, setCheckboxStates] = React.useState({
    inteferenceObserved: false,
    processRelated: false,
    recentMaintenance: false,
  })

  React.useEffect(() => {
    props.checkBoxCallback(checkboxStates)
  }, [checkboxStates])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxStates({
      ...checkboxStates,
      [event.target.name]: event.target.checked,
    })
  }

  const { inteferenceObserved, processRelated, recentMaintenance } =
    checkboxStates

  return (
    <div className={classes.root}>
      <FormControl
        component="fieldset"
        className={classes.formControl}
        variant="standard"
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={inteferenceObserved}
                onChange={handleChange}
                name="inteferenceObserved"
              />
            }
            label="Interference from surrounding equipment"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={processRelated}
                onChange={handleChange}
                name="processRelated"
              />
            }
            label="Process related event"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={recentMaintenance}
                onChange={handleChange}
                name="recentMaintenance"
              />
            }
            label="Recent maintenance"
          />
        </FormGroup>
      </FormControl>
    </div>
  )
}

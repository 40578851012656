import { Theme, Toolbar, Typography, AppBar, Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { createStyles, makeStyles } from '@mui/styles'
import { useMsal } from '@azure/msal-react'

import { acquireTokenInfoSilent } from '../../shared/auth'
import WoodsideLogo from '../../icons/woodside.svg?react'
import { getInitials } from '../../shared/utility'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      width: '100%',
    },
    fullList: {
      width: 'auto',
    },
  })
)

const RightDiv = styled(Box)`
  margin-left: auto;
  margin-right: 2rem;
`

const CircularDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 40px;
  height: 40px;
  background: #f3f5f7;
  border-radius: 64px;
  flex: none;
  order: 1;
  flex-grow: 0;
  span {
    width: 32px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.14px;
    color: #001543;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`

const BrandContainer = styled.a`
  margin-bottom: 10px;
`

const Logo = styled(Box)`
  width: 64px;
  height: 50px;
  display: inline-block;
  svg {
    display: block;
    margin: auto;
  }
`

const AppBarComp = () => {
  const classes = useStyles()
  const [shortName, setShortName] = useState('')
  const { instance: msalInstance } = useMsal()
  useEffect(() => {
    let isMounted = true
    const fetchUserName = async () => {
      const token = await acquireTokenInfoSilent(
        msalInstance,
        import.meta.env.VITE_AAD_CLIENTID! + '/.default'
      )

      if (token && token.account && token.account.name) {
        const startOfCompany = token.account.name.indexOf('(')
        let fullName = token.account!.name
        if (startOfCompany > 0) {
          fullName = token.account.name.substr(0, startOfCompany).trim()
        }
        const names = fullName.split(',')
        const initials = getInitials(
          `${names[names.length - 1].trim()} ${names[0].trim()}`
        )
        if (isMounted) {
          setShortName(initials)
        }
      }
    }
    fetchUserName().catch(console.error)
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <AppBar
      position="fixed"
      elevation={1}
      className={classes.appBar}
      style={{ backgroundColor: '#FFFFFF' }}
    >
      <Toolbar disableGutters={true}>
        <BrandContainer href="/">
          <Logo component="span">
            <WoodsideLogo style={{ height: '65px', width: '35px' }} />
          </Logo>
          <Typography
            variant="h6"
            noWrap={true}
            style={{
              fontFamily: 'Roboto',
              color: '#001543',
              display: 'inline-block',
              letterSpacing: '1px',
            }}
          >
            Condition Based Maintenance
          </Typography>
        </BrandContainer>
        <RightDiv>
          {shortName && (
            <CircularDiv>
              <Box component="span" data-testid="initialsContainer">
                {shortName}
              </Box>
            </CircularDiv>
          )}
        </RightDiv>
      </Toolbar>
    </AppBar>
  )
}

export default AppBarComp

/**
 * Schema: https://confluence.woodside.com.au/display/PCBM/CBM+Interim+Strategy+2.0
 */
export function getFailureModes(floc: string): string {
  return `query GetFailureModes {
    equipment(floc: "${floc}") {
      floc
      equipmentClass {
        equipmentClass
        equipmentClassDescription
      }
      component {
        component
        componentDescription
        failureModes {
          failureMode
          failureModeControlActivity {
            failureModeControlActivity
            maintenanceResponsePackage {
              maintenanceResponsePackageId
              failureModeControlActivityId
              maintenanceResponseId
              maintenanceResponseEntity
            }
          }
        }
      }
      childEquipment {
        floc
        equipmentClass {
          equipmentClass
          equipmentClassDescription
        }
        component {
          component
          componentDescription
          failureModes {
            failureMode
            failureModeControlActivity {
              failureModeControlActivity
              maintenanceResponsePackage {
                maintenanceResponsePackageId
                failureModeControlActivityId
                maintenanceResponseId
                maintenanceResponseEntity
              }
            }
          }
        }
      }
    }
  }`
}

export function getEquipmentModelActionConditions(
  floc: string,
  eventType: string
): string {
  return `query getEquipmentModelActionConditions {
    conditionBasedModel(floc: "${floc}",modelName:"${eventType}",unique:true) {
      modelName
      auditRecordActiveFlag
      conditionBasedActionCondition {
        conditionName
        conditionExpression
        conditionBasedActionConditionProperty {
          propertyName
          propertyValue
          propertyValueSource
          propertyValueDatatype
        }
      }
    }
  }`
}

export function getEngineeringDataQuery(floc: string): string {
  return `query EngineeringData {
    engineeringData(floc: "${floc}") {
      floc
      floc_description
      equipment_type
      pump_manufacturer
      pump_model_number
      pump_serial_number
      pump_performance_standards
      pump_sap_criticality
      driver_floc
      driver_floc_description
      driver_equipment_type
      driver_manufacturer
      driver_model_number
      driver_serial_number
      driver_performance_standards
      driver_sap_criticality
      gearbox_floc
      gearbox_floc_description
      gearbox_equipment_type
      gearbox_manufacturer
      gearbox_model_number
      gearbox_serial_number
      gearbox_performance_standards
      gearbox_sap_criticality
      pump_floc_set
      pump_group
      api_pump_type
      driver_type
      gearbox_type
      vsds
      criticality
      supporting_function
      operating_configuration
      process_fluid
      hazardous_service
      hazardous_area
      enclosed_or_open
      pump_speed
      driver_speed
      motor_rating
      operation_utilisation
      wired_vibration
      lube_strategy
      lube_location
      lube_type
      lube_material_nr
      de_inboard_top
      nde_outboard
      common
      lube_volume_units
      pump_seal_group
      pump_barrier_fluid
      changeover
      uknitee_model
      runhours_tag
      seed_value
      model_start
      drawing_p_id
      drawing_pump_curve
      drawing_1
      drawing_2
      drawing_seal_p_id
      drawing_seal
      drawing_iom
      drawing_vem
      drawing_cause_effects
    }
  }`
}

import { useQuery } from 'react-query'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { apm } from '@elastic/apm-rum'

import { getEquipmentOverviewQuery } from '../graphql/EQ360Queries'
import { Eq360GraphFetch } from '../../shared/auth'
import EquipmentOverview from '../../shared/types/getOverviewResponse'

export async function fetchEquipmentOverviewByFloc(
  msalInstance: IPublicClientApplication,
  floc: string
): Promise<EquipmentOverview> {
  const transaction = apm.startTransaction('useEquipmentOverview', 'EQ360')!
  const httpSpan = transaction.startSpan(
    `POST ${import.meta.env.VITE_EQ360_HOST!}`,
    'app'
  )!
  transaction.addLabels({
    measurement: false,
    floc,
  })

  return new Promise((resolve, reject) => {
    Eq360GraphFetch(
      msalInstance,
      import.meta.env.VITE_EQ360_ID!,
      fetch,
      import.meta.env.VITE_EQ360_HOST!,
      getEquipmentOverviewQuery(floc, Math.floor(Date.now() / 1000))
    )
      .then((result) => {
        if (result.getOverview) {
          resolve(new EquipmentOverview(result.getOverview))
        } else {
          apm.captureError(
            new Error(`get overview is not in the response for floc ${floc}.`)
          )
          reject('get overview is not in the response.')
        }
      })
      .catch((e) => {
        let errorMessage = e
        if (e.length) {
          errorMessage = e[0].message
        }
        apm.captureError(
          new Error(
            `Equipment Overview fetch failed with status ${errorMessage}`
          )
        )
        reject(e)
      })
      .finally(() => {
        httpSpan.end()
        transaction.end()
      })
  })
}

const useEquipmentOverview = (floc: string) => {
  const { instance: msalInstance } = useMsal()

  return useQuery(
    ['EquipmentOverview', floc],
    () => fetchEquipmentOverviewByFloc(msalInstance, floc),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 20 * 1000 * 60,
      enabled: floc ? true : false,
    }
  )
}

export default useEquipmentOverview

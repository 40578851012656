import styled from '@emotion/styled'
import Pagination from '@mui/material/Pagination'
import React, { useRef, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useMsal } from '@azure/msal-react'
import { Box, Button } from '@mui/material'

import emptyImg from '../../../../images/app-noalerts.png'
import { fetchEquipmentOverviewByFloc } from '../../../../services/hooks/useEquipmentOverview'
import { FlocAlerts } from '../../../../shared/types/flocAlerts'
import { useWindowSize } from '../../../anomaly-review/context-panel/floc-panel/useWindowResize'
import PlusIcon from '../../../../icons/plusIcon.svg?react'
import FlocCard from './floc-card'
import { useAppSearchContext } from '../../../../shared/providers/app-search-provider'
// index == 1, means resolved/cancelled, dont show any actions, show status instead
interface ActiveTabProps {
  input: FlocAlerts[] | undefined
  value: number // current selection, not really used
  index: number
}

const CachingNumber = 5 // cache first 5 requests for EQ360

const EmptyDiv = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  width: 13rem;
  height: 11rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(${emptyImg});
  margin-bottom: 1rem;
`

const EmptyBox = styled(Box)`
  height: 60vh;
  width: 25vw;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-top: auto;
  margin-bottom: auto;
  h2 {
    opacity: 0.6;
  }
  p {
    opacity: 0.6;
    text-align: center;
    font-size: 1rem;
  }
  place-self: center;
`

const AlertsDiv = styled(Box)`
  padding: 1rem 0;
  gap: 0.6rem;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
`

const Root = styled(Box)`
  flex: 1 1 100%;
  display: contents;
`

const PlusIcontyled = styled((props: any) => <PlusIcon {...props} />)`
  width: 1rem;
  height: 1rem;
  margin-right: 0;
`

const ActiveTab = (state: ActiveTabProps) => {
  // get context for state
  const { input, value, index, ...other } = state
  const alertBackgroundDiv = useRef<HTMLDivElement | null>(null)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(8)
  const { openFlocSearch } = useAppSearchContext()
  const size = useWindowSize()
  const RowHeightApprox = 180
  const queryClient = useQueryClient()
  const { instance: msalInstance } = useMsal()
  const prefetchFlocs = async () => {
    if (input) {
      input.forEach((row: any, i: any) => {
        if (i < CachingNumber) {
          queryClient.prefetchQuery(
            ['EquipmentOverview', row.floc],
            () => fetchEquipmentOverviewByFloc(msalInstance, row.floc),
            {
              staleTime: 10 * 60 * 1000, // 10min stale
            }
          )
        }
      })
    }
  }
  // only preFetch Active Alerts (index ===0 )
  if (index === 0) {
    prefetchFlocs()
  }
  const EmptyCard = () => {
    return (
      <EmptyBox>
        <EmptyDiv />
        <h2>No new alerts just yet</h2>
        <p>
          We’ll let you know when something pops up for this equipment type. In
          the meantime you can still review FLOC information and raise adhoc
          maintenance requests.
        </p>
        <div>
          <Button
            color="primary"
            startIcon={<PlusIcontyled />}
            size="small"
            onClick={() => {
              openFlocSearch(true)
            }}
          >
            New Maintenance Request
          </Button>
        </div>
      </EmptyBox>
    )
  }

  const PaginationComp = (input: FlocAlerts[] | undefined) => {
    const PaginationRoot = styled(Box)`
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      margin-top: auto;
      padding-top: 1rem;
      .MuiPaginationItem-page.Mui-selected {
        background-color: #e6e7e8;
      }
    `

    if (input && input.length > rowsPerPage) {
      return (
        <PaginationRoot>
          <Pagination
            page={currentPage}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              setCurrentPage(value)
            }}
            count={Math.ceil(input.length / rowsPerPage)}
          />
        </PaginationRoot>
      )
    } else {
      return null
    }
  }

  useMemo(() => {
    if (alertBackgroundDiv && alertBackgroundDiv.current) {
      const totalSpaceinPixiel =
        alertBackgroundDiv.current!.getBoundingClientRect().height
      if (totalSpaceinPixiel) {
        setRowsPerPage(Math.ceil(totalSpaceinPixiel / RowHeightApprox))
      }
    }
  }, [alertBackgroundDiv, size])

  useMemo(() => {
    // so that page reset when external filter changes
    setCurrentPage(1)
  }, [input])

  return (
    <Root
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <AlertsDiv ref={alertBackgroundDiv}>
        {input && input.length ? (
          <>
            {input
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((row) => {
                return <FlocCard key={row.floc} filteredFlocAlert={row} />
              })}
            {PaginationComp(input)}
          </>
        ) : (
          <EmptyCard />
        )}
      </AlertsDiv>
    </Root>
  )
}

export default ActiveTab

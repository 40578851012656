import styled from '@emotion/styled'
import {
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  Box,
  Typography,
  Link,
} from '@mui/material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import copy from 'copy-to-clipboard'

import { ISSOWStrategyTypes } from '../../../../shared/types/strategyRule'
import {
  getISSOWEnvBasedonFloc,
  useParamQuery,
} from '../../../../shared/utility'
import { UIInternalDiagnosisStateType } from '../../../../shared/types/diagnosis'
import AlertNotification from '../../../../shared/components/alert-notification'
import ExternalOpen from '../../../../icons/open-external.svg?react'
import CopyIcon from '../../../../icons/copy.svg?react'

const StyledContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  img {
    height: 7rem !important;
    margin: 1.5rem 0rem 1.5rem 0rem;
  }
  h1 {
    color: #01c851;
    padding: 4px;
    font-weight: 400;
  }
  h2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 500;
  }
  b {
    font-weight: 500;
  }
  p {
    text-align: center;
    color: transparent(0.6);
    font-size: 0.9rem;
  }
  ul {
    text-align: left;
    li {
      margin-bottom: 1.5rem;
    }
  }
  a {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    margin-top: 0.5rem;
    color: #0645ad;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`

const ReceiptDiv = styled(Box)`
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
`

const ReceiptRow = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  a {
    margin: 0;
  }
  #left {
    width: 50%;
    text-align: right;
  }
  #right {
    text-align: left;
    width: 50%;
  }
`

const Content = styled(Box)`
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
`

const StyledBox = styled(Box)`
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 1rem;
  margin-left: 1rem;
`

interface SubmitSuccessDialogueProps {
  onClose: () => void
  image: string
  alt: string
  heading: string
  content: string
  isoNumber?: string
  measurementDocNumber?: string
  permitName?: string
  permitID?: string
  permitType?: ISSOWStrategyTypes
  issowType?: ISSOWStrategyTypes
  diagnosisState?: UIInternalDiagnosisStateType
}

const SubmitSuccessDialogue = ({
  onClose,
  image,
  alt,
  heading,
  content,
  measurementDocNumber,
  permitName,
  permitID,
  permitType,
  isoNumber,
  issowType,
  diagnosisState,
}: SubmitSuccessDialogueProps) => {
  const myHistroy = useHistory()
  const query = useParamQuery()
  const [show, setShow] = useState(false)

  const copyToClipBoard = (e: any) => {
    if (diagnosisState) {
      copy(
        diagnosisState.diagnosisResponseType.formattedDiagnosisResponse
          .workLongText
      )
      setShow(true)
    }
  }

  const getHrefLink = (permitId: string) => {
    return `https://${getISSOWEnvBasedonFloc(query.get('floc')!)}.wde.woodside.com.au/Permit/General/Default.aspx?PermitID=${permitId}`
  }

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="dialog-diagnosis-action"
      aria-describedby="dialog-diagnosis-done"
      open={true}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return
        }
        onClose && onClose()
      }}
    >
      <StyledContent>
        <img id="dialogue-main-image" src={image} alt={alt} />
        <Typography variant="h1">{heading}</Typography>
        <Content id="dialog-diagnosis-content" data-testid="dialog-content">
          {content}
          {(measurementDocNumber || permitName) && (
            <>
              <b>Submission Receipt:</b>
              <ReceiptDiv>
                {measurementDocNumber && (
                  <ReceiptRow>
                    <Box id="left">SAP Measurement Doc No:</Box>
                    <Box id="right">{measurementDocNumber}</Box>
                  </ReceiptRow>
                )}
                <ReceiptRow>
                  <Box id="left">Permit No:</Box>
                  <Box id="right">
                    {permitID ? (
                      <Link
                        href={getHrefLink(permitID)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Box component="span">{permitName}</Box>
                      </Link>
                    ) : permitType === ISSOWStrategyTypes.LRP ? (
                      'NA - Under LRP'
                    ) : (
                      'N/A'
                    )}
                  </Box>
                </ReceiptRow>
                {isoNumber ? (
                  <>
                    {isoNumber.split(',').map((iso: string, idx: number) => {
                      const splitedIso = iso.split('|')
                      return (
                        <ReceiptRow key={iso}>
                          <Box id="left">
                            {idx === 0 ? 'Isolation No:' : ''}
                          </Box>
                          <Box id="right">
                            {splitedIso.length > 1 ? (
                              <Link
                                href={getHrefLink(splitedIso[0])}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {splitedIso[1]}
                              </Link>
                            ) : (
                              <>{splitedIso}</>
                            )}
                          </Box>
                        </ReceiptRow>
                      )
                    })}
                  </>
                ) : (
                  <ReceiptRow>
                    <Box id="left">Isolation No:</Box>
                    <Box id="right">
                      {issowType === ISSOWStrategyTypes.OwnIsolations
                        ? 'NA - Own isolations'
                        : 'N/A'}
                    </Box>
                  </ReceiptRow>
                )}
              </ReceiptDiv>
            </>
          )}
        </Content>
        {(!measurementDocNumber || !permitName) && (
          <>
            <Typography variant="h1">What Does This Mean?</Typography>
            <StyledBox id="dialog-diagnosis-content">
              <ul>
                <li>The active health alert has been moved to Actioned</li>
                {!measurementDocNumber && (
                  <>
                    <li>
                      To continue with this request you must manually create a
                      SAP notification
                    </li>
                    <li>
                      You can copy a summary of your diagnosis below and paste
                      it into the notification when ready
                      <Link onClick={copyToClipBoard}>
                        <CopyIcon /> Copy diagnosis summary
                      </Link>
                      <Link
                        href="https://smart.app.woodside/my-notifications"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ExternalOpen />
                        Open SAP Notification Smart App (Fixit)
                      </Link>
                    </li>
                  </>
                )}
                {!permitName && (
                  <li>
                    Maintenance will need to manually create and link the
                    appropriate permit for this request at a later stage
                  </li>
                )}
              </ul>
            </StyledBox>
          </>
        )}
      </StyledContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            myHistroy.push('/')
          }}
          color="primary"
        >
          Return to All Alerts
        </Button>
      </DialogActions>
      {show && (
        <AlertNotification
          type="success"
          message={'Summary successfully copied to clipboard'}
          duration={2000}
          onHide={() => {
            setTimeout(() => {
              setShow(false)
            }, 800)
          }}
        />
      )}
    </Dialog>
  )
}

export default SubmitSuccessDialogue

import React from 'react'

import SituationAwarenessCard from './fan-cards/situation-awareness-card-fan/component'
import SituationAwarenessCardPump from './pump-cards/situation-awareness-card-pump/component'
import { useParamQuery } from '../../../shared/utility'
import RunHourCard from './pump-cards/run-hours/component'
import OPAMCard from './pump-cards/opam/component'
import { EquipmentOverviewContext } from '../../../shared/providers/floc-context-provider'
import { PaddedTabBox } from '../../../shared/components/padded-box'

import './index.css'

interface ContextTabProps {
  handleNextPage: (data: any) => void
  currentTab: number
}

const ContextStep = (props: ContextTabProps) => {
  const query = useParamQuery()
  const eqOverview = React.useContext(EquipmentOverviewContext)
  const [showFullLengthSA, setShowFullLengthSA] = React.useState(true)

  return (
    <PaddedTabBox hidden={props.currentTab !== 1}>
      {eqOverview?.isFan() && (
        <>
          <SituationAwarenessCard
            showFullLengthCallBack={setShowFullLengthSA}
          />
        </>
      )}
      {eqOverview?.isPump() && (
        <>
          <SituationAwarenessCardPump
            showFullLengthCallBack={setShowFullLengthSA}
            currentFloc={query.get('floc')!}
          />
          <RunHourCard />
          <OPAMCard />
        </>
      )}
    </PaddedTabBox>
  )
}

export default ContextStep

import { useQuery } from 'react-query'
import axios from 'axios'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { acquireTokenInfoSilent } from '../../../shared/auth'

const sentCancelWithFeedback = async (
  msalInstance: IPublicClientApplication,
  key: string,
  body: any
) => {
  try {
    const response = await axios(
      import.meta.env.VITE_CBM_ENDPOINT! + `anomalies/${key}`,
      {
        method: 'PUT',
        data: { ...body },
        headers: {
          Authorization: `Bearer ${await (await acquireTokenInfoSilent(
            msalInstance,
            import.meta.env.VITE_AAD_CLIENTID! + '/.default'
          ))!.accessToken}`,
        },
      }
    )

    return response
  } catch (e) {
    console.log('got error:', e)
    throw e
  }
}

//TODO use mututaion do to optimisic update https://react-query.tanstack.com/docs/guides/optimistic-updates
export default function useSentCancelFeedback(
  key: string,
  body: any,
  enabled: boolean
) {
  const { instance: msalInstance } = useMsal()
  return useQuery(
    ['Key', key, body],
    () => sentCancelWithFeedback(msalInstance, key, body),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: enabled,
    }
  )
}

export enum Plant {
  'pluto' = 'pluto',
  'kgp' = 'kgp',
}

export enum LocationMappings {
  AU21 = 'Pluto LNG Park',
  AU01 = 'Karratha Gas Plant Facility',
}

interface PlantMetaData {
  name: string
  prefix: LocationMappings
}

export const plantMetaData: Record<Plant, PlantMetaData> = {
  pluto: { name: 'Pluto LNG Park', prefix: LocationMappings.AU21 },
  kgp: { name: 'KGP', prefix: LocationMappings.AU01 },
}

export enum FLOCMapToPlant {
  AU21 = 'pluto',
  AU01 = 'kgp',
}

export enum mapPlantToFlocPrefix {
  pluto = 'AU21',
  kgp = 'AU01',
}

export enum Eq360PlantNames {
  AU21 = 'PLP',
  AU01 = 'KGP',
}

export const convertFlocToPlantName = (floc: string): Plant => {
  const plant = FLOCMapToPlant[floc.split('.')[0]]
  return plant ? plant : FLOCMapToPlant.AU21 // default to AU21(pluto)
}

export const getEq360PlantFromFloc = (floc: string): Plant => {
  const plant = Eq360PlantNames[floc.split('.')[0]]

  return plant ? plant : Eq360PlantNames.AU21 // default to AU21(pluto)
}

export const getISSOWPlantNameFromFloc = (
  floc?: string | undefined | null
): 'AU21' | 'AU01' => {
  if (floc) {
    const plant = floc.split('.')[0]

    return plant && plant.includes('AU01') ? 'AU01' : 'AU21' // default to AU21(pluto)
  } else {
    return 'AU21'
  }
}
